/* eslint-disable multiline-ternary */
/* eslint-disable react/prop-types */

import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { convertToPlain } from 'common/utils'
import { imgholder } from 'constant/images'
import AgeRatingItem from 'components/AgeRatingItem'
import NewReleaseItem from 'components/NewReleaseItem'
import RibbonItemLiveEvent from './RibbonItemLiveEvent'
import { Play, InfoCircle } from 'iconsax-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import '../styles/ribbon-item-newrelease.scss'

const RibbonItemNewRelease = ({ index, indexChild, handleClickShowModal, item, itemChild, slug }) => {
  const location = useLocation()

  const publishDate = itemChild.publish_date
  const publishDateArr = typeof publishDate === 'string' ? publishDate.split('-') : []
  const datePublishValue = Array.isArray(publishDateArr) ? publishDateArr[2] : ''
  const monthPublishValue = Array.isArray(publishDateArr) ? publishDateArr[1] : ''

  return (
    <div className="mb-1 mb-md-4 item-figure ribbon-item-newrelease">
    {/* <Link to={`${itemChild.type === 5 ? '/live/' : '/detail/'}${itemChild.slug}`}> */}
      {/* <figure className="figure px-1 px-md-2"> */}

      <figure className="figure">
        {/* <div className='ribbon-item-top-number-wrapper'>
          <div className='ribbon-item-top-number'>{indexChild + 1}</div>
        </div> */}

        <AgeRatingItem item={item} />

        <NewReleaseItem
          item={itemChild}
          // isPoster={itemChild?.type === 1}
          isPoster={item?.type === 1}
        />

        <RibbonItemLiveEvent data={itemChild} />

        <Link to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/${item.slug}/`)}${itemChild.slug}`}>
          {/* <div className={`figure-overflow ${item.id === 'fb6237e5-471f-474b-8fad-7cd6f1777be0' ? 'overflow_master_banner_mobile' : ''}`}> */}
            <div className="figure-overflow">

              {/* <ImageHolder data={itemChild.images} /> */}

              {/* {item.id === 'fb6237e5-471f-474b-8fad-7cd6f1777be0'
                ? <ImagePosterHolder data={itemChild.images} />
                : <ImageHolder data={itemChild.images} />
              } */}

            <div className="ribbon-item-newrelease-image-wrapper">
              <img
                className="figure-img img-fluid rounded-block w-100 img-thumbnail"
                src={itemChild.images
                  ? (itemChild.images.thumbnail && itemChild.images.thumbnail) !== ''
                      ? itemChild.images.thumbnail
                      : imgholder
                  : imgholder
                }
                onError={(e) => {
                  e.target.onerror = null
                  e.target.src = imgholder
                }}
              />
            </div>
          </div>
        </Link>

        {itemChild.type !== 5 &&
          <div className={`info-detail ${item.type === 1 ? 'info_detail_master_banner_mobile' : ''}`} onClick={e => handleClickShowModal(e, itemChild)}>
            <Link
              to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/${item.slug}/`)}${itemChild.slug}`}
              className="link-btn-play"
            >
              <button className={`btn btn-primary btn-play ${item.type === 1 ? 'btn_play_master_banner_mobile' : ''}`}>
              {/* <button className="btn btn-primary btn-play"> */}
                {/* <FontAwesomeIcon icon={faPlay} className="icon-play me-2" /> */}
                <Play color="black" variant='Bold' size={12} className='me-1' />
                {item.type === 1 ? 'Xem ngay' : 'Xem'}
              </button>
            </Link>
            <button className={`btn btn-primary btn-info-detail ${item.type === 1 ? 'btn_play_master_banner_mobile' : ''}`} onClick={e => handleClickShowModal(e, itemChild)}>
            {/* <button className="btn btn-primary btn-info-detail" onClick={e => handleClickShowModal(e, itemChild)}> */}
              {/* <FontAwesomeIcon icon={faInfoCircle} className="icon-info-detail me-2" /> */}
              <InfoCircle color="white" variant='Bold' size={12} className='me-1' />
              Thông tin
            </button>
          </div>
        }
      </figure>

      <div className="ribbon-newrelease-logo-container">
        {/* {itemChild?.images?.content_logo
          ? <img src={itemChild?.images?.content_logo} className="ribbon-newrelease-logo" />
          : <div className="ribbon-newrelease-logo"></div>
        } */}
        <div className="ribbon-newrelease-logo">
          <div className="ribbon-newrelease-monthpublish">Th {monthPublishValue}</div>
          <div className="ribbon-newrelease-datepublish">{datePublishValue}</div>
        </div>

        <div className="ribbon-newrelease-right-btn">
          <Link
            to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/${item.slug}/`)}${itemChild.slug}`}
            className="ribbon-newrelease-btn-play"
          >
            {/* <Play color="white" variant='Bold' size={24} className='me-1 ribbon-top-content-icon-play' role="button" /> */}
            <FontAwesomeIcon color="white" icon={faPlay} className="ribbon-top-content-icon-play" />
            <div className="ribbon-newrelease-play" role="button">Phát</div>
          </Link>
        </div>
      </div>

      <Link
        to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/${item.slug}/`)}${itemChild.slug}`}
        className="ribbon-newrelease-title"
      >
        <div>{itemChild.title}</div>
      </Link>

      {location?.pathname === '/page/moi-va-hot-web' &&
        <div className="ribbon-newrelease-description">{convertToPlain(itemChild.long_description)}</div>
      }
  </div>
  )
}

export default RibbonItemNewRelease
