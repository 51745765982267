import React, { useEffect } from 'react'
import Layout from 'features/layout/components/Layout'
import '../styles/privacy-policy.scss'

const PrivacyPolicyPage = (props) => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Privacy Policy - THVLi',
        page_location: 'https://thvli.vn/privacy-policy',
        page_path: '/privacy-policy',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    document.title = 'Privacy Policy - THVLi'
  }, [])

  return (
    <Layout>
      <div className="privacy-policy-body">
        <div className="private-title-wrapper">
          <div className="private-title">CHÍNH SÁCH VỀ QUYỀN RIÊNG TƯ</div>
          <div className="private-sub-title">Hệ thống ứng dụng THVLi (“THVLi”) là dịch vụ được cung cấp bởi Đài Phát thanh và Truyền hình Vĩnh Long (“THVL”), cho phép người dùng có thể truy cập vào để xem truyền hình trực tuyến, xem phim, chương trình theo yêu cầu và các nội dung video khác (“Nội dung”) mọi lúc mọi nơi, trên các thiết bị và/hoặc nền tảng khác nhau thông qua mạng Internet.</div>
          <div className="private-sub-title">Bằng việc sử dụng dịch vụ của THVLi, Người dùng đồng ý và chấp thuận bị ràng buộc về pháp lý bởi các quyền và nghĩa vụ phát sinh theo quy định tại Chính sách này. THVLi có quyền thay đổi, chỉnh sửa, thêm hoặc lược bỏ bất kỳ phần nào trong Quy định và Điều kiện sử dụng, vào bất cứ lúc nào mà không cần thông báo trước. Người dùng vui lòng kiểm tra thường xuyên để cập nhật những thay đổi của THVLi</div>
          <div className="private-sub-title">Bằng việc sử dụng dịch vụ của THVLi, Người dùng đồng ý và chấp thuận bị ràng buộc về pháp lý bởi các quyền và nghĩa vụ phát sinh theo quy định tại Chính sách này. THVLi có quyền thay đổi, chỉnh sửa, thêm hoặc lược bỏ bất kỳ phần nào trong Quy định và Điều kiện sử dụng, vào bất cứ lúc nào mà không cần thông báo trước. Người dùng vui lòng kiểm tra thường xuyên để cập nhật những thay đổi của THVLi</div>
        </div>
        <div className="privacy-param">
          <div className="param-lv1">
              1. Người Sử Dụng xác nhận và đồng ý cho THVLi thu thập những thông tin sau khi Người Sử Dụng sử dụng Dịch vụ THVLi, bao gồm:
          </div>
          <div className="param-lv2">
              a. Thông tin của Người Sử Dụng cung cấp cho THVL như: họ tên, địa chỉ email, số điện thoại, tên tài khoản, mật khẩu tài khoản, hình đại diện và bất kỳ thông tin cá nhân nào khác mà Người Sử Dụng cung cấp cho THVL thông qua THVLi.
          </div>
          <div className="param-lv2">
              b. Hệ thống của THVLi cũng được tự động tổng hợp, thu thập các thông tin như:
          </div>
          <div className="param-lv3">
              + Địa chỉ IP của Người Sử Dụng, cookies/history của Người Sử Dụng trong quá trình Người Sử Dụng sử dụng Dịch vụ THVLi;
          </div>
          <div className="param-lv3">
              + Thông tin từ các tổ chức/bên thứ ba mà Người Sử Dụng đã cung cấp cho họ và Người Sử Dụng cho phép họ kết nối với hệ thống của THVLi (ví dụ Người Sử Dụng đăng ký THVLi bằng các tài khoản liên kết với các mạng xã hội, tài khoản email của Người Sử Dụng).
          </div>
          <div className="param-lv3">
              + Các thông tin mà Người Sử Dụng cho phép THVL truy cập trên thiết bị di động của Người Sử Dụng khi Người Sử Dụng cài đặt ứng dụng THVLi.
          </div>
        </div>

        <div className="privacy-param">
          <div className="param-lv1">
              2. THVL cam kết không tiết lộ không mua bán, trao đổi thông tin Người Sử Dụng cho bất kỳ bên thứ ba nào và Người Sử Dụng xác nhận, đồng ý cho THVL sử dụng các thông tin của THVL cho các mục đích sau:
          </div>
          <div className="param-lv2">
              a. Để xử lý việc cung cấp dịch vụ, để cung cấp cho Người Sử Dụng các sản phẩm hay dịch vụ Người Sử Dụng yêu cầu từ THVL.
          </div>
          <div className="param-lv2">
              b. Để cải tiến và nâng cao trải nghiệm của Người Sử Dụng trên THVLi.
          </div>
          <div className="param-lv2">
              c. Đánh giá việc sử dụng sản phẩm và dịch vụ trên THVLi.
          </div>
          <div className="param-lv2">
              d. Trao cho Người Sử Dụng cơ hội tham gia vào các cuộc thi hay chương trình khuyến mãi.
          </div>
          <div className="param-lv2">
              e. Phân tích tính hiệu quả của các nội dung.
          </div>
          <div className="param-lv2">
              f. Đề xuất, giới thiệu về các bộ phim, chương trình truyền hình, quảng cáo mà THVL cho rằng Người Sử Dụng có thể quan tâm.
          </div>
          <div className="param-lv2">
              g. Cá nhân hóa trải nghiệm trang trên THVLi, đồng thời đánh giá số liệu thống kê về hoạt động của THVLi, chẳng hạn như thời gian Người Sử Dụng ghé thăm, trang web nào giới thiệu Người Sử Dụng đăng nhập vào đó.
          </div>
          <div className="param-lv2">
              h. Mỗi máy tính nối mạng đều được xác định bởi một chuỗi số gọi là &quot;giao thức Internet&quot; hay địa chỉ IP. Khi Người Sử Dụng có một yêu cầu gửi đến máy chủ của THVLi trong khi truy cập vào trang, máy chủ sẽ nhận ra Người Sử Dụng thông qua địa chỉ IP đó. Điều này sẽ không ảnh hưởng gì đến những thông tin cá nhân của Khách hàng ngoài việc nhận ra một máy tính đang truy cập THVLi. THVL dùng thông tin này để xác lập thống kê về lượng truy cập toàn cục và để xem có sự lạm dụng băng thông hay không nhằm phối hợp với các chính sách pháp luật ban hành về an ninh mạng.
          </div>
          <div className="param-lv2">
              i. Dịch vụ THVLi có thể đăng quảng cáo và quảng cáo có thể bao gồm các đường dẫn tới các trang web khác. Cũng như nhiều website khác, THVLi thiết lập và sử dụng cookie để tìm hiểu thêm về cách Người Sử Dụng tương tác với nội dung của THVLi và giúp THVLi cải thiện trải nghiệm của Người Sử Dụng khi sử dụng dịch vụ THVLi.
          </div>
        </div>

        <div className="privacy-param">
          <div className="param-lv1">
              3. Để bảo vệ thông tin không bị phá hoại một cách vô tình hay cố ý, khi THVL xóa thông tin Người Sử Dụng khỏi Dịch vụ THVLi, THVL có thể không xóa ngay các bản sao còn lại từ máy chủ hoặc hệ thống sao lưu của THVL. Thời gian lưu trữ lại thông tin của Người Sử Dụng trước khi xóa hẳn tùy thuộc vào từng thời điểm. THVL cung cấp tính năng biên tập thông tin trong tài khoản cá nhân thông qua trang cấu hình cá nhân riêng của Người Sử Dụng.
          </div>
        </div>

        <div className="privacy-param">
          <div className="param-lv1">
              4. THVL có thể chia sẻ thông tin của Người Sử Dụng một cách hợp lý trong một số trường hợp sau:
          </div>
          <div className="param-lv2">
              a. Được sự đồng ý của Người Sử Dụng.
          </div>
          <div className="param-lv2">
              b. Theo yêu cầu của Cơ quan Nhà nước có thẩm quyền.
          </div>
          <div className="param-lv2">
              c. Cho pháp nhân kế thừa liên quan đến việc sáp nhập, mua lại, hoặc bán lại toàn bộ hoặc một phần tài sản đáng kể của THVL để phục vụ mục đích cung cấp và sử dụng dịch vụ.
          </div>
        </div>

        <div className="privacy-param">
          <div className="param-lv1">
              5. THVLi thực hiện mọi biện pháp phòng ngừa thích hợp có thể để bảo mật thông tin cá nhân của Người Sử Dụng. Tuy nhiên, trong những Trường Hợp Bất Khả Kháng theo quy định của Pháp luật và Điều Khoản Sử dụng này, Người Sử Dụng đồng ý với THVL rằng không có một hệ thống an ninh nào trên toàn thế giới có thể đảm bảo an toàn 100% và có thể có những nhân tố vượt ra ngoài tầm kiểm soát của THVL dẫn đến việc thông tin Người Sử Dụng bị tiết lộ mà không phải do sự cố ý của THVL. Do vậy, THVL nỗ lực áp dụng mọi biện pháp có thể nhưng không bảo đảm thông tin của THVL sẽ được bảo mật tuyệt đối khi xảy ra những sự cố ngoài ý muốn của THVL.
          </div>
        </div>

        <div className="privacy-param">
          <div className="param-lv1">
              6. THVL sẽ không chịu bất kỳ trách nhiệm nào về việc tiết lộ thông tin Người Sử Dụng do lỗi của Người Sử Dụng không tuân thủ quy định của Chính sách bảo mật hoặc trên thiết bị của Người Sử Dụng có chứa các phần mềm độc hại (virus, spyware, worm, mailware, key logger, v.v), hoặc Người Sử Dụng đã kết nối tới các địa chỉ website không an toàn trước đó.
          </div>
        </div>

        <div className="privacy-param">
          <div className="param-lv1">
              7. Người Sử Dụng có thể thực hiện việc tự chỉnh sửa thông tin cá nhân bằng cách tự truy cập vào website THVLi.vn hoặc liên hệ với THVL qua email thvli@thvl.vn
          </div>
        </div>

        <div className="privacy-param">
          <div className="param-lv1">
              8. Người Sử Dụng có thể yêu cầu xóa Tài khoản THVLi bất kỳ thời điểm nào bằng cách liên hệ với THVL qua email thvli@thvl.vn và cung cấp các thông tin bắt buộc sau: Tên tài khoản THVLi, Số điện thoại (hoặc email) dùng để đăng ký Tài khoản THVLi. Sau khi hoàn tất việc xác thực thông tin Tài khoản THVL của Người Sử Dụng, THVL sẽ tiến hành xóa Tài khoản THVLi theo yêu cầu của Người Sử Dụng. Ngoài ra, Người dùng có thể xóa vĩnh viễn tài khoản đã đăng ký trên hệ thống THVLi (“Tài khoản”). Bằng việc thực hiện thao tác xóa Tài khoản trong phần “Thông tin tài khoản” https://www.thvli.vn/profile của người dùng. Người dùng đã hiểu rõ và chấp thuận toàn bộ Quy chế xóa Tài khoản này và tất cả quy định, hướng dẫn khác của THVLi liên quan đến việc xóa Tài khoản. THVLi sẽ xóa tất cả thông tin trong Tài khoản của Người dùng (bao gồm: số điện thoại, email, lịch sử xem…) ra khỏi hệ thống THVLi. THVLi có quyền sửa đổi, bổ sung, thay thế một phần hoặc toàn bộ Quy chế xóa Tài khoản này và các quy định, hướng dẫn liên quan đến việc xóa Tài khoản vào bất kỳ thời điểm nào mà không cần thông báo trước. Người dùng vui lòng kiểm tra thường xuyên để cập nhật những thay đổi chính sách của THVLi. Đối với các Tài khoản đã được xóa khỏi hệ thống THVLi: Người dùng không thể đăng nhập Tài khoản và/hoặc sử dụng một phần hoặc toàn bộ Dịch vụ trong Tài khoản đã xóa thành công. Người dùng có thể tạo lại Tài khoản mới với số điện thoại của các Tài khoản đã xoá thành công.
          </div>
        </div>

        <div className="privacy-param">
          <div className="param-lv1">
              9. THVL khuyến cáo Người Sử Dụng hạn chế truy cập tài khoản bằng đăng nhập tự động, chú ý chế độ sao lưu mật khẩu và đảm bảo đăng xuất khỏi tài khoản khi sử dụng các thiết bị chung. THVL sẽ không chịu trách nhiệm khi những thông tin cá nhân của Người Sử Dụng bị rò rỉ vì bất kỳ lý do nào xuất phát từ Người Sử Dụng.
          </div>
        </div>

        <div className="privacy-param">
          <div className="param-lv1">
              10. THVL có thể sửa đổi, cập nhật, bổ sung chính sách này vào mọi thời điểm. Bản sửa đổi có hiệu lực vào thời điểm chúng tôi cập nhật. Sau khi Chính sách bảo mật thông tin được cập nhật, Người Sử Dụng sử dụng Dịch Vụ THVLi có nghĩa là Người Sử Dụng đã hiểu và đồng ý với Chính sách bảo mật của THVL.
          </div>
        </div>

        <div className="privacy-param">
          <div className="param-lv1">
              11. Điều khoản bảo mật này chỉ áp dụng những thông tin Người Sử Dụng đăng ký trên hệ thống THVLi. Mọi thông tin Người Sử Dụng đăng ký tại những hệ thống khác sẽ không được THVL bảo mật.
          </div>
        </div>

        <div className="privacy-param">
          <div className="param-lv1">
              12. Thông tin về đơn vị thu thập và quản lý thông tin của Người Sử Dụng.
          </div>
        </div>

        <div className="privacy-param">
          <div className="param-lv1">
            Đài Phát Thanh và Truyền Hình Vĩnh Long
          </div>
          <div className="param-lv2">
            Địa chỉ: số 50, Đường Phạm Thái Bường, Phường 4, Thành phố Vĩnh Long, Tỉnh Vĩnh Long
          </div>
          <div className="param-lv2">
            Điện thoại: +84270.3822345
          </div>
          <div className="param-lv2">
            Email : thvli@thvl.vn
          </div>
          <div className="param-lv2">
            Website: http://www.thvli.vn
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicyPage
