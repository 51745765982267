export const imgLogo = `${process.env.PUBLIC_URL}/static/media/logo.png`
export const imgLogoTet = `${process.env.PUBLIC_URL}/static/media/logo_tet.png`
export const imgLogoCustom = `${process.env.PUBLIC_URL}/static/media/logo_custom.png`
export const imgholder = `${process.env.PUBLIC_URL}/static/media/imgholder.png`
export const img3G = `${process.env.PUBLIC_URL}/static/media/free3g_icon.png`
export const imgMobi = `${process.env.PUBLIC_URL}/static/media/mobi.png`
export const imgVinhLong = `${process.env.PUBLIC_URL}/static/media/vl.jpg`
export const imgAuthBackground = `${process.env.PUBLIC_URL}/static/media/auth_background.jpg`
export const imgGoogleIcon = `${process.env.PUBLIC_URL}/static/media/google_icon.png`
export const imgFacebookIcon = `${process.env.PUBLIC_URL}/static/media/facebook_icon.png`
export const imgAppleIcon = `${process.env.PUBLIC_URL}/static/media/apple_icon.png`
export const imgActiveImageSuccessIcon = `${process.env.PUBLIC_URL}/static/media/active_email_success.png`
export const imgActiveImageErrorIcon = `${process.env.PUBLIC_URL}/static/media/active_email_error.png`
export const imgRadioAnimation = `${process.env.PUBLIC_URL}/static/media/radio_animation.gif`
