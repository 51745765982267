import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTv } from '@fortawesome/free-solid-svg-icons'
import '../styles/tv.scss'

const Tv = (props) => {
  const inputRef1 = useRef(null)
  const inputRef2 = useRef(null)
  const inputRef3 = useRef(null)
  const inputRef4 = useRef(null)
  const inputRef5 = useRef(null)
  const inputRef6 = useRef(null)

  const [activeCode, setActiveCode] = useState(null)
  const [isError, setIsError] = useState(false)

  const [value1, setValue1] = useState('')
  const [value2, setValue2] = useState('')
  const [value3, setValue3] = useState('')
  const [value4, setValue4] = useState('')
  const [value5, setValue5] = useState('')
  const [value6, setValue6] = useState('')

  useEffect(() => {
    document.title = 'TV'

    if (!localStorage.getItem('adm_token')) {
      localStorage.setItem('adm_redirect_url', window.location.href)
      window.location.href = '/login'
    } else {
      localStorage.removeItem('adm_redirect_url')
    }

    const urlString = window.location.href
    const url = new URL(urlString)
    const activeCodeParam = url.searchParams.get('activecode')
    setActiveCode(activeCodeParam)

    if (inputRef1.current) {
      inputRef1.current.focus()
    }
  }, [])

  useEffect(() => {
    if (activeCode && typeof activeCode === 'string') {
      for (let i = 0; i < activeCode.length; i++) {
        if (i === 0) {
          setValue1(activeCode[i])
        }
        if (i === 1) {
          setValue2(activeCode[i])
        }
        if (i === 2) {
          setValue3(activeCode[i])
        }
        if (i === 3) {
          setValue4(activeCode[i])
        }
        if (i === 4) {
          setValue5(activeCode[i])
        }
        if (i === 5) {
          setValue6(activeCode[i])
        }
      }
    }
  }, [activeCode])

  useEffect(() => {
    if (value1 !== '' && value2 !== '' && value3 !== '' && value4 !== '' && value5 !== '' && value6 !== '') {
      const formData = new FormData()
      formData.append('code', activeCode === null ? `${value1}${value2}${value3}${value4}${value5}${value6}` : activeCode)

      axios.post(`${process.env.REACT_APP_API_SETTING_URL}login/tv/confirm-code`, formData, {
        headers: {
          Authorization: `${localStorage.getItem('adm_token')}`
        }
      })
        .then(res => {
          // console.log(res)

          const successTVButton = document.getElementById('successTVButton')
          if (successTVButton) {
            successTVButton.click()
          }
        })
        .catch(err => {
          setIsError(true)
          console.log(err)
        })
    } else {
      setIsError(false)
    }
  }, [value1, value2, value3, value4, value5, value6])

  const handleChangeInput1 = (e) => {
    setValue1(e.target.value && e.target.value.toUpperCase())

    if (e.target.value !== '') {
      if (inputRef2.current) {
        inputRef2.current.focus()
      }
    }
  }
  const handleChangeInput2 = (e) => {
    setValue2(e.target.value && e.target.value.toUpperCase())

    if (e.target.value !== '') {
      if (inputRef3.current) {
        inputRef3.current.focus()
      }
    } else {
      if (inputRef1.current) {
        inputRef1.current.focus()
      }
    }
  }
  const handleChangeInput3 = (e) => {
    setValue3(e.target.value && e.target.value.toUpperCase())

    if (e.target.value !== '') {
      if (inputRef4.current) {
        inputRef4.current.focus()
      }
    } else {
      if (inputRef2.current) {
        inputRef2.current.focus()
      }
    }
  }
  const handleChangeInput4 = (e) => {
    setValue4(e.target.value && e.target.value.toUpperCase())

    if (e.target.value !== '') {
      if (inputRef5.current) {
        inputRef5.current.focus()
      }
    } else {
      if (inputRef3.current) {
        inputRef3.current.focus()
      }
    }
  }
  const handleChangeInput5 = (e) => {
    setValue5(e.target.value && e.target.value.toUpperCase())

    if (e.target.value !== '') {
      if (inputRef6.current) {
        inputRef6.current.focus()
      }
    } else {
      if (inputRef4.current) {
        inputRef4.current.focus()
      }
    }
  }
  const handleChangeInput6 = (e) => {
    setValue6(e.target.value && e.target.value.toUpperCase())

    if (e.target.value !== '') {
      //
    } else {
      if (inputRef5.current) {
        inputRef5.current.focus()
      }
    }
  }

  const handleClickHome = () => {
    window.location.href = '/'
  }

  const handleClickConfirm = () => {
    window.location.href = '/'
  }

  return (
    <div className="tv">
      {isError &&
        <div className="row pt-4">
          <div className="col-10 col-md-4 offset-1 offset-md-4">
            <div className="alert alert-danger" role="alert">
              Mã xác thực không chính xác!
            </div>
          </div>
        </div>
      }

      <div className="row pt-5">
        <div className="col-10 col-md-6 offset-1 offset-md-3">
          <div className="alert alert-dark adm-alert-custom" role="alert">
            <FontAwesomeIcon className="mt-2 tv-icon" icon={faTv} />
            <div className="title pt-2">Đăng nhập trên tivi</div>
            <div className="content pt-1">Nhập mã xác thực đang hiển thị trên tivi của bạn</div>
            <div className="tv-code">
              <input ref={inputRef1} className="tv-input" id="tv-input-0" maxLength={1} value={value1} onChange={handleChangeInput1} />
              <input ref={inputRef2} className="tv-input ms-2" id="tv-input-1" maxLength={1} value={value2} onChange={handleChangeInput2} />
              <input ref={inputRef3} className="tv-input ms-2" id="tv-input-2" maxLength={1} value={value3} onChange={handleChangeInput3} />
              <input ref={inputRef4} className="tv-input ms-2" id="tv-input-3" maxLength={1} value={value4} onChange={handleChangeInput4} />
              <input ref={inputRef5} className="tv-input ms-2" id="tv-input-4" maxLength={1} value={value5} onChange={handleChangeInput5} />
              <input ref={inputRef6} className="tv-input ms-2" id="tv-input-5" maxLength={1} value={value6} onChange={handleChangeInput6} />
            </div>
            <button className="btn btn-primary mt-4 mb-4" onClick={handleClickHome}>Về trang chủ</button>
          </div>
        </div>
      </div>

      <div className="modal fade" id="successTVModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Thông báo</h5>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              Đăng nhập thành công
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleClickConfirm}>Đồng ý</button>
            </div>
          </div>
        </div>
      </div>

      <button style={{ display: 'none' }} type="button" id="successTVButton" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#successTVModal">
        Launch static backdrop modal
      </button>
    </div>
  )
}

export default Tv
