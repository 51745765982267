import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { imgActiveImageSuccessIcon, imgActiveImageErrorIcon } from 'constant/images'
import Overlay from 'components/Overlay'
import '../styles/active-email.scss'

const ActiveEmail = (props) => {
  const [emailData, setEmailData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(0) // 0: pending, 1: success, 2: error

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Kích hoạt tài khoản',
        page_location: 'https://thvli.vn/validate_register',
        page_path: '/validate_register',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    document.title = 'Kích hoạt tài khoản'

    const urlString = window.location.href
    const url = new URL(urlString)
    const email = url.searchParams.get('email')
    const otp = url.searchParams.get('otp')
    setEmailData(email)

    const formData = new FormData()
    formData.append('email', email)

    axios.get(`${process.env.REACT_APP_API_SETTING_URL}register/validate?email=${email}&otp=${otp}`)
      .then(res => {
        if (res.status === 200) {
          console.log('OK')
        }
        setIsLoading(false)
        setStatus(1)
      })
      .catch(err => {
        setIsLoading(false)
        setStatus(2)
        console.log(err)
      })
  }, [])

  const handleClickHome = () => {
    window.location.href = '/'
  }

  return (
    <div className="active-email">
      {/* <div className="auth-background">
        <img src={imgAuthBackground} />
      </div>

      <div className="auth-title">KÍCH HOẠT TÀI KHOẢN</div> */}

      <div className="row pt-5">
        <div className="col-10 col-md-6 offset-1 offset-md-3">
          {status === 1 &&
            <div className="alert alert-dark adm-alert-custom" role="alert">
              <img className="mt-2" src={imgActiveImageSuccessIcon} width={70} height={70} />
              <div className="title pt-2">Thành công!</div>
              <div className="content pt-1">Chúc mừng, email {emailData} đã được xác thực thành công</div>
              <button className="btn btn-primary mt-4 mb-4" onClick={handleClickHome}>Về trang chủ</button>
            </div>
          }
          {status === 2 &&
            <div className="alert alert-dark adm-alert-custom" role="alert">
              <img className="mt-2" src={imgActiveImageErrorIcon} width={70} height={70} />
              <div className="title pt-2">Không thành công!</div>
              <div className="content pt-1">Đường dẫn xác thực đã hết hiệu lực, vui lòng truy cập trang thông tin người dùng để gửi lại đường dẫn xác thực</div>
              <button className="btn btn-primary mt-4 mb-4" onClick={handleClickHome}>Về trang chủ</button>
            </div>
          }
        </div>
      </div>

      {isLoading && <Overlay />}
    </div>
  )
}

export default ActiveEmail
