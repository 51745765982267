import React from 'react'
import Layout from 'features/layout/components/Layout'
import ForgetPassword from '../components/ForgetPassword'

const ForgetPasswordPage = (props) => {
  return (
    <Layout>
      <ForgetPassword />
    </Layout>
  )
}

export default ForgetPasswordPage
