import React from 'react'
import { useMediaQuery } from 'common/hook-custom'
import Layout from 'features/layout/components/Layout'
import ArtistCustom from '../components/ArtistCustom'
import ArtistMobileCustom from '../components/ArtistMobileCustom'

const ArtistCustomPage = () => {
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  return (
    <Layout>
      {isDesktop
        ? <ArtistCustom />
        : <ArtistMobileCustom />
      }
    </Layout>
  )
}

export default ArtistCustomPage
