import React from 'react'
import Layout from 'features/layout/components/Layout'
import UpdatePassword from '../components/UpdatePassword'

const UpdatePasswordPage = (props) => {
  return (
    <Layout>
      <UpdatePassword />
    </Layout>
  )
}

export default UpdatePasswordPage
