import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useIntersectionObserver from 'common/hook'
import { formatDataChunk, convertToPlain } from 'common/utils'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faPlay, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Play, InfoCircle } from 'iconsax-react'
import { setProgress } from 'features/watching/watchingSlice'
import Spinner from 'components/Spinner'
import { imgholder } from 'constant/images'
import ImageHolder from 'features/core/ImageHolder'
import ModalFigure from 'features/core/ModalFigure'
import AgeRatingItem from 'components/AgeRatingItem'
import NewReleaseItem from 'components/NewReleaseItem'
import RibbonItemLiveEvent from './RibbonItemLiveEvent'
import '../styles/ribbon-detail.scss'

const RibbonDetail = (props) => {
  const { data, slug, ribbonSlug, loadMoreRef, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = props

  const dispatch = useDispatch()

  const [modalContentId, setModalContentId] = useState(null)
  const [modalContentSlug, setModalContentSlug] = useState(null)
  const [modalContentImage, setModalContentImage] = useState(null)
  const [modalContentDescription, setModalContentDescription] = useState(null)
  const [modalContentPublishDate, setModalContentPublishDate] = useState(null)
  const [modalProgress, setModalProgress] = useState(null)
  const [modalContentNewRelease, setModalContentNewRelease] = useState(null)
  const [isShowModal, setIsShowModal] = useState(false)

  // Infinite Loading
  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage
  })

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickShowModal = (e, item) => {
    if (item.images && item.images.cover && item.images.cover !== '') {
      setModalContentImage(item.images
        ? ((item.images.cover && item.images.cover) !== '' ? item.images.cover : imgholder)
        : imgholder)
    } else {
      setModalContentImage(item.images
        ? ((item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : imgholder)
        : imgholder)
    }

    const strippedHtml = convertToPlain(
      item.long_description
    )
    setModalContentDescription(strippedHtml)

    setModalContentPublishDate(item.publish_date)

    setModalContentId(item.id)
    setModalContentSlug((item.default_episode && item.default_episode.id) ? item.default_episode.id : item.slug)
    setModalProgress(item.progress)
    setModalContentNewRelease(item.new_release)
    setIsShowModal(true)
  }

  const handleCloseModal = (value) => {
    setIsShowModal(value)
    setModalContentId(null)
  }

  const handleClickWatching = (e, progress) => {
    dispatch(setProgress(progress))
  }

  return (
    <div className="ribbon-detail">
      {data && data.map((item, index) => {
        const dataChunk = formatDataChunk(item, 4)

        return (
          <div key={index}>
            {dataChunk && dataChunk.map((itemChild, indexChild) => {
              return (
                <div key={indexChild} className={`row ${indexChild === 0 ? 'pt-2' : 'pt-4'} container-wrapper`}>
                  {itemChild && itemChild.map((itemGrandChild, indexGrandChild) => {
                    // const peopleArr = itemGrandChild.people
                    // let actorStr = ''
                    // let directorStr = ''
                    // const publishDateStr = parseDate(itemGrandChild.publish_date)

                    // if (Array.isArray(peopleArr)) {
                    //   for (let i = 0; i < peopleArr.length; i++) {
                    //     if (peopleArr[i] && peopleArr[i].role === 'actor') {
                    //       actorStr += peopleArr[i].name + ', '
                    //     }
                    //     if (peopleArr[i] && peopleArr[i].role === 'director') {
                    //       directorStr += peopleArr[i].name + ', '
                    //     }
                    //   }
                    // }

                    // actorStr = actorStr.substring(0, actorStr.length - 2)
                    // directorStr = directorStr.substring(0, directorStr.length - 2)

                    return (
                      <React.Fragment key={indexGrandChild}>
                        <div className="col-3 item-figure">
                          {/* <Link to={`/${itemGrandChild.type === 5 ? 'live' : 'detail'}/${itemGrandChild.slug}`}> */}
                          <figure className={`figure figure-item-custom ${indexChild === dataChunk.length - 1 ? 'figure-last' : ''}`}>
                            <AgeRatingItem item={itemGrandChild} />
                            <NewReleaseItem item={itemGrandChild} isPoster={itemGrandChild?.type === 1} />
                            <RibbonItemLiveEvent data={itemGrandChild} />

                            <div className="figure-overflow">
                              <Link to={`/${itemGrandChild.type === 10 ? 'truc-tiep' : (itemGrandChild.type === 5 ? 'live' : `detail/${slug}/${ribbonSlug}`)}/${itemGrandChild.slug}`}>
                                <ImageHolder data={itemGrandChild.images} />
                              </Link>
                            </div>
                            <div className="info-detail" onClick={e => handleClickShowModal(e, itemGrandChild)}>
                              <Link
                                to={`/${itemGrandChild.type === 10 ? 'truc-tiep' : (itemGrandChild.type === 5 ? 'live' : `detail/${slug}/${ribbonSlug}`)}/${itemGrandChild.slug}`}
                                className="link-btn-play"
                              >
                                <button className="btn btn-primary btn-play">
                                  {/* <FontAwesomeIcon icon={faPlay} className="icon-play me-2" /> */}
                                  <Play color="black" variant='Bold' size={12} className='me-1' />
                                  Xem
                                </button>
                              </Link>
                              <button className="btn btn-primary btn-info-detail" onClick={e => handleClickShowModal(e, itemGrandChild)}>
                                {/* <FontAwesomeIcon icon={faInfoCircle} className="icon-info-detail me-2" /> */}
                                <InfoCircle color="white" variant='Bold' size={12} className='me-1' />
                                Thông tin
                              </button>
                              {/* {directorStr.length > 0 &&
                                <div className="info-text">Đạo diễn: {directorStr}</div>
                              }
                              {actorStr.length > 0 &&
                                <div className="info-text">Diễn viên: {actorStr}</div>
                              }
                              <div className="info-text pb-2">Xuất bản: {publishDateStr}</div> */}
                            </div>
                          </figure>
                          {/* </Link> */}
                        </div>

                        {((indexGrandChild === itemChild.length - 1) && itemChild.length === 1) &&
                          <>
                            <div className='col-3'></div>
                            <div className='col-3'></div>
                            <div className='col-3'></div>
                          </>
                        }
                        {((indexGrandChild === itemChild.length - 1) && itemChild.length === 2) &&
                          <>
                            <div className='col-3'></div>
                            <div className='col-3'></div>
                          </>
                        }
                        {((indexGrandChild === itemChild.length - 1) && itemChild.length === 3) &&
                          <>
                            <div className='col-3'></div>
                          </>
                        }
                      </React.Fragment>
                    )
                  })}
                </div>
              )
            })}
          </div>
        )
      })}

      <div ref={loadMoreRef} className="row"></div>

      {isLoading && <Spinner />}

      {isFetchingNextPage && <div></div>}

      <ModalFigure
        isShow={isShowModal}
        modalContentId={modalContentId}
        modalContentSlug={modalContentSlug}
        modalContentImage={modalContentImage}
        modalContentDescription={modalContentDescription}
        modalContentPublishDate={modalContentPublishDate}
        modalContentNewRelease={modalContentNewRelease}
        onClose={handleCloseModal}
        onClickPlay={e => handleClickWatching(e, modalProgress)}
      />
    </div>
  )
}

RibbonDetail.propTypes = {
  data: PropTypes.array,
  slug: PropTypes.string,
  ribbonSlug: PropTypes.string,
  loadMoreRef: PropTypes.any,
  fetchNextPage: PropTypes.any,
  hasNextPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFetchingNextPage: PropTypes.bool
}

RibbonDetail.defaultProps = {
  data: [],
  slug: '',
  ribbonSlug: '',
  hasNextPage: false,
  isLoading: false,
  isFetchingNextPage: false
}

export default RibbonDetail
