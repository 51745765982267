import * as Sentry from '@sentry/react'

export const sentryException = (message, error) => {
  Sentry.captureMessage(message || 'Error', {
    extra: error
      ? {
          error: error
        }
      : null
  })
}
