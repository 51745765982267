import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector, useDispatch } from 'react-redux'
import { getData, getDataServerTime } from 'common/utils'
import { setBannerList } from 'features/banner/bannerSlice'
import { setRibbonList } from 'features/ribbon/ribbonSlice'
import { useMediaQuery } from 'common/hook-custom'
import Overlay from 'components/Overlay'
import MenuCustom from 'features/menu/components/MenuCustom'
import Ribbon from 'features/ribbon/components/Ribbon'
import Banner from 'features/banner/components/Banner'
import BannerMobile from 'features/banner/components/BannerMobile'
import Footer from 'features/footer/components/Footer'
import RibbonMobile from 'features/ribbon/components/RibbonMobile'
import InAppPopup from 'components/InAppPopup'
import AdsItem from 'components/AdsItem'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../styles/home.scss'

const HomePage = (props) => {
  const dispatch = useDispatch()
  const menu = useSelector((state) => state.menu.menu)
  const ribbonList = useSelector((state) => state.ribbon.ribbonList)
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  const [menuId, setMenuId] = useState(null)

  const { data: dataPage, isLoading, isFetching } = useQuery(['getPage', menuId], async () => {
    if (menuId) {
      if (menuId === process.env.REACT_APP_MENU_HOT_ID) {
        return await getData('GET', `special-page/${process.env.REACT_APP_MENU_HOT_ID}/`)
      } else if (menuId === process.env.REACT_APP_MENU_EURO_ID) {
        // return await getData('GET', `special-page/${process.env.REACT_APP_MENU_EURO_ID}/`)
        return await getData('GET', `special-page/${process.env.REACT_APP_MENU_EURO_SLUG}/`)
      } else {
        return await getData('GET', `page/${menuId}/?platform=web`)
      }
    }
  })

  const { data: dataServerTime } = useQuery('getServerTime', async () => {
    return await getDataServerTime()
  }, {
    staleTime: 0,
    cacheTime: 0
  })

  const randomElement = (arr, type) => {
    if (Array.isArray(arr)) {
      const arrTemp = arr.filter(obj => {
        return obj.type === type
      })

      const randomIndex = Math.floor(Math.random() * arrTemp.length)
      if (Number.isInteger(randomIndex)) {
        return arrTemp[randomIndex]
      } else {
        return null
      }
    } else {
      return null
    }
  }

  useEffect(() => {
    document.title = 'Trang Chủ - THVLi'

    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Trang chủ',
        page_location: 'https://thvli.vn/',
        page_path: '/',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    if (localStorage.getItem('adm_redirect_url')) {
      localStorage.removeItem('adm_redirect_url')
    }
  }, [])

  useEffect(() => {
    if (menu) {
      document.title = menu.name
      setMenuId(menu.id)
    }
  }, [menu])

  useEffect(() => {
    if (dataPage) {
      dispatch(setBannerList(dataPage.banners))

      const ribbonDataList = dataPage.ribbons
      if (Array.isArray(ribbonDataList)) {
        const arrTemp = ribbonDataList.map((item, index) => {
          const itemDataList = item.items
          let itemArrTemp = []

          if (Array.isArray(itemDataList)) {
            itemArrTemp = itemDataList.map((itemChild, indexChild) => {
              const imageListArr = itemChild.image_list
              const imgThumbnailRandomObj = randomElement(imageListArr, 'thumbnail')
              const imgPosterRandomObj = randomElement(imageListArr, 'master_banner_mobile')

              return {
                image_custom_value: item.type === 1 ? imgPosterRandomObj : imgThumbnailRandomObj,
                ...itemChild
              }
            })
          }

          return {
            ...item,
            items: itemArrTemp
          }
        })

        dispatch(setRibbonList(arrTemp))
      }
      // dispatch(setRibbonList(dataPage.ribbons))
    }
  }, [dataPage])

  useEffect(() => {
    if (dataServerTime) {
      const currentTimestamp = Date.now()
      const timeDiff = currentTimestamp - dataServerTime
      localStorage.setItem('TIME_NOW', currentTimestamp)
      localStorage.setItem('TIME_SERVER', dataServerTime)
      localStorage.setItem('TIME_DIFF', timeDiff)
    }
  }, [dataServerTime])

  return (
    <>
      <MenuCustom />

      {(isLoading || isFetching)
        ? <div className="homepage">
            <div className="skeleton-content"></div>
            <div className="skeleton-block-one"></div>

            <div className="d-flex skeleton-block-container">
              <div className="skeleton-block"></div>
              <div className="skeleton-block"></div>
              <div className="skeleton-block"></div>
              <div className="skeleton-block mb-5"></div>
            </div>
            <Overlay />
          </div>
        : <div className="homepage">
          {isDesktop
            ? <Banner />
            : <BannerMobile />
          }
          {/* <Menu style={{ paddingTop: 0 }} /> */}

          <AdsItem
            adsPos="POS001"
            adsClassName="banner-middle-ads"
          />

          {isDesktop
            ? <>
                <Ribbon
                  data={ribbonList}
                />
              </>
            : <>
                <RibbonMobile
                  data={ribbonList}
                />
              </>
          }
        </div>
      }

      <InAppPopup />

      <Footer />
    </>
  )
}

export default HomePage
