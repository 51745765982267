import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { setProgress } from 'features/watching/watchingSlice'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { getData, formatDataChunk, convertToPlain } from 'common/utils'
import { imgholder } from 'constant/images'
// import ImagePosterHolder from 'features/core/ImagePosterHolder'
import ImageHolder from 'features/core/ImageHolder'
import AgeRatingItem from 'components/AgeRatingItem'
import NewReleaseItem from 'components/NewReleaseItem'
import ModalFigure from 'features/core/ModalFigure'
import { Play, InfoCircle } from 'iconsax-react'
import imgArtistPlaceholder from '../styles/images/artist_placeholder.png'
import '../styles/artist.scss'

const ArtistCustom = () => {
  const { slug } = useParams()
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [country, setCountry] = useState('')
  const [dataList, setDataList] = useState([])
  const [avatar, setAvatar] = useState(null)
  const [role, setRole] = useState('')

  const [modalContentId, setModalContentId] = useState(null)
  const [modalContentSlug, setModalContentSlug] = useState(null)
  const [modalContentImage, setModalContentImage] = useState(null)
  const [modalContentDescription, setModalContentDescription] = useState(null)
  const [modalContentPublishDate, setModalContentPublishDate] = useState(null)
  const [modalProgress, setModalProgress] = useState(null)
  const [modalContentNewRelease, setModalContentNewRelease] = useState(null)
  const [isShowModal, setIsShowModal] = useState(false)

  const { data } = useQuery(['getArtist', slug], async () => {
    if (slug) {
      return await getData('GET', `artist/${slug}/contents/`)
    }
  })

  useEffect(() => {
    document.title = 'Nghệ sĩ'
  }, [])

  useEffect(() => {
    if (data) {
      setRole(data.job_title)
      setCountry(data.country_name)
      setAvatar(data.avatar_url)
      setName(data.name)

      const dataTemp = formatDataChunk(data, 4)
      if (Array.isArray(dataTemp)) {
        setDataList(dataTemp)
      }
    }
  }, [data])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickShowModal = (e, item) => {
    if (item.images && item.images.cover && item.images.cover !== '') {
      setModalContentImage(item.images
        ? ((item.images.cover && item.images.cover) !== '' ? item.images.cover : imgholder)
        : imgholder)
    } else {
      setModalContentImage(item.images
        ? ((item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : imgholder)
        : imgholder)
    }

    const strippedHtml = convertToPlain(
      item.long_description
    )
    setModalContentDescription(strippedHtml)

    setModalContentPublishDate(item.publish_date)

    setModalContentId(item.id)
    setModalContentSlug((item.default_episode && item.default_episode.id) ? item.default_episode.id : item.slug)
    setModalProgress(item.progress)
    setModalContentNewRelease(item.new_release)
    setIsShowModal(true)
  }

  const handleCloseModal = (value) => {
    setIsShowModal(value)
    setModalContentId(null)
  }

  const handleClickWatching = (e, progress) => {
    dispatch(setProgress(progress))
  }

  return (
    <div className="artist">
      <div className="row">
        <div className="col-md-12">
          <div className="text-artist">NGHỆ SĨ</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <div className="artist-container">
            <div className="info-container">
              {avatar
                ? <img
                    className="artist-img"
                    src={avatar}
                    width={85}
                    height={85}
                    alt=""
                  />
                : <img
                    className="artist-img"
                    src={imgArtistPlaceholder}
                    width={85}
                    height={85}
                    alt=""
                  />
              }
              <div className="artist-name">{name}</div>
              <div className="text-nation mt-2">Nghề nghiệp: <span className="text-detail">{role}</span></div>
              <div className="text-nation">Quốc gia: <span className="text-detail">{country}</span></div>

              <hr className="img-divider" />
            </div>
          </div>
        </div>

        <div className='col-md-9'>
          <div className="row">
            <div className="col-md-12">
              <div className="text-joined">Đã tham gia</div>
            </div>
          </div>
          {Array.isArray(dataList) && dataList.map((item, index) => {
            return (
              <div key={index} className='row'>
                {Array.isArray(item) && item.map((itemChild, indexChild) => {
                  return (
                    <React.Fragment key={indexChild}>
                      <div className='col item-figure col-artist-joined'>
                        <figure className="figure">
                          <AgeRatingItem item={itemChild} />
                          <NewReleaseItem item={itemChild} />

                          <div className="figure-overflow">
                            <ImageHolder data={itemChild.images} />
                          </div>

                          <div className="info-detail" onClick={e => handleClickShowModal(e, itemChild)}>
                            <Link
                              to={`detail/${itemChild.slug}`}
                              className="link-btn-play"
                            >
                              <button className="btn btn-primary btn-play">
                                {/* <FontAwesomeIcon icon={faPlay} className="icon-play me-2" /> */}
                                <Play color="black" variant='Bold' size={12} className='me-1' />
                                Xem
                              </button>
                            </Link>
                            <button className="btn btn-primary btn-info-detail" onClick={e => handleClickShowModal(e, itemChild)}>
                              {/* <FontAwesomeIcon icon={faInfoCircle} className="icon-info-detail me-2" /> */}
                              <InfoCircle color="white" variant='Bold' size={12} className='me-1' />
                              Thông tin
                            </button>
                            {/* {directorStr.length > 0 &&
                              <div className="info-text">Đạo diễn: {directorStr}</div>
                            }
                            {actorStr.length > 0 &&
                              <div className="info-text">Diễn viên: {actorStr}</div>
                            }
                            <div className="info-text pb-2">Xuất bản: {publishDateStr}</div> */}
                          </div>
                        </figure>
                      </div>

                      {((indexChild === item.length - 1) && item.length === 1) &&
                        <>
                          <div className='col item-figure col-artist-joined'></div>
                          <div className='col item-figure col-artist-joined'></div>
                          <div className='col item-figure col-artist-joined'></div>
                        </>
                      }
                      {((indexChild === item.length - 1) && item.length === 2) &&
                        <>
                          <div className='col item-figure col-artist-joined'></div>
                          <div className='col item-figure col-artist-joined'></div>
                        </>
                      }
                      {((indexChild === item.length - 1) && item.length === 3) &&
                        <>
                          <div className='col item-figure col-artist-joined'></div>
                        </>
                      }
                    </React.Fragment>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>

      <ModalFigure
        isShow={isShowModal}
        modalContentId={modalContentId}
        modalContentSlug={modalContentSlug}
        modalContentImage={modalContentImage}
        modalContentDescription={modalContentDescription}
        modalContentPublishDate={modalContentPublishDate}
        modalContentNewRelease={modalContentNewRelease}
        onClose={handleCloseModal}
        onClickPlay={e => handleClickWatching(e, modalProgress)}
      />
    </div>
  )
}

export default ArtistCustom
