/* eslint-disable multiline-ternary */
/* eslint-disable react/prop-types */

import React from 'react'
import { Link } from 'react-router-dom'
import AgeRatingItem from 'components/AgeRatingItem'
import NewReleaseItem from 'components/NewReleaseItem'
import ImageHolder from 'features/core/ImageHolder'
import ImagePosterHolder from 'features/core/ImagePosterHolder'
import { Play, InfoCircle } from 'iconsax-react'
import bgTop10 from '../styles/images/bg_top10.png'
import RibbonItemLiveEvent from './RibbonItemLiveEvent'
import '../styles/ribbon-item-top.scss'

const RibbonItemTop = ({ index, indexChild, handleClickShowModal, item, itemChild, slug }) => {
  return (
    // <div key={indexChild} className="pb-1 pb-md-4 item-figure">
    <div key={indexChild} className="mb-1 mb-md-0 item-figure ribbon-item-top">
      {/* <Link to={`${itemChild.type === 5 ? '/live/' : '/detail/'}${itemChild.slug}`}> */}
        {/* <figure className="figure px-1 px-md-2"> */}
        <figure className="figure">
          <AgeRatingItem item={itemChild} type="top10" />

          <NewReleaseItem
            item={itemChild}
            // isPoster={itemChild?.type === 1}
            isPoster={item?.type === 1}
          />

          <RibbonItemLiveEvent data={itemChild} />

          <div className="badge-top10">
            <img src={bgTop10} alt="" />
          </div>

          <div className="order-top10">
            {indexChild + 1}
          </div>

          {itemChild.slug === 'phat-thanh'
            ? <a href="https://audio.thvl.vn/radio/phat-thanh" target="_blank" rel="noopener noreferrer">
                <div className="figure-overflow">
                  <ImageHolder data={itemChild.images} />
                </div>
              </a>
            : <Link to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/${item.slug}/`)}${itemChild.slug}`}>
                {/* <div className={`figure-overflow ${item.id === 'fb6237e5-471f-474b-8fad-7cd6f1777be0' ? 'overflow_master_banner_mobile' : ''}`}> */}
                  <div className="figure-overflow">

                    {/* <ImageHolder data={itemChild.images} /> */}

                    {/* {item.id === 'fb6237e5-471f-474b-8fad-7cd6f1777be0'
                      ? <ImagePosterHolder data={itemChild.images} />
                      : <ImageHolder data={itemChild.images} />
                    } */}

                    {item.type === 1
                      ? <ImagePosterHolder data={itemChild.images} />
                      : <ImageHolder data={itemChild.images} />
                    }
                    {/* <ImageHolder data={itemChild.images} /> */}
                </div>
              </Link>
          }

          {itemChild.type !== 5 &&
            <div className={`info-detail ${item.type === 1 ? 'info_detail_master_banner_mobile' : ''}`} onClick={e => handleClickShowModal(e, itemChild)}>
              <Link
                to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/${item.slug}/`)}${itemChild.slug}`}
                className="link-btn-play"
              >
                <button className={`btn btn-primary btn-play ${item.type === 1 ? 'btn_play_master_banner_mobile' : ''}`}>
                {/* <button className="btn btn-primary btn-play"> */}
                  {/* <FontAwesomeIcon icon={faPlay} className="icon-play me-2" /> */}
                  <Play color="black" variant='Bold' size={12} className='me-1' />
                  {item.type === 1 ? 'Xem ngay' : 'Xem'}
                </button>
              </Link>
              <button className={`btn btn-primary btn-info-detail ${item.type === 1 ? 'btn_play_master_banner_mobile' : ''}`} onClick={e => handleClickShowModal(e, itemChild)}>
              {/* <button className="btn btn-primary btn-info-detail" onClick={e => handleClickShowModal(e, itemChild)}> */}
                {/* <FontAwesomeIcon icon={faInfoCircle} className="icon-info-detail me-2" /> */}
                <InfoCircle color="white" variant='Bold' size={12} className='me-1' />
                Thông tin
              </button>

              {/* {directorStr.length > 0 &&
                <div className="info-text">Đạo diễn: {directorStr}</div>
              }
              {actorStr.length > 0 &&
                <div className="info-text">Diễn viên: {actorStr}</div>
              }
              <div className="info-text pb-2">Xuất bản: {publishDateStr}</div> */}
            </div>
          }
        </figure>
      {/* </Link> */}
    </div>
  )
}

export default RibbonItemTop
