import rootReducer from './slice'
import menuReducer from 'features/menu/menuSlice'
import bannerReducer from 'features/banner/bannerSlice'
import ribbonReducer from 'features/ribbon/ribbonSlice'
import watchingReducer from 'features/watching/watchingSlice'

const rootReducerExport = {
  root: rootReducer,
  menu: menuReducer,
  banner: bannerReducer,
  ribbon: ribbonReducer,
  watching: watchingReducer
}

export default rootReducerExport
