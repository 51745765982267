import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getDataDetail, getDataServerTime } from 'common/utils'
import { VideoSlash } from 'iconsax-react'
import Overlay from 'components/Overlay'
import DetailInfo from './DetailInfo'
import DetailRelate from './DetailRelate'
import DetailAds from './DetailAds'
import 'bootstrap/js/dist/tab'
import '../styles/detail.scss'

const DetailGeoBlock = (props) => {
  // const history = useHistory()
  const { page, ribbon, slug } = useParams()

  const [title, setTitle] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [relateList, setRelateList] = useState([])
  const [hashtagList, setHastagList] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const { data, isError, error, refetch } = useQuery(['getDetail', slug], async () => {
    return await getDataDetail('GET', `get_detail/${slug}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
  }, {
    retry: false,
    cacheTime: 0,
    staleTime: 0
  })

  useEffect(() => {
    window.onpopstate = e => {
      window.location.reload()
    }

    setIsLoading(true)
  }, [])

  useEffect(() => {
    if (isError && error) {
      if (error?.request?.status === 403) {
        const fetchDataServerTime = async () => {
          const resultServerTime = await getDataServerTime()
          if (resultServerTime) {
            const currentTimestamp = Date.now()
            const timeDiff = currentTimestamp - resultServerTime
            localStorage.setItem('TIME_NOW', currentTimestamp)
            localStorage.setItem('TIME_SERVER', resultServerTime)
            localStorage.setItem('TIME_DIFF', timeDiff)
          }

          setTimeout(() => {
            refetch()
          }, 200)
        }

        fetchDataServerTime()
      }
    }
  }, [isError, error])

  useEffect(() => {
    if (data) {
      const dataHashtagTemp = data.hashtags
      if (typeof dataHashtagTemp === 'string') {
        if (dataHashtagTemp !== '') {
          const dataHashtagArr = dataHashtagTemp.split(',')
          setHastagList(dataHashtagArr)
        }
      }

      // eslint-disable-next-line no-undef
      if (gtag) {
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
          page_title: data.title,
          page_location: page ? `https://thvli.vn/detail/${page}/${ribbon}/${slug}` : `https://thvli.vn/detail/${slug}`,
          page_path: page ? `/${page}/${ribbon}/${slug}` : `/detail/${slug}`,
          send_to: 'G-RW9WHCKH4J'
        })
      }

      if (data.type === 6) {
        document.title = data.episode_name
      } else {
        document.title = data.title
      }

      if (data.type === 6) {
        if (data.default_episode && data.default_episode.id) {
          setTitle(data.display_name ? data.display_name : data.episode_name)
        } else {
          setTitle(data.display_name ? data.display_name : data.episode_name)
        }
      } else {
        if (data.default_episode && data.default_episode.id) {
          setTitle(data.display_name ? data.display_name : data.default_episode.title)
        } else {
          setTitle(data.display_name ? data.display_name : data.title)
        }
      }

      setIsLoading(false)
    }
  }, [data])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleRelateList = (value) => {
    setRelateList(value)
  }

  return (
    <div className="detail">
      <div className="row pt-4">
        <div className="col-0 col-md-2">
          <DetailAds data={data} position="left" />
        </div>
        <div className="col-12 col-lg-8">
          <div className='vod-geo-block'>
            <div>
              <VideoSlash color="white" size={32} className='mb-2' />
            </div>
            <div>Sorry, this video is not available in your country</div>
          </div>

          <DetailInfo data={data} title={title} hashtagList={hashtagList} />

          {(data && data.type !== 6) &&
            <DetailRelate data={data} onRelateList={handleRelateList} />
          }
        </div>
        <div className="col-0 col-md-2">
          <div className="banner-ads">
            <DetailAds data={data} position="right" />
          </div>
        </div>
      </div>

      {isLoading && <Overlay />}
    </div>
  )
}

export default DetailGeoBlock
