import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getData, parseDate, getDataAuth, getAgeContentRating, formatDataChunkCustom } from 'common/utils'
import Slider from 'react-slick'
import ImageHolder from './ImageHolder'
import { setProgress } from 'features/watching/watchingSlice'
import { imgholder } from 'constant/images'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faTimes, faRedo } from '@fortawesome/free-solid-svg-icons'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
// import icPlay from './styles/images/ic_play.svg'
import 'bootstrap/js/dist/modal'
import './styles/modal-figure.scss'

const ModalFigure = (props) => {
  const dispatch = useDispatch()

  // eslint-disable-next-line react/prop-types
  // const { type } = props

  const slickRef = useRef(null)
  const progressRef = useRef(null)
  const playerRef = useRef(null)
  const timeoutRef = useRef(null)

  // const history = useHistory()

  // eslint-disable-next-line react/prop-types
  const { type, modalContentId, modalContentSlug, modalContentEpisode, modalContentImage, modalContentDescription, modalContentNewRelease, modalContentPublishDate, isShow, onClose, onClickPlay } = props

  const [show, setShow] = useState(false)

  const [modalType, setModalType] = useState(null)
  const [modalSlug, setModalSlug] = useState(null)
  const [modalImage, setModalImage] = useState(null)
  const [modalContentLogo, setModalContentLogo] = useState(null)
  const [modalPublishDate, setModalPublishDate] = useState(null)
  const [modalSeasonNumber, setModalSeasonNumber] = useState(null)
  const [modalDescription, setModalDescription] = useState(null)
  const [modalDirectorList, setModalDirectorList] = useState([])
  const [modalActorList, setModalActorList] = useState([])
  const [modalRelateList, setModalRelateList] = useState([])
  const [modalTrailerList, setModalTrailerList] = useState([])
  const [modalSeasonList, setModalSeasonList] = useState([])
  const [modalSeason, setModalSeason] = useState(null)
  const [modalSeasonIndex, setModalSeasonIndex] = useState(0)
  const [modalSeasonPartIndex, setModalSeasonPartIndex] = useState(0)
  const [modalEpisodeId, setModalEpisodeId] = useState(null)
  const [modalEpisodeList, setModalEpisodeList] = useState([])
  const [modalEpisodeChunkList, setModalEpisodeChunkList] = useState([])

  const [modalEpisodeNumber, setModalEpisodeNumber] = useState(null)

  // Progress
  const [modalProgress, setModalProgress] = useState(0)
  const [modalRunTime, setModalRunTime] = useState(0)
  const [isEndTrailer, setIsEndTrailer] = useState(false)

  const { data: dataContentId } = useQuery(['getSearchContentId', modalContentId], async () => {
    if (modalContentId) {
      return await getData('GET', `get_detail/${modalContentId}/?platform=web&drm_ver=2`)
    }
  })

  const { data: dataProgress } = useQuery(['getProgressContent', modalContentId], async () => {
    if (modalContentId) {
      if (localStorage.getItem('adm_token')) {
        return await getDataAuth('GET', `progress-detail/${modalContentId}/`)
      }
    }
  })

  const { data: dataSeason, isLoading: isLoadingSeason } = useQuery(['getSeasonContentId', modalSeason], async () => {
    if (modalSeason) {
      // return await getData('GET', `get_season_by_id/${modalSeason.id}/?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
      return await getData('GET', `episodes_by_season_id/${modalSeason.id}/?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
    }
  })

  // const { data: dataEpisode } = useQuery(['getEpisodeContentId', modalEpisodeId], async () => {
  //   if (modalEpisodeId) {
  //     return await getData('GET', `content/${modalEpisodeId}/`)
  //   }
  // })

  const { data: dataRelateContentId } = useQuery(['getRelateContentId', modalContentId], async () => {
    if (modalContentId) {
      return await getData('GET', `related/${modalContentId}/`)
    }
  })

  useEffect(() => {
    if (isShow) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [isShow])

  useEffect(() => {
    if (show) {
      if (type === 'banner') {
        const modalOpenBanner = document.getElementById('modalOpenBanner')
        if (modalOpenBanner) {
          modalOpenBanner.click()
        }
      } else {
        const modalOpen = document.getElementById('modalOpen')
        if (modalOpen) {
          modalOpen.click()
        }
      }
    }
  }, [show])

  useEffect(() => {
    if (dataContentId) {
      setModalType(dataContentId.type)

      // Trailer
      if (Array.isArray(dataContentId.trailers)) {
        setModalTrailerList(dataContentId.trailers)
      }

      if (dataContentId.default_episode && dataContentId.default_episode.id) {
        setModalEpisodeId(dataContentId.default_episode.id)
      } else {
        setModalEpisodeId(dataContentId.id)
      }

      if (modalContentSlug) {
        setModalSlug(modalContentSlug)
      } else {
        setModalSlug(dataContentId.slug)
      }

      // Content Logo
      setModalContentLogo(dataContentId.images && dataContentId.images.content_logo)

      if (dataContentId.images && dataContentId.images.cover && dataContentId.images.cover !== '') {
        setModalImage(dataContentId.images
          ? ((dataContentId.images.cover && dataContentId.images.cover) !== '' ? dataContentId.images.cover : imgholder)
          : imgholder)
      } else {
        setModalImage(dataContentId.images
          ? ((dataContentId.images.thumbnail && dataContentId.images.thumbnail) !== '' ? dataContentId.images.thumbnail : imgholder)
          : imgholder)
      }
      setModalPublishDate(dataContentId.publish_date)
      // setModalDescription(dataContentId.long_description)
      setModalSeasonNumber(dataContentId.seasons && dataContentId.seasons.length)

      const strippedHtml = convertToPlain(
        dataContentId.long_description
      )
      setModalDescription(strippedHtml)

      // let actorStr = ''
      // let directorStr = ''

      const actorArr = []
      const directorArr = []

      if (dataContentId.people) {
        for (let i = 0; i < dataContentId.people.length; i++) {
          if (dataContentId.people[i].role === 'actor') {
            // actorStr += dataContentId.people[i].name + ', '
            actorArr.push(dataContentId.people[i])
          }
          if (dataContentId.people[i].role === 'director') {
            // directorStr += dataContentId.people[i].name + ', '
            directorArr.push(dataContentId.people[i])
          }
        }
      }
      // setModalActor(actorStr.substr(0, actorStr.length - 2))
      // setModalDirector(directorStr.substr(0, directorStr.length - 2))

      setModalActorList(actorArr)
      setModalDirectorList(directorArr)

      // Season
      // let episodeIdTemp = null
      // if (dataContentId.default_episode && dataContentId.default_episode.id) {
      //   episodeIdTemp = dataContentId.default_episode.id
      // } else {
      //   episodeIdTemp = dataContentId.id
      // }

      const seasonListTemp = dataContentId.seasons
      setModalSeasonList(seasonListTemp)

      setModalRunTime(dataContentId.runtime)

      if (dataContentId.type === 2) {
        setModalEpisodeNumber(dataContentId.default_episode?.episode)
      } else {
        setModalEpisodeNumber(null)
      }
    }
  }, [dataContentId])

  useEffect(() => {
    if (isShow === true && Array.isArray(modalTrailerList) && modalTrailerList.length > 0) {
      const firstTrailer = modalTrailerList[0]
      if (firstTrailer) {
        const fetchDetailTrailer = async () => {
          const resultDetailTrailer = await getData('GET', `get_detail/${firstTrailer.id}/?platform=web&drm_ver=2`)
          if (resultDetailTrailer) {
            let linkPlayDetail = ''

            if (resultDetailTrailer.type === 6) {
              if (resultDetailTrailer.default_episode && resultDetailTrailer.default_episode.id) {
                linkPlayDetail = resultDetailTrailer.default_episode.play_info && resultDetailTrailer.default_episode.play_info.data && resultDetailTrailer.default_episode.play_info.data.hls_link_play
              } else {
                linkPlayDetail = resultDetailTrailer.play_info && resultDetailTrailer.play_info.data && resultDetailTrailer.play_info.data.hls_link_play
              }
            } else {
              if (resultDetailTrailer.default_episode && resultDetailTrailer.default_episode.id) {
                linkPlayDetail = resultDetailTrailer.default_episode.play_info && resultDetailTrailer.default_episode.play_info.data && resultDetailTrailer.default_episode.play_info.data.hls_link_play
              } else {
                linkPlayDetail = resultDetailTrailer.play_info && resultDetailTrailer.play_info.data && resultDetailTrailer.play_info.data.hls_link_play
              }
            }

            if (linkPlayDetail) {
              const modalVideoContainer = document.getElementById('modal-video-trailer')
              if (modalVideoContainer) {
                const videoElement = document.createElement('video-js')
                videoElement.classList.add('video-js')
                videoElement.classList.add('vjs-default-skin')
                modalVideoContainer.appendChild(videoElement)

                const playerTemp = playerRef.current = videojs(videoElement, {
                  autoplay: false,
                  // muted: true,
                  loop: false,
                  controls: false,
                  responsive: true,
                  fluid: true,
                  // inactivityTimeout: 4000,
                  html5: {
                    hls: {
                      limitRenditionByPlayerDimensions: false
                    }
                  },
                  sources: [{
                    type: 'application/x-mpegURL',
                    src: linkPlayDetail
                  }]
                }, () => {
                  timeoutRef.current = setTimeout(() => {
                    const posterVideoTemp = document.getElementById('poster-modal-video')
                    if (posterVideoTemp) {
                      posterVideoTemp.style.opacity = '0'
                      posterVideoTemp.style.transition = 'opacity 2s'
                    }

                    playerTemp.play()
                  }, 2000)
                })

                if (playerTemp) {
                  playerTemp.on('ended', () => {
                    setIsEndTrailer(true)
                  })
                }
              }
            }
          }
        }

        fetchDetailTrailer()
      }
    }
  }, [modalTrailerList, isShow])

  useEffect(() => {
    if (dataProgress) {
      setModalProgress(dataProgress.progress)
    }
  }, [dataProgress])

  useEffect(() => {
    if (modalSeasonList && modalSeasonList.length > 0) {
      const checkSeasonItem = modalSeasonList[0] // check season đang xem
      const checkSeasonIndex = 0

      setModalSeason(checkSeasonItem)
      setModalSeasonIndex(checkSeasonIndex)
    }
  }, [modalSeasonList])

  useEffect(() => {
    if (dataSeason) {
      if (dataSeason.episodes) {
        if (Array.isArray(dataSeason.episodes)) {
          setModalEpisodeList(dataSeason.episodes)

          const episodeListTemp = formatDataChunkCustom(dataSeason.episodes, 12)
          setModalEpisodeChunkList(episodeListTemp)
        }
      }
    }
  }, [dataSeason])

  useEffect(() => {
    if (Array.isArray(modalEpisodeChunkList)) {
      for (let i = 0; i < modalEpisodeChunkList.length; i++) {
        const tempArr = modalEpisodeChunkList[i]
        if (Array.isArray(tempArr)) {
          for (let j = 0; j < tempArr.length; j++) {
            if (tempArr[j].id === modalEpisodeId) {
              setModalSeasonPartIndex(i)

              if (slickRef.current) {
                slickRef.current.slickGoTo((i * 12) + j)
              }
            }
          }
        }
      }
    }
  }, [modalEpisodeChunkList])

  useEffect(() => {
    if (dataRelateContentId) {
      // const dataTemp = formatDataChunk(dataRelateContentId, 3)
      setModalRelateList(dataRelateContentId.items)
    }
  }, [dataRelateContentId])

  // Progress
  useEffect(() => {
    if (modalProgress && modalRunTime) {
      const progress = progressRef.current
      const progressBar = document.getElementById('progress-bar')

      if (progress) {
        progress.setAttribute('max', modalRunTime * 60)
      }

      if (progressBar) {
        if (!progress?.getAttribute('max')) {
          progress?.setAttribute('max', (modalRunTime * 60))
        }

        progress.value = modalProgress
        progressBar.style.width =
          Math.floor((modalProgress / (modalRunTime * 60)) * 100) + '%'
      }
    }
  }, [modalProgress, modalRunTime])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleCloseVideoTrailer = () => {
    clearTimeout(timeoutRef.current)

    const playerTemp = playerRef.current
    if (playerTemp) {
      playerTemp.dispose()
      playerRef.current = null
    }
  }

  const handleClickEpisodePart = (e, index) => {
    setModalSeasonPartIndex(index)

    if (slickRef.current) {
      slickRef.current.slickGoTo(index * 12)
    }
  }

  const handleChangeSeason = (e) => {
    const index = e.target.value
    setModalSeason(modalSeasonList[index])
    setModalSeasonIndex(index)
  }

  const handleLinkPlayUrl = (item) => {
    handleCloseVideoTrailer()

    if (item.type === 6) {
      const slugTemp = item.slug
      // const urlTemp = location.pathname.slice(0, location.pathname.lastIndexOf('/'))

      if (slugTemp && slugTemp !== '') {
        window.location.href = `/trailer/${modalSlug || modalContentSlug}/${item.slug}`
      } else {
        window.location.href = `/trailer/${modalSlug || modalContentSlug}/${item.id}`
      }
    } else {
      const slugTemp = item.slug
      // const urlTemp = location.pathname.slice(0, location.pathname.lastIndexOf('/'))

      if (slugTemp && slugTemp !== '') {
        window.location.href = `/detail/${item.slug}`
      } else {
        window.location.href = `/detail/${item.id}`
      }
    }
  }

  const handleClickEpisode = (e, item) => {
    if (item) {
      if (item.status === 7) {
        if (item.has_trailer === false) {
          //
        } else {
          handleLinkPlayUrl(item)
        }
      } else {
        handleLinkPlayUrl(item)
      }
    }
  }

  const handleClickRelate = (e, item) => {
    // history.push(`/detail/${item.slug}`)

    // history.push(`/detail/${item.slug}`)
    // history.push('/empty')
    // history.goBack()

    handleCloseVideoTrailer()
    window.location.href = `/detail/${item.slug}`
  }

  // const handleClickRelate = (e, item) => {
  //   history.push(`/detail/${item.slug}`)
  // }

  const handleCloseModal = () => {
    handleCloseVideoTrailer()

    setShow(false)
    onClose(false)

    setModalSlug(null)
    setModalImage(null)
    setModalContentLogo(null)
    setModalPublishDate(null)
    setModalSeasonNumber(null)
    setModalDescription(null)
    // setModalDirector(null)
    setModalDirectorList([])
    // setModalActor(null)
    setModalActorList([])
    setModalRelateList([])
    setModalTrailerList([])
    setModalSeasonList([])
    setModalSeason(null)
    setModalEpisodeList([])
    setModalEpisodeChunkList([])
    setModalEpisodeId(null)
    setIsEndTrailer(false)

    setModalProgress(0)
    setModalRunTime(0)

    setModalEpisodeNumber(null)
  }

  const convertToPlain = (html) => {
    // Create a new div element
    const tempDivElement = document.createElement('div')

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html

    const htmlValue =
      tempDivElement.textContent || tempDivElement.innerText || ''

    tempDivElement.remove()

    // Retrieve the text property of the element
    return htmlValue
  }

  const handleClickPlayContinue = () => {
    handleCloseVideoTrailer()

    dispatch(setProgress(modalProgress))
  }

  const handleClickPlayFromBegin = () => {
    handleCloseVideoTrailer()

    dispatch(setProgress(0))
  }

  const handleClickPlayNow = () => {
    handleCloseVideoTrailer()
    onClickPlay()
  }

  const renderAgeRatingIcon = (itemParam) => {
    if (itemParam?.content_ratings?.length > 0) {
      const ageRating = getAgeContentRating(
        itemParam?.content_ratings
      )

      if (ageRating && ageRating.image_url) {
        return (
          <div className='modal-ribbon-item-age-rating-wrapper'>
            <img
              className='modal-ribbon-item-age-rating-icon'
              src={ageRating?.image_url}
              // src='https://s3-hcm.cloud.cmctelecom.vn/sctv-main-2/tenants/none_tenant/photos/t13_f5201dc0.png'
            />
          </div>
        )
      }
    }

    return null
  }

  return (
    <div className="modal-figure">
      <div className="modal fade" id={type === 'banner' ? 'modalFigureBackdropBanner' : 'modalFigureBackdrop'} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {(Array.isArray(modalTrailerList) && modalTrailerList.length > 0)
              ? isEndTrailer
                ? <div
                    className="modal-header"
                    style={{ backgroundImage: modalImage ? `url('${modalImage}'), url('${imgholder}')` : `url('${modalContentImage}'), url('${imgholder}')`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}
                  >
                    <div className='modal-header-bottom-gradient' />

                    {modalContentNewRelease &&
                      <div className="modal-new-release">Tập mới</div>
                    }
                    {modalContentLogo &&
                      <img className="modal-content-logo" src={modalContentLogo} alt="" />
                    }

                    <button className="btn btn-dark btn-close-custom" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                : <div className="modal-header">
                    <div className='modal-header-bottom-gradient' />

                    {modalContentNewRelease &&
                      <div className="modal-new-release">Tập mới</div>
                    }
                    {modalContentLogo &&
                      <img className="modal-content-logo" src={modalContentLogo} alt="" />
                    }

                    <div id="modal-video-trailer">
                    </div>

                    <div
                      id="poster-modal-video"
                      className="poster-modal-video"
                      style={{ backgroundImage: modalImage ? `url('${modalImage}'), url('${imgholder}')` : `url('${modalContentImage}'), url('${imgholder}')`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}
                    >
                    </div>

                    <button className="btn btn-dark btn-close-custom" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
              : <div
                  className="modal-header"
                  style={{ backgroundImage: modalImage ? `url('${modalImage}'), url('${imgholder}')` : `url('${modalContentImage}'), url('${imgholder}')`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}
                >
                  <div className='modal-header-bottom-gradient' />

                  {modalContentNewRelease &&
                    <div className="modal-new-release">Tập mới</div>
                  }
                  {modalContentLogo &&
                    <img className="modal-content-logo" src={modalContentLogo} alt="" />
                  }

                  <button className="btn btn-dark btn-close-custom" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
            }
            <div className="modal-body">
              {/* {dataContentId &&
                <Link to={`/detail/${modalSlug}`} onClick={onClickPlay}>
                  <button className="btn btn-primary btn-play" data-bs-dismiss="modal">
                    <FontAwesomeIcon icon={faPlay} className="icon-play me-2" />
                    Xem ngay
                  </button>
                </Link>
              } */}

              {localStorage?.getItem('adm_token')
                ? <>
                    {!modalProgress
                      ? <>
                          {modalType &&
                            <Link to={modalType === 10 ? `/truc-tiep/${modalSlug || modalContentSlug}` : `/detail/${modalSlug || modalContentSlug}`} onClick={handleClickPlayNow}>
                              <button className="btn btn-primary btn-play" data-bs-dismiss="modal">
                                <FontAwesomeIcon icon={faPlay} className="icon-play me-2" />
                                Xem ngay
                              </button>
                            </Link>
                          }
                        </>
                      : <>
                          {modalType &&
                            <>
                              <Link to={modalType === 10 ? `/truc-tiep/${modalSlug || modalContentSlug}` : `/detail/${modalSlug || modalContentSlug}`} onClick={handleClickPlayContinue}>
                                <button className={`btn btn-primary btn-play-continue ${modalEpisodeNumber ? 'btn-ctn-episode' : ''}`} data-bs-dismiss="modal">
                                  <FontAwesomeIcon icon={faPlay} className="icon-play me-2" />
                                  {/* Xem tiếp {modalEpisodeNumber ? ` tập ${modalEpisodeNumber}` : ''} */}
                                  Xem tiếp {modalContentEpisode ? ` tập ${modalContentEpisode}` : ''}
                                </button>
                              </Link>
                              <Link to={modalType === 10 ? `/truc-tiep/${modalSlug || modalContentSlug}` : `/detail/${modalSlug || modalContentSlug}`} onClick={handleClickPlayFromBegin}>
                                <button className="btn btn-primary btn-redo" data-bs-dismiss="modal">
                                  <FontAwesomeIcon icon={faRedo} className="icon-redo me-2" />
                                  Xem từ đầu
                                </button>
                              </Link>
                            </>
                          }

                          <div className={`progress ${modalEpisodeNumber ? 'btn-ctn-episode' : ''}`}>
                            <progress
                              ref={progressRef}
                              value="0"
                              min="0"
                              // onClick={handleClickProgress}
                            >
                              <span id="progress-bar"></span>
                            </progress>
                          </div>
                        </>
                    }
                  </>
                : <>
                    {modalType &&
                      <Link to={modalType === 10 ? `/truc-tiep/${modalSlug || modalContentSlug}` : `/detail/${modalSlug || modalContentSlug}`} onClick={handleClickPlayNow}>
                        <button className="btn btn-primary btn-play" data-bs-dismiss="modal">
                          <FontAwesomeIcon icon={faPlay} className="icon-play me-2" />
                          Xem ngay
                        </button>
                      </Link>
                    }
                  </>
              }

              <div className="row pt-4">
                <div className={`col-${modalSeasonNumber > 0 ? 2 : 3}`}>
                  <div className="publish-date">{modalPublishDate || modalContentPublishDate}</div>
                  {modalSeasonNumber <= 0 &&
                    <>
                      {renderAgeRatingIcon(dataContentId)}
                    </>
                  }
                </div>
                <div className={`col-${modalSeasonNumber > 0 ? 10 : 9}`}>
                  <div className="publish-date">{modalSeasonNumber > 0} {(modalSeasonNumber > 0) && `${modalSeasonNumber} Mùa`}</div>
                  {modalSeasonNumber > 0 &&
                    <>
                      {renderAgeRatingIcon(dataContentId)}
                    </>
                  }
                </div>
              </div>
              <div className="row pt-2">
                <div className="col-6">
                  {modalDescription
                    ? <div className="description">{modalDescription}</div>
                    : (modalContentDescription && modalContentDescription !== 'undefined')
                        ? <div className="description">{modalContentDescription}</div>
                        : ''
                  }
                  {/* <div className="description" dangerouslySetInnerHTML={{ __html: modalDescription }}></div> */}
                </div>
                <div className="col-6">
                  {/* {modalDirector &&
                    <div className="director">Đạo diễn: <span className="info-text">{modalDirector}</span></div>
                  } */}
                  {(Array.isArray(modalDirectorList) && modalDirectorList.length > 0) &&
                    <div className="director">Đạo diễn: <span className="info-text">{modalDirectorList.map((item, index) => {
                      return (
                        <a key={index} href={`/artist/${item.id}/${modalSlug}`} className="link-people">
                          {/* {item.name} */}
                          {item.name + (index < modalDirectorList.length - 1 ? ', ' : '')}
                        </a>
                      )
                    })}</span></div>
                  }
                  {/* {modalActor &&
                    <div className="actor pt-2">Diễn viên: <span className="info-text">{modalActor}</span></div>
                  } */}

                  {(Array.isArray(modalActorList) && modalActorList.length > 0) &&
                    <div className="director">Diễn viên: <span className="info-text">{modalActorList.map((item, index) => {
                      return (
                        <a key={index} href={`/artist/${item.id}/${modalSlug}`} className="link-people">
                          {item.name + (index < modalActorList.length - 1 ? ', ' : '')}
                        </a>
                      )
                    })}</span></div>
                  }
                </div>
              </div>

              <div className="row mt-4 mb-4">
                <div className="col-12">
                  {/* <div className="season-title">
                    <div className="season-title-text">{seasonList[seasonIndex]?.title}</div>

                    <select className="form-select" aria-label="Season" value={seasonIndex} onChange={handleChangeSeason}>
                      {seasonList && seasonList.map((item, index) => {
                        return (
                          <option key={index} value={index}>{item.title}</option>
                        )
                      })}
                    </select>
                  </div> */}

                  {(Array.isArray(modalSeasonList) && modalSeasonList.length > 1)
                    ? <select className="form-select" aria-label="Season" value={modalSeasonIndex} onChange={handleChangeSeason}>
                      {modalSeasonList && modalSeasonList.map((item, index) => {
                        return (
                          <option key={index} value={index}>Mùa {index + 1}</option>
                        )
                      })}
                    </select>
                    : <>
                        {/* <div className="season-title">{modalSeasonList[modalSeasonIndex]?.title}</div> */}

                        {/* {(Array.isArray(modalSeasonList) && modalSeasonList.length > 0) &&
                          <div className="season-title">Danh sách tập</div>
                        } */}
                      </>
                  }

                  {(Array.isArray(modalEpisodeChunkList) && modalEpisodeChunkList.length > 0) &&
                    <div className="season-title">Danh sách tập</div>
                  }

                  {Array.isArray(modalEpisodeChunkList) && modalEpisodeChunkList.map((item, index) => {
                    if (index === modalEpisodeChunkList.length - 1) {
                      const childArrLength = Array.isArray(item) ? item.length : 0

                      return (
                        <span
                          key={index}
                          className={`episode-part ${modalSeasonPartIndex === index ? 'active' : ''}`}
                          onClick={e => handleClickEpisodePart(e, index)}
                        >
                          {(index * 12) + 1} - {(index * 12) + childArrLength}
                        </span>
                      )
                    } else {
                      return (
                        <span
                          key={index}
                          className={`episode-part ${modalSeasonPartIndex === index ? 'active' : ''}`}
                          onClick={e => handleClickEpisodePart(e, index)}
                        >
                          {(index * 12) + 1} - {(index * 12) + 12}
                        </span>
                      )
                    }
                  })}

                  <div className="pt-2 episode-list">
                    {(isLoadingSeason)
                      // ? <Overlay />
                      ? <></>
                      : <>
                        <Slider ref={slider => (slickRef.current = slider)} {...{
                          className: 'modal-relate-ribbon',
                          dots: false,
                          infinite: false,
                          swipeToSlide: true,
                          speed: 500,
                          initialSlide: 0,
                          slidesToShow: 3,
                          slidesToScroll: 3,
                          beforeChange: (current, next) => {
                            if (Array.isArray(modalEpisodeList) && Array.isArray(modalEpisodeChunkList)) {
                              for (let i = 0; i < modalEpisodeChunkList.length; i++) {
                                const itemArrTemp = modalEpisodeChunkList[i]

                                if (Array.isArray(itemArrTemp)) {
                                  for (let j = 0; j < itemArrTemp.length; j++) {
                                    if (itemArrTemp[j] && modalEpisodeList[next]) {
                                      if (itemArrTemp[j].id === modalEpisodeList[next].id) {
                                        let partIndexCheck = i

                                        // Kiểm tra episode
                                        if (next >= modalEpisodeList.length - 4) {
                                          if (modalEpisodeChunkList[i + 1]) {
                                            partIndexCheck = i + 1
                                          }
                                        }
                                        setModalSeasonPartIndex(partIndexCheck)
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          // responsive: [
                          //   { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                          //   { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                          //   { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 3 } },
                          //   { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                          //   { breakpoint: 1350, settings: { slidesToShow: 4, slidesToScroll: 4 } }
                          // ]
                        }}>
                          {modalEpisodeList && modalEpisodeList.map((item, index) => {
                            return (
                              <div
                                key={index}
                                // className="col-6 col-md-3"
                                onClick={e => handleClickEpisode(e, item)}
                              >
                                <figure className="figure">
                                  <div className={`figure-overflow ${item.status === 7 ? 'imgTrailer' : ''}`}>
                                    <ImageHolder data={item.images} />

                                    {/* {item.id !== modalEpisodeId &&
                                      <div className="episode_current_custom">
                                        <img src={icPlay} width={36} height={36} className="ic-play-episode" alt="" />
                                      </div>
                                    } */}

                                    {(item.status === 7 && item.has_trailer)
                                      ? <div className="badgeTrailerContainer">
                                          <div className="badge badgeTrailer">Giới thiệu</div>
                                        </div>
                                      : <>
                                          {(item.status !== 7 && item.new_release)
                                            ? <div className="badgeNewReleaseContainer">
                                                <div className="badge badgeNewRelease">Tập mới</div>
                                              </div>
                                            : <></>
                                          }
                                        </>
                                    }
                                  </div>
                                  <figcaption className={`figure-caption ${item.status === 7 ? 'titleTrailer' : ''}`} role="button">
                                    {item.status === 7
                                      ? item.display_name + ' | Ra mắt ' + parseDate(item.release_date)
                                      : (item.display_name ? item.display_name : item.title)
                                    }
                                  </figcaption>
                                </figure>
                              </div>
                            )
                          })}
                        </Slider>
                      </>}
                  </div>
                </div>
              </div>

              {(Array.isArray(modalTrailerList) && modalTrailerList.length > 0) &&
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="season-title">Trailer & Hot Clips</div>

                    <Slider {...{
                      className: 'modal-relate-ribbon',
                      dots: false,
                      infinite: false,
                      swipeToSlide: false,
                      speed: 500,
                      initialSlide: 0,
                      slidesToShow: 3,
                      slidesToScroll: 3
                      // responsive: [
                      //   { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                      //   { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                      //   { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 3 } },
                      //   { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                      //   { breakpoint: 1350, settings: { slidesToShow: 4, slidesToScroll: 4 } }
                      // ]
                    }}>
                      {Array.isArray(modalTrailerList) && modalTrailerList.map((itemGrandChild, indexGrandChild) => {
                        return (
                          <div key={indexGrandChild} className="col-3" onClick={e => handleClickEpisode(e, itemGrandChild)}>
                            <figure className="figure">
                              <div className="figure-overflow">
                                <ImageHolder data={itemGrandChild.images} />

                                {/* <div className="episode_current_custom">
                                  <img src={icPlay} width={36} height={36} className="ic-play-episode" alt="" />
                                </div> */}
                              </div>
                              {/* <figcaption className="figure-caption" role="button">{itemGrandChild.title}</figcaption> */}
                            </figure>
                          </div>
                        )
                      })}
                    </Slider>
                  </div>
                </div>
              }

              {(modalRelateList && modalRelateList.length > 0) &&
                <div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <div className="relate-text pb-2">Đề xuất cho bạn</div>

                      {/* <div className="row pt-2 pb-2"> */}
                      <div>
                        <Slider {...{
                          className: 'modal-relate-ribbon',
                          dots: false,
                          infinite: false,
                          swipeToSlide: true,
                          speed: 500,
                          initialSlide: 0,
                          slidesToShow: 3,
                          slidesToScroll: 3
                          // responsive: [
                          //   { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                          //   { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                          //   { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 3 } },
                          //   { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                          //   { breakpoint: 1350, settings: { slidesToShow: 4, slidesToScroll: 4 } }
                          // ]
                        }}>
                          {modalRelateList && modalRelateList.map((item, index) => {
                            return (
                              <div key={index} onClick={e => handleClickRelate(e, item)}>
                                <figure className="figure">
                                  <div className="figure-overflow">
                                    <ImageHolder data={item.images} />

                                    {/* <div className="episode_current_custom">
                                      <img src={icPlay} width={36} height={36} className="ic-play-episode" alt="" />
                                    </div> */}
                                  </div>

                                  <figcaption className="figure-caption" role="button">{item.title}</figcaption>
                                </figure>
                              </div>
                            )
                          })}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <button
        id={type === 'banner' ? 'modalOpenBanner' : 'modalOpen'}
        style={{ display: 'none' }}
        data-bs-toggle="modal"
        data-bs-target={type === 'banner' ? '#modalFigureBackdropBanner' : '#modalFigureBackdrop'}
      >
        Open
      </button>
    </div>
  )
}

ModalFigure.propTypes = {
  modalContentId: PropTypes.string,
  modalContentSlug: PropTypes.any,
  modalContentEpisode: PropTypes.any,
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onClickPlay: PropTypes.func
}

ModalFigure.defaultProps = {
  modalContentId: '',
  isShow: false
}

export default ModalFigure
