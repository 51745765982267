import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { imgholder } from 'constant/images'

const ImageHolder = (props) => {
  const { data } = props

  const [loaded, setLoaded] = useState(false)

  return (
    <div className="image-holder">
      {loaded
        ? null
        : <img
            className="figure-img img-fluid rounded-block w-100 img-thumbnail"
            src={imgholder}
          />
      }
      <img
        className="figure-img img-fluid rounded-block w-100 img-thumbnail"
        style={loaded ? {} : { display: 'none' }}
        src={data
          ? (data.thumbnail && data.thumbnail) !== ''
              ? data.thumbnail
              : imgholder
          : imgholder
        }
        onLoad={() => setLoaded(true)}
        onError={(e) => {
          e.target.onerror = null
          e.target.src = imgholder
        }}
      />
    </div>
  )
}

ImageHolder.propTypes = {
  data: PropTypes.object
}

ImageHolder.defaultProps = {
  data: null
}

export default ImageHolder
