import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { initializeApp } from 'firebase/app'
import { getDataPublisher } from 'common/utils'
import { getRemoteConfig, getValue, fetchAndActivate } from 'firebase/remote-config'
import { DFPSlotsProvider, AdSlot } from 'react-dfp'

// eslint-disable-next-line react/prop-types
const AdsItem = ({ type, platform, adsPos, adsClassName }) => {
  // Initialize Firebase
  const firebaseConfig = {
    apiKey: 'AIzaSyCkxCx869eG8aPFoZOc7K2U1bIfBnRr2os',
    authDomain: 'thvli-48223.firebaseapp.com',
    databaseURL: 'https://thvli-48223.firebaseio.com',
    projectId: 'thvli-48223',
    storageBucket: 'thvli-48223.appspot.com',
    messagingSenderId: '513556246464',
    appId: '1:513556246464:web:8f3b68253b4f3625a76225',
    measurementId: 'G-NW8BGJLPSG'
  }
  const app = initializeApp(firebaseConfig)

  // Initialize Remote Config and get a reference to the service
  const remoteConfig = getRemoteConfig(app)
  remoteConfig.settings.minimumFetchIntervalMillis = 5 * 60 * 1000

  const [publisher, setPublisher] = useState(null)
  const [configData, setConfigData] = useState(null)

  const { data: dataPublisher } = useQuery('getPublisher', async () => {
    return await getDataPublisher()
  })

  useEffect(() => {
    if (dataPublisher) {
      if (type === 'player') {
        setPublisher('GAM')
      } else {
        const resultData = dataPublisher.data
        if (resultData) {
          const posValue = resultData[adsPos]
          if (posValue === 'gam' || posValue === 'GAM') {
            setPublisher(posValue)
          } else {
            setPublisher('MGID')
          }
        }
      }
    }
  }, [dataPublisher])

  useEffect(() => {
    if (publisher === 'gam' || publisher === 'GAM') {
      fetchAndActivate(remoteConfig)
        .then(() => {
          const val = getValue(remoteConfig, adsPos)
          if (val) {
            const valString = val._value
            if (typeof valString === 'string') {
              const objVal = JSON.parse(val._value)
              if (objVal) {
                setConfigData(objVal)
              }
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else if (publisher === 'MGID') {
      fetchAndActivate(remoteConfig)
        .then(() => {
          const val = getValue(remoteConfig, adsPos)
          if (val) {
            const valString = val._value
            if (typeof valString === 'string') {
              const objVal = JSON.parse(val._value)
              if (objVal) {
                if (objVal?.desktop?.ads_mgid_script !== objVal?.mobile?.ads_mgid_script) {
                  const script = document.createElement('script')
                  const scriptMobile = document.createElement('script')

                  script.src = objVal?.desktop?.ads_mgid_script
                  script.async = true

                  scriptMobile.src = objVal?.mobile?.ads_mgid_script
                  scriptMobile.async = true

                  document.body.appendChild(script)
                  document.body.appendChild(scriptMobile)
                  setConfigData(objVal)

                  return () => {
                    document.body.removeChild(script)
                    document.body.removeChild(scriptMobile)
                  }
                } else {
                  const script = document.createElement('script')
                  script.src = objVal?.desktop?.ads_mgid_script
                  script.async = true

                  document.body.appendChild(script)
                  setConfigData(objVal)

                  return () => {
                    document.body.removeChild(script)
                  }
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [publisher])

  return (
    <>
      {configData &&
        <>
          {(publisher === 'MGID') &&
            <div>
              <div className={adsClassName} id={platform === 'mobile' ? configData?.mobile?.ads_mgid_id : configData?.desktop?.ads_mgid_id}></div>
            </div>
          }

          {(publisher === 'gam' || publisher === 'GAM') &&
            <div className={adsClassName}>
              <DFPSlotsProvider dfpNetworkId={platform === 'mobile' ? configData?.mobile?.ads_unit_id : configData?.desktop?.ads_unit_id}>
                <AdSlot
                  sizes={[[platform === 'mobile' ? configData?.mobile?.ads_width : configData?.desktop?.ads_width, platform === 'mobile' ? configData?.mobile?.ads_height : configData?.desktop?.ads_height]]}
                  adUnit={platform === 'mobile' ? configData?.mobile?.ads_unit : configData?.desktop?.ads_unit}
                />
              </DFPSlotsProvider>
            </div>
          }
        </>
      }
    </>
  )
}

export default AdsItem
