import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useMediaQuery } from 'common/hook-custom'
import { getProfileData } from 'common/utils'
import axios from 'axios'
import Layout from 'features/layout/components/Layout'
import Profile from '../components/Profile'
import ProfileMobile from '../components/ProfileMobile'
import Overlay from 'components/Overlay'

const ProfilePage = (props) => {
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  const [email, setEmail] = useState('')
  const [fullname, setFullname] = useState('')
  const [isActive, setIsActive] = useState(1)
  const [loginMethod, setLoginMethod] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  const { data: dataProfile } = useQuery('getProfile', async () => {
    return await getProfileData()
  })

  useEffect(() => {
    document.title = 'Tài khoản'

    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Tài khoản',
        page_location: 'https://thvli.vn/profile',
        page_path: '/profile',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    if (!localStorage.getItem('adm_token')) {
      window.location.href = '/'
    }
  }, [])

  useEffect(() => {
    if (dataProfile) {
      setEmail(dataProfile.email)
      if (dataProfile.first_name === 'undefined' || dataProfile.last_name === 'undefined') {
        if (dataProfile.login_method === 'google') {
          setFullname('Bạn đang đăng nhập bằng Google')
        } else if (dataProfile.login_method === 'facebook') {
          setFullname('Bạn đang đăng nhập bằng Facebook')
        } else if (dataProfile.login_method === 'apple') {
          setFullname('Bạn đang đăng nhập bằng Apple')
        } else {
          setFullname('')
        }
      } else {
        setFullname(dataProfile.first_name + ' ' + dataProfile.last_name)
      }
      setIsActive(dataProfile.is_active)
      setLoginMethod(dataProfile.login_method)
    }
  }, [dataProfile])

  const handleClickResendActiveEmail = () => {
    setIsLoading(true)

    const formData = new FormData()
    formData.append('email', email)

    axios.post(`${process.env.REACT_APP_API_SETTING_URL}register/resend-email-active/`, formData)
      .then(res => {
        if (res.status === 200) {
          const successResendActiveEmailModalButton = document.getElementById('successResendActiveEmailModalButton')
          if (successResendActiveEmailModalButton) {
            successResendActiveEmailModalButton.click()
          }
        }
        setIsLoading(false)
      })
      .catch(err => {
        const errData = err.response && err.response.data
        if (errData) {
          if (errData.error === 400) {
            //
          } else if (errData.error === 412) {
            //
          } else {
            // setError('Something went wrong!')
          }
        } else {
          // setError('Something went wrong!')
        }

        setIsLoading(false)
        console.log(err)
      })
  }

  const handleClickShowModalDeleteAccount = () => {
    const deleteAccountModalButton = document.getElementById('deleteAccountModalButton')
    if (deleteAccountModalButton) {
      deleteAccountModalButton.click()
    }
  }

  const handleClickDeleteAccount = () => {
    setIsLoading(true)

    axios.delete(`${process.env.REACT_APP_API_SETTING_URL}profile/`, {
      headers: {
        Authorization: `${localStorage.getItem('adm_token')}`
      }
    })
      .then(res => {
        if (res.status === 200) {
          const successDeleteAccountModalButton = document.getElementById('successDeleteAccountModalButton')
          if (successDeleteAccountModalButton) {
            successDeleteAccountModalButton.click()
          }
        }
        setIsLoading(false)
      })
      .catch(err => {
        alert('Có lỗi xảy ra')
        setIsLoading(false)
        console.log(err)
      })
  }

  const handleClickSuccessDeleteAccount = () => {
    localStorage.removeItem('adm_token')
    window.location.reload()
  }

  return (
    <Layout>
      <div className="profile-page">
        {isDesktop
          ? <Profile
              email={email}
              fullname={fullname}
              isActive={isActive}
              loginMethod={loginMethod}
              handleClickResendActiveEmail={handleClickResendActiveEmail}
              handleClickShowModalDeleteAccount={handleClickShowModalDeleteAccount}
            />
          : <ProfileMobile
              email={email}
              fullname={fullname}
              isActive={isActive}
              loginMethod={loginMethod}
              handleClickResendActiveEmail={handleClickResendActiveEmail}
              handleClickShowModalDeleteAccount={handleClickShowModalDeleteAccount}
            />
        }

        <div className="modal fade" id="successResendActiveEmailModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Thông báo</h5>
                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              </div>
              <div className="modal-body">
                Vui lòng kiểm tra thông tin kích hoạt tài khoản thông qua email.
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Đồng ý</button>
              </div>
            </div>
          </div>
        </div>

        <button style={{ display: 'none' }} type="button" id="successResendActiveEmailModalButton" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#successResendActiveEmailModal">
          Launch static backdrop modal
        </button>

        {/* Xóa tài khoản */}
        <div className="modal fade" id="deleteAccountModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Thông báo</h5>
                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              </div>
              <div className="modal-body">
                Bạn có chắc chắn muốn xóa tài khoản?
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Hủy</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleClickDeleteAccount}>Đồng ý</button>
              </div>
            </div>
          </div>
        </div>

        <button style={{ display: 'none' }} type="button" id="deleteAccountModalButton" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#deleteAccountModal">
          Launch static backdrop modal
        </button>
      </div>

      <div className="modal fade" id="successDeleteAccountModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Thông báo</h5>
                {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              </div>
              <div className="modal-body">
                Xóa tài khoản thành công.
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleClickSuccessDeleteAccount}>Đồng ý</button>
              </div>
            </div>
          </div>
        </div>

        <button style={{ display: 'none' }} type="button" id="successDeleteAccountModalButton" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#successDeleteAccountModal">
          Launch static backdrop modal
        </button>

      {isLoading && <Overlay />}
    </Layout>
  )
}

export default ProfilePage
