import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useInfiniteQuery } from 'react-query'
import { getData } from 'common/utils'
import { useMediaQuery } from 'common/hook-custom'
import Layout from 'features/layout/components/Layout'
import RibbonDetail from '../components/RibbonDetail'
import RibbonDetailMobile from '../components/RibbonDetailMobile'
import '../styles/ribbon-detail-page.scss'

const RibbonDetailPage = (props) => {
  const { slug, ribbonSlug } = useParams()
  const loadMoreRef = useRef()
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  const [title, setTitle] = useState('')
  const [data, setData] = useState([])

  const {
    data: dataRibbon,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(['getRibbonDetail', ribbonSlug], async ({ pageParam = 0 }) => {
    if (ribbonSlug) {
      return await getData('GET', `ribbon/${ribbonSlug}/?platform=web&page=${pageParam}&limit=20`)
    } else {
      return []
    }
  }, {
    getNextPageParam: lastPage => {
      if (lastPage.metadata) {
        return (lastPage.metadata.page + 1) ?? false
      } else {
        return false
      }
    }
  })

  useEffect(() => {
    if (dataRibbon) {
      document.title = dataRibbon.pages && dataRibbon.pages[0] && dataRibbon.pages[0].name
      setTitle(dataRibbon.pages && dataRibbon.pages[0] && dataRibbon.pages[0].name)
      setData(dataRibbon.pages)
    }
  }, [dataRibbon])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */

  return (
    <Layout>
      <div className="ribbon-detail-page">
        <div className="pb-3 mx-0 mx-md-5">
          <div className="row">
            <div className="col-12">
              <div className="title ms-md-3 ms-lg-5">{title}</div>
            </div>
          </div>

          <div className="mx-md-3 mx-lg-5 pt-2">
            {isDesktop
              ? <RibbonDetail
                  data={data}
                  slug={slug}
                  ribbonSlug={ribbonSlug}
                  loadMoreRef={loadMoreRef}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  isLoading={isLoading}
                  isFetchingNextPage={isFetchingNextPage}
                />
              : <RibbonDetailMobile
                  data={data}
                  slug={slug}
                  ribbonSlug={ribbonSlug}
                  loadMoreRef={loadMoreRef}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  isLoading={isLoading}
                  isFetchingNextPage={isFetchingNextPage}
                />
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RibbonDetailPage
