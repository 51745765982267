import React from 'react'
import Layout from 'features/layout/components/Layout'
import ChangeInfo from '../components/ChangeInfo'

const ChangeInfoPage = (props) => {
  return (
    <Layout>
      <ChangeInfo />
    </Layout>
  )
}

export default ChangeInfoPage
