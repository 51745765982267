import React, { useEffect } from 'react'

const AdsPage = (props) => {
  useEffect(() => {
    const urlStr = window.location.href
    const url = new URL(urlStr)
    const idParamUrl = url.searchParams.get('id')
    const urlParamUrl = url.searchParams.get('url')

    if (idParamUrl && urlParamUrl) {
      const pageElement = document.getElementById('ads-page')

      const divElement = document.createElement('div')
      divElement.setAttribute('id', idParamUrl)
      pageElement.appendChild(divElement)

      const script = document.createElement('script')
      script.src = urlParamUrl
      pageElement.appendChild(script)
    }
  }, [])

  return (
    <div id="ads-page"></div>
  )
}

export default AdsPage
