import { createSlice } from '@reduxjs/toolkit'

const bannerSlice = createSlice({
  name: 'banner',
  initialState: {
    bannerList: null
  },
  reducers: {
    setBannerList (state, action) {
      state.bannerList = action.payload
    }
  }
})

export const {
  setBannerList
} = bannerSlice.actions
export default bannerSlice.reducer
