import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { imgAuthBackground } from 'constant/images'
import InputForm from 'components/InputForm'
import Overlay from 'components/Overlay'
import '../styles/forget-password.scss'

const ForgetPassword = (props) => {
  const { handleSubmit, register, getValues, formState: { errors } } = useForm()

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Quên mật khẩu',
        page_location: 'https://thvli.vn/forget-password',
        page_path: '/forget-password',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    document.title = 'Quên mật khẩu'

    if (localStorage.getItem('adm_token')) {
      window.location.href = '/'
    }
  }, [])

  const onSubmit = (data) => {
    if (data) {
      setIsLoading(true)

      const formData = new FormData()
      formData.append('email', data.email)

      axios.post(`${process.env.REACT_APP_API_SETTING_URL}password/forget_password/`, formData)
        .then(res => {
          if (res.status === 200) {
            setError('')
            const successForgetPasswordModalButton = document.getElementById('successForgetPasswordModalButton')
            if (successForgetPasswordModalButton) {
              successForgetPasswordModalButton.click()
            }
          }
          setIsLoading(false)
        })
        .catch(err => {
          const errData = err.response && err.response.data
          if (errData) {
            if (errData.error === 400) {
              const messageError = errData.message ? errData.message : errData.detail
              setError(messageError)
            } else if (errData.error === 412) {
              const messageError = errData.data && errData.data.error_msg
              setError(messageError)
            } else {
              setError('Something went wrong!')
            }
          } else {
            setError('Something went wrong!')
          }

          setIsLoading(false)
          console.log(err)
        })
    }
  }

  const handleClickConfirm = () => {
    window.location.href = `/update-forget-password?email=${getValues('email')}`
  }

  return (
    <div className="forget-password">
      <div className="auth-background">
        <img src={imgAuthBackground} />
      </div>

      <div className="auth-title">QUÊN MẬT KHẨU</div>

      {error !== '' &&
        <div className="row pt-4">
          <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        </div>
      }

      <div className="row pt-4">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <div className="text-instruction">Nhập email của bạn để lấy mã xác nhận</div>
        </div>
      </div>

      <div className="row pt-3">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <InputForm register={register} error={errors.email} required name="email" label="Email" className="register-input" placeholder="Email" />
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <button className="btn btn-primary w-100" onClick={handleSubmit(onSubmit)}>Lấy mã xác nhận</button>
        </div>
      </div>

      <div className="row pt-4 pb-5">
        <div className="col-12 col-md-6 col-lg-4 offset-0 offset-md-3 offset-lg-4">
          <div className="text-login">Bạn đã có tài khoản? <Link to="/login"><span className="text-primary">Đăng nhập ngay</span></Link></div>
        </div>
      </div>

      <div className="modal fade" id="successForgetPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Thông báo</h5>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              Vui lòng kiểm tra thông tin đổi mật khẩu thông qua email.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleClickConfirm}>Đồng ý</button>
            </div>
          </div>
        </div>
      </div>

      <button style={{ display: 'none' }} type="button" id="successForgetPasswordModalButton" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#successForgetPasswordModal">
        Launch static backdrop modal
      </button>

      {isLoading && <Overlay />}
    </div>
  )
}

export default ForgetPassword
