import React from 'react'
import Layout from 'features/layout/components/Layout'
import Register from '../components/Register'

const RegisterPage = (props) => {
  return (
    <Layout>
      <Register />
    </Layout>
  )
}

export default RegisterPage
