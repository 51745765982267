import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData, formatDataChunk } from 'common/utils'
import ImageHolder from 'features/core/ImageHolder'
import AgeRatingItem from 'components/AgeRatingItem'
import NewReleaseItem from 'components/NewReleaseItem'
import imgArtistPlaceholder from '../styles/images/artist_placeholder.png'
import '../styles/artist-mobile.scss'

const ArtistMobileCustom = () => {
  const { slug } = useParams()

  const [name, setName] = useState('')
  const [country, setCountry] = useState('')
  const [dataList, setDataList] = useState([])
  const [avatar, setAvatar] = useState(null)
  const [role, setRole] = useState('')

  const { data } = useQuery(['getArtist', slug], async () => {
    if (slug) {
      return await getData('GET', `artist/${slug}/contents/`)
    }
  })

  useEffect(() => {
    if (data) {
      setRole(data.job_title)
      setCountry(data.country_name)
      setAvatar(data.avatar_url)
      setName(data.name)

      const dataTemp = formatDataChunk(data, 4)
      if (Array.isArray(dataTemp)) {
        setDataList(dataTemp)
      }
    }
  }, [data])

  return (
    <div className="artist-mobile">
      <div className="row">
        <div className="col-md-12">
          <div className="text-artist">NGHỆ SĨ</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="artist-container">
            <div className="info-container">
              {avatar
                ? <img
                    className="artist-img"
                    src={avatar}
                    width={85}
                    height={85}
                    alt=""
                  />
                : <img
                    className="artist-img"
                    src={imgArtistPlaceholder}
                    width={85}
                    height={85}
                    alt=""
                  />
                }
              <div className="artist-name">{name}</div>
              <div className="text-nation">Nghề nghiệp: <span className="text-detail">{role}</span></div>
              <div className="text-nation">Quốc gia: <span className="text-detail">{country}</span></div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="text-joined">Đã tham gia</div>
        </div>
      </div>
      {Array.isArray(dataList) && dataList.map((item, index) => {
        return (
          <div key={index} className='row'>
            {Array.isArray(item) && item.map((itemChild, indexChild) => {
              return (
                <React.Fragment key={indexChild}>
                  <div className='col-6 item-figure'>
                    <Link to={`/detail/${itemChild.slug}`}>
                      <figure className="figure">
                        <div className="figure-overflow">
                          <AgeRatingItem item={itemChild} />
                          <NewReleaseItem item={itemChild} isPoster />

                          <ImageHolder data={itemChild.images} />
                        </div>
                        <figcaption className="figure-caption">
                          {itemChild.title}
                        </figcaption>
                      </figure>
                    </Link>
                  </div>

                  {((indexChild === item.length - 1) && item.length === 1) &&
                    <>
                      <div className='col item-figure col-artist-joined'></div>
                    </>
                  }
                </React.Fragment>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default ArtistMobileCustom
