import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
// import { imgAuthBackground } from 'constant/images'
import InputForm from 'components/InputForm'
import Overlay from 'components/Overlay'
import '../styles/change-password.scss'

const ChangePassword = (props) => {
  const { handleSubmit, register, formState: { errors } } = useForm()

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Đổi mật khẩu',
        page_location: 'https://thvli.vn/change-password',
        page_path: '/change-password',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    document.title = 'Đổi mật khẩu'

    if (!localStorage.getItem('adm_token')) {
      window.location.href = '/'
    }
  }, [])

  const onSubmit = (data) => {
    if (data) {
      setIsLoading(true)

      if (data.password !== data.confirm_password) {
        setError('Nhập lại mật khẩu mới không chính xác!')
        setIsLoading(false)
      } else {
        const formData = new FormData()
        formData.append('old_password', data.old_password)
        formData.append('password', data.password)

        axios.post(`${process.env.REACT_APP_API_SETTING_URL}password/change/`, formData, {
          headers: {
            Authorization: `${localStorage.getItem('adm_token')}`
          }
        })
          .then(res => {
            if (res.status === 200) {
              setError('')

              localStorage.removeItem('adm_email')
              localStorage.removeItem('adm_avatar')
              localStorage.removeItem('adm_token')

              const successUpdateForgetPasswordModalButton = document.getElementById('successUpdateForgetPasswordModalButton')
              if (successUpdateForgetPasswordModalButton) {
                successUpdateForgetPasswordModalButton.click()
              }
            }
            setIsLoading(false)
          })
          .catch(err => {
            const errData = err.response && err.response.data
            if (errData) {
              if (errData.error === 400) {
                const messageError = errData.message ? errData.message : errData.detail
                setError(messageError)
              } else if (errData.error === 412) {
                const messageError = errData.data && errData.data.error_msg
                setError(messageError)
              } else {
                setError('Something went wrong!')
              }
            } else {
              setError('Something went wrong!')
            }

            setIsLoading(false)
            console.log(err)
          })
      }
    }
  }

  const handleClickConfirm = () => {
    window.location.href = '/login'
  }

  return (
    <div className="change-password">
      {/* <div className="auth-background">
        <img src={imgAuthBackground} />
      </div> */}

      <div className="auth-title">ĐỔI MẬT KHẨU</div>

      {error !== '' &&
        <div className="row pt-4">
          <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        </div>
      }

      <div className="row pt-4">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <InputForm type="password" register={register} error={errors.old_password} required name="old_password" label="Mật khẩu hiện tại" className="register-input" placeholder="Mật khẩu hiện tại" />
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <InputForm type="password" register={register} error={errors.password} required name="password" label="Mật khẩu mới" className="register-input" placeholder="Mật khẩu mới" />
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <InputForm type="password" register={register} error={errors.confirm_password} required name="confirm_password" label="Nhập lại mật khẩu mới" className="register-input" placeholder="Nhập lại mật khẩu mới" />
        </div>
      </div>
      <div className="row pt-3 pb-5">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <button className="btn btn-primary w-100 btn-change-password" onClick={handleSubmit(onSubmit)}>Thay đổi mật khẩu</button>
        </div>
      </div>

      <div className="modal fade" id="successUpdateForgetPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Thông báo</h5>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              Đổi mật khẩu thành công, vui lòng đăng nhập lại.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleClickConfirm}>Đồng ý</button>
            </div>
          </div>
        </div>
      </div>

      <button style={{ display: 'none' }} type="button" id="successUpdateForgetPasswordModalButton" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#successUpdateForgetPasswordModal">
        Launch static backdrop modal
      </button>

      {isLoading && <Overlay />}
    </div>
  )
}

export default ChangePassword
