import React from 'react'
import Layout from 'features/layout/components/Layout'
import DetailLiveEvent from '../components/DetailLiveEvent'

const DetailLiveEventPage = (props) => {
  return (
    <Layout>
      <DetailLiveEvent />
    </Layout>
  )
}

export default DetailLiveEventPage
