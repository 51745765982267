import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { getProfileData } from 'common/utils'
// import { imgAuthBackground } from 'constant/images'
import InputForm from 'components/InputForm'
import Overlay from 'components/Overlay'
import '../styles/change-password.scss'

const ChangeInfo = (props) => {
  const history = useHistory()
  const { handleSubmit, register, setValue, formState: { errors } } = useForm()

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { data: dataProfile } = useQuery('getChangeInfoProfile', async () => {
    return await getProfileData()
  })

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Đổi thông tin',
        page_location: 'https://thvli.vn/change-info',
        page_path: '/change-info',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    document.title = 'Đổi thông tin'

    if (!localStorage.getItem('adm_token')) {
      window.location.href = '/'
    }
  }, [])

  useEffect(() => {
    if (dataProfile) {
      setValue('first_name', dataProfile.first_name)
      setValue('last_name', dataProfile.last_name)
    }
  }, [dataProfile])

  const onSubmit = (data) => {
    if (data) {
      setIsLoading(true)

      const formData = new FormData()
      formData.append('first_name', data.first_name)
      formData.append('last_name', data.last_name)

      axios.post(`${process.env.REACT_APP_API_SETTING_URL}profile/`, formData, {
        headers: {
          Authorization: `${localStorage.getItem('adm_token')}`
        }
      })
        .then(res => {
          if (res.status === 200) {
            setError('')

            const successChangeInfoModalButton = document.getElementById('successChangeInfoModalButton')
            if (successChangeInfoModalButton) {
              successChangeInfoModalButton.click()
            }
          }
          setIsLoading(false)
        })
        .catch(err => {
          const errData = err.response && err.response.data
          if (errData) {
            if (errData.error === 400) {
              const messageError = errData.message ? errData.message : errData.detail
              setError(messageError)
            } else if (errData.error === 412) {
              const messageError = errData.data && errData.data.error_msg
              setError(messageError)
            } else {
              setError('Something went wrong!')
            }
          } else {
            setError('Something went wrong!')
          }

          setIsLoading(false)
          console.log(err)
        })
    }
  }

  const handleClickConfirm = () => {
    history.goBack()
  }

  return (
    <div className="change-password">
      {/* <div className="auth-background">
        <img src={imgAuthBackground} />
      </div> */}

      <div className="auth-title">ĐỔI THÔNG TIN</div>

      {error !== '' &&
        <div className="row pt-4">
          <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        </div>
      }

      <div className="row pt-4">
        <div className="col-5 col-md-3 col-lg-2 offset-1 offset-md-3 offset-lg-4">
          <InputForm register={register} error={errors.first_name} required name="first_name" label="Họ" className="register-input" placeholder="Họ" />
        </div>
        <div className="col-5 col-md-3 col-lg-2">
          <InputForm register={register} error={errors.last_name} required name="last_name" label="Tên" className="register-input" placeholder="Tên" />
        </div>
      </div>
      <div className="row pt-3 pb-5">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <button className="btn btn-primary w-100 btn-change-password" onClick={handleSubmit(onSubmit)}>Thay đổi Họ Tên</button>
        </div>
      </div>

      <div className="modal fade" id="successChangeInfoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Thông báo</h5>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              Đổi thông tin thành công
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleClickConfirm}>Đồng ý</button>
            </div>
          </div>
        </div>
      </div>

      <button style={{ display: 'none' }} type="button" id="successChangeInfoModalButton" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#successChangeInfoModal">
        Launch static backdrop modal
      </button>

      {isLoading && <Overlay />}
    </div>
  )
}

export default ChangeInfo
