import { createSlice } from '@reduxjs/toolkit'

const ribbonSlice = createSlice({
  name: 'ribbon',
  initialState: {
    ribbonList: null
  },
  reducers: {
    setRibbonList (state, action) {
      state.ribbonList = action.payload
    }
  }
})

export const {
  setRibbonList
} = ribbonSlice.actions
export default ribbonSlice.reducer
