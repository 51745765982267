/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react'
import { msToTimeRibbon } from 'common/utils'
import '../styles/ribbon-item-live-event.scss'

const RibbonItemLiveEvent = ({ data }) => {
  const intervalRef = useRef(null)

  const [countdownTime, setCountdownTime] = useState('idle')

  const handleIntervalEvent = () => {
    if (data) {
      if (data.type === 10) {
        const now = new Date()

        if (typeof data?.event_start_time === 'number' && typeof data?.event_end_time === 'number') {
          const eventStartTime = new Date(data?.event_start_time * 1000)
          const eventEndTime = new Date(data?.event_end_time * 1000)

          if (eventStartTime && eventEndTime) {
            if (now.getTime() < eventStartTime.getTime()) {
              const countdownTimeTemp = eventStartTime.getTime() - now.getTime()
              setCountdownTime(msToTimeRibbon(countdownTimeTemp))
            } else if (now.getTime() > eventEndTime.getTime()) {
              setCountdownTime(null)
            } else {
              setCountdownTime(1)
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    if (data) {
      handleIntervalEvent()

      intervalRef.current = setInterval(() => {
        handleIntervalEvent()
      }, [60000])

      return () => {
        clearInterval(intervalRef.current)
      }
    }
  }, [data])

  useEffect(() => {
    if (intervalRef?.current) {
      return () => {
        clearInterval(intervalRef.current)
      }
    }
  }, [intervalRef])

  return (
    <>
      {data?.type === 10 &&
        <div className='ribbon-item-live-event'>
          {countdownTime
            ? countdownTime === 1
              ? <>
                  <div className='live-event-streaming'></div>
                  <div>Đang phát</div>
                </>
              : <>{`Diễn ra sau ${countdownTime}`}</>
            : <>
                {countdownTime === 'idle'
                  ? <></>
                  : <>
                      Đã kết thúc
                    </>
                }
              </>
          }
        </div>
      }
    </>
  )
}

export default RibbonItemLiveEvent
