import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import ImageHolder from 'features/core/ImageHolder'
import Slider from 'react-slick'
import { getData } from 'common/utils'
// import icPlay from '../styles/images/ic_play.svg'
import '../styles/detail-relate.scss'

const DetailRelate = (props) => {
  const { data, onRelateList } = props
  const slickRef = useRef(null)

  const location = useLocation()

  const [detailId, setDetailId] = useState(null)
  const [relateList, setRelateList] = useState([])
  const [slugPath, setSlugPath] = useState(null)

  const { data: dataRelate } = useQuery(['getRelate', detailId], async () => {
    if (detailId) {
      return await getData('GET', `related/${detailId}/`)
    }
  })

  useEffect(() => {
    if (location) {
      if (location.pathname) {
        const slugPathData = location.pathname.substring(0, location.pathname.lastIndexOf('/'))
        setSlugPath(slugPathData)
      }
    }
  }, [location])

  useEffect(() => {
    if (data) {
      const parentEpisode = data.parent_episode
      if (parentEpisode && parentEpisode.show_id) {
        setDetailId(parentEpisode.show_id)
      } else {
        setDetailId(data.id)
      }
    }
  }, [data])

  useEffect(() => {
    if (dataRelate) {
      // const dataTemp = formatDataChunk(dataRelate, 4)
      onRelateList(dataRelate.items)
      setRelateList(dataRelate.items)
    }
  }, [dataRelate])

  useEffect(() => {
    if (Array.isArray(relateList) && relateList.length > 0) {
      if (slickRef.current) {
        slickRef.current.slickGoTo(0)
      }
    }
  }, [relateList])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickRelate = (e, item) => {
    // history.push(`/detail/${item.slug}`)

    // history.push(`/detail/${item.slug}`)
    // history.push('/empty')
    // history.goBack()

    if (typeof slugPath === 'string' && slugPath.slice(0, 8) === '/trailer') {
      window.location.href = item.type === 10 ? `/truc-tiep/${item.slug}` : `/detail/${item.slug}`
    } else {
      window.location.href = item.type === 10 ? `/truc-tiep/${item.slug}` : `${slugPath}/${item.slug}`
    }
  }

  return (
    <>
      {(Array.isArray(relateList) && relateList.length > 0) &&
        <div className="detail-relate">
          <div className="row">
            <div className="col-12">
              <div className="relate-title">Đề xuất cho bạn</div>

              <Slider ref={slider => (slickRef.current = slider)} {...{
                dots: false,
                infinite: false,
                swipeToSlide: false,
                speed: 500,
                initialSlide: 0,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                  { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                  { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                  { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 3 } },
                  { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                  { breakpoint: 1350, settings: { slidesToShow: 4, slidesToScroll: 4 } }
                ]
              }}>
                {relateList && relateList.map((item, index) => {
                  return (
                    <div key={index} onClick={e => handleClickRelate(e, item)}>
                      <figure className="figure">
                        <div className="figure-overflow">
                          <ImageHolder data={item.images} />

                          {/* <div className="episode_current_custom">
                            <img src={icPlay} width={36} height={36} className="ic-play-episode" alt="" />
                          </div> */}
                        </div>
                        {/* <figcaption className="figure-caption">{item.title}</figcaption> */}
                      </figure>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>
      }
    </>
  )
}

DetailRelate.propTypes = {
  data: PropTypes.object,
  onRelateList: PropTypes.func
}

DetailRelate.defaultProps = {
  detailId: null
}

export default DetailRelate
