import React, { useEffect, useState, useRef } from 'react'
// import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import ImageHolder from 'features/core/ImageHolder'
import { getData, getDataDetail, formatDataChunkCustom, parseDate } from 'common/utils'
import Overlay from 'components/Overlay'
import Slider from 'react-slick'
// import icPlay from '../styles/images/ic_play.svg'
import '../styles/detail-season.scss'
// import { useGetSeasonDetail } from 'hooks/api'

const DetailSeason = (props) => {
  const {
    slug,
    slugtrailer
  } = useParams()

  const {
    vodType,
    data,
    onSeasonList,
    onEpisodeList,
    onChangeTitle,
    onChangeSrc
  } = props
  const slickRef = useRef(null)

  // const location = useLocation()
  // const history = useHistory()

  const [detailId, setDetailId] = useState(null)
  // const [dataList, setDataList] = useState([])
  const [seasonList, setSeasonList] = useState([])
  const [season, setSeason] = useState(null)
  const [seasonIndex, setSeasonIndex] = useState(0)
  const [seasonPartIndex, setSeasonPartIndex] = useState(0)
  const [episodeList, setEpisodeList] = useState([])
  const [episodeChunkList, setEpisodeChunkList] = useState([])
  const [episodeId, setEpisodeId] = useState(null)
  const [trailerList, setTrailerList] = useState([])

  const { data: dataGetListSeason } = useQuery(['getDataListSeason', detailId], async () => {
    if (detailId) {
      return await getDataDetail('GET', `get_detail/${detailId}/?platform=web&drm_ver=2`)
    }
  })

  // const { data: dataSeason, isLoading: isLoadingSeason } = useGetSeasonDetail(season?.id, { enabled: !!season })
  const { data: dataSeason, isLoading: isLoadingSeason } = useQuery(['getSeason', season], async () => {
    if (season) {
      // return await getData('GET', `get_season_by_id/${season.id}/?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
      return await getData('GET', `episodes_by_season_id/${season.id}/?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
    }
  })

  const { data: dataEpisode } = useQuery(['getEpisode', episodeId], async () => {
    if (episodeId) {
      return await getDataDetail('GET', `get_detail/${episodeId}/?platform=web&drm_ver=2`)
    }
  })

  useEffect(() => {
    if (data) {
      // Trailer list
      if (data.parent_episode && data.parent_episode.show_id) {
        const fetchTrailer = async () => {
          const resultTemp = await getData('GET', `get_detail/${data.parent_episode.show_id}/?platform=web&drm_ver=2`)
          if (resultTemp) {
            if (Array.isArray(resultTemp.trailers)) {
              setTrailerList(resultTemp.trailers)
            }
          }
        }

        fetchTrailer()
      } else {
        if (Array.isArray(data.trailers)) {
          setTrailerList(data.trailers)
        }
      }

      if (data.default_episode && data.default_episode.id) {
        setEpisodeId(data.default_episode.id)
      } else {
        setEpisodeId(data.id)
      }

      const parentEpisode = data.parent_episode
      if (parentEpisode && parentEpisode.show_id) {
        setDetailId(parentEpisode.show_id)
      } else {
        setDetailId(data.id)
      }
    }
  }, [data])

  useEffect(() => {
    if (dataGetListSeason) {
      onSeasonList(dataGetListSeason.seasons)
      setSeasonList(dataGetListSeason.seasons)
    }
  }, [dataGetListSeason])

  useEffect(() => {
    if (seasonList && seasonList.length > 0) {
      // const seasonListTemp = [...seasonList]

      // const loopData = async () => {
      //   for (let i = 0; i < seasonList.length; i++) {
      //     const seasonByIdResult = await getData('GET', `get_season_by_id/${seasonList[i].id}/?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`)
      //     if (seasonByIdResult) {
      //       const episodeListTemp = formatDataChunkCustom(seasonByIdResult.episodes, 12)

      //       seasonListTemp[i].episode_category_custom = 0

      //       if (Array.isArray(episodeListTemp)) {
      //         for (let j = 0; j < episodeListTemp.length; j++) {
      //           const tempArr = episodeListTemp[j]
      //           if (Array.isArray(tempArr)) {
      //             for (let k = 0; k < tempArr.length; k++) {
      //               if (tempArr[k].id === episodeId) {
      //                 seasonListTemp[i].episode_category_custom = j
      //                 seasonListTemp[i].episode_current_custom = k
      //               }
      //             }
      //           }
      //         }
      //       }

      //       seasonListTemp[i].episodes = episodeListTemp
      //     }
      //   }

      //   setDataList(seasonListTemp)
      // }

      // loopData()

      let checkSeasonItem = seasonList[0] // check season đang xem
      let checkSeasonIndex = 0

      const groupId = data.group
      for (let i = 0; i < seasonList.length; i++) {
        const seasonItem = seasonList[i]
        if (seasonItem) {
          if (groupId === seasonItem.id) {
            checkSeasonItem = seasonItem
            checkSeasonIndex = i
          }
        }
      }

      setSeason(checkSeasonItem)
      setSeasonIndex(checkSeasonIndex)
    }
  }, [seasonList])

  // useEffect(() => {
  //   if (Array.isArray(dataList)) {
  //     for (let i = 0; i < dataList.length; i++) {
  //       const episodeArrTemp = dataList[i].episodes
  //       if (Array.isArray(episodeArrTemp)) {
  //         const tempArr = episodeArrTemp[dataList[i].episode_category_custom]
  //         if (Array.isArray(tempArr)) {
  //           for (let j = 0; j < tempArr.length; j++) {
  //             if (tempArr[j].id === episodeId) {
  //               if (slickArrRef.current[i]) {
  //                 slickArrRef.current[i].slickGoTo(j)
  //               }

  //               // dataList[i].episode_current_custom = j
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }, [dataList])

  useEffect(() => {
    if (dataSeason) {
      // let i
      // let j
      // const tempArr = []
      if (dataSeason.episodes) {
        if (Array.isArray(dataSeason.episodes)) {
          // const reverseClone = [...dataSeason.episodes]
          // const dataEpisodeReverse = reverseClone.reverse()

          // for (i = 0, j = dataEpisodeReverse.length; i < j; i += 4) {
          //   const sliceArr = dataEpisodeReverse.slice(i, i + 4)
          //   tempArr.push(sliceArr)
          // }

          setEpisodeList(dataSeason.episodes)
          onEpisodeList(dataSeason.episodes)

          const episodeListTemp = formatDataChunkCustom(dataSeason.episodes, 12)
          setEpisodeChunkList(episodeListTemp)
        }
      }
    }
  }, [dataSeason])

  useEffect(() => {
    if (dataEpisode) {
      let linkPlay = ''
      if (dataEpisode.default_episode && dataEpisode.default_episode.id) {
        onChangeTitle(dataEpisode.display_name ? dataEpisode.display_name : dataEpisode.default_episode.title)
        linkPlay = dataEpisode.default_episode.play_info && dataEpisode.default_episode.play_info.data && dataEpisode.default_episode.play_info.data.hls_link_play
      } else {
        onChangeTitle(dataEpisode.display_name ? dataEpisode.display_name : dataEpisode.title)
        linkPlay = dataEpisode.play_info && dataEpisode.play_info.data && dataEpisode.play_info.data.hls_link_play
      }

      if (linkPlay && linkPlay !== '') {
        onChangeSrc(linkPlay)
      }
    }
  }, [dataEpisode])

  useEffect(() => {
    if (Array.isArray(episodeChunkList)) {
      for (let i = 0; i < episodeChunkList.length; i++) {
        const tempArr = episodeChunkList[i]
        if (Array.isArray(tempArr)) {
          for (let j = 0; j < tempArr.length; j++) {
            if (tempArr[j].id === episodeId) {
              setSeasonPartIndex(i)

              if (slickRef.current) {
                slickRef.current.slickGoTo((i * 12) + j)
              }
            }
          }
        }
      }
    }
  }, [episodeChunkList])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickEpisodePart = (e, index) => {
    setSeasonPartIndex(index)

    if (slickRef.current) {
      slickRef.current.slickGoTo(index * 12)
    }
  }

  const handleChangeSeason = (e) => {
    const index = e.target.value
    setSeason(seasonList[index])
    setSeasonIndex(index)
  }

  const handleLinkPlayUrl = (item) => {
    if (item.type === 6) {
      const slugTemp = item.slug

      if (slugTemp && slugTemp !== '') {
        window.location.href = `/trailer/${slug}/${item.slug}`
      } else {
        window.location.href = `/trailer/${slug}/${item.id}`
      }
    } else {
      const slugTemp = item.slug
      const urlTemp = location.pathname.slice(0, location.pathname.lastIndexOf('/'))

      if (typeof urlTemp === 'string' && urlTemp.slice(0, 8) === '/trailer') {
        if (slugTemp && slugTemp !== '') {
          window.location.href = item.type === 10 ? `/truc-tiep/${item.slug}` : `/detail/${item.slug}`
        } else {
          window.location.href = item.type === 10 ? `/truc-tiep/${item.slug}` : `/detail/${item.id}`
        }
      } else {
        if (slugTemp && slugTemp !== '') {
          window.location.href = `${urlTemp}/${item.slug}`
        } else {
          window.location.href = `${urlTemp}/${item.id}`
        }
      }
    }
  }

  const handleClickEpisode = (e, item) => {
    if (item) {
      if (item.status === 7) {
        if (item.has_trailer === false) {
          //
        } else {
          handleLinkPlayUrl(item)
        }
      } else {
        handleLinkPlayUrl(item)
      }
    }
  }

  return (
    <div className="detail-season">
      {/* {Array.isArray(dataList) && dataList.map((item, index) => {
        const episodeListArr = item.episodes

        return (
          <div className="row mb-4" key={index}>
            <div className="col-12">
              <div className="season-title">{item.title}</div>

              {Array.isArray(episodeListArr) && episodeListArr.map((itemChild, indexChild) => {
                if (indexChild === episodeListArr.length - 1) {
                  const childArrLength = Array.isArray(itemChild) ? itemChild.length : 0

                  return (
                    <span
                      key={indexChild}
                      className={`episode-part ${item.episode_category_custom === indexChild ? 'active' : ''}`}
                      onClick={e => handleClickEpisodePart(e, index, indexChild)}
                    >
                      {(indexChild * 12) + 1} - {(indexChild * 12) + childArrLength}
                    </span>
                  )
                } else {
                  return (
                    <span
                      key={indexChild}
                      className={`episode-part ${item.episode_category_custom === indexChild ? 'active' : ''}`}
                      onClick={e => handleClickEpisodePart(e, index, indexChild)}
                    >
                      {(indexChild * 12) + 1} - {(indexChild * 12) + 12}
                    </span>
                  )
                }
              })}

              {Array.isArray(episodeListArr) && episodeListArr.map((itemChild, indexChild) => {
                if (item.episode_category_custom === indexChild) {
                  return (
                    <div key={indexChild} className="mt-2">
                      <Slider ref={slider => (slickArrRef.current[index] = slider)} {...{
                        dots: false,
                        infinite: false,
                        swipeToSlide: false,
                        speed: 500,
                        initialSlide: 0,
                        slidesToShow: 4,
                        slidesToScroll: 4
                        // responsive: [
                        //   { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                        //   { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                        //   { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 3 } },
                        //   { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                        //   { breakpoint: 1350, settings: { slidesToShow: 4, slidesToScroll: 4 } }
                        // ]
                      }}>
                        {Array.isArray(itemChild) && itemChild.map((itemGrandChild, indexGrandChild) => {
                          return (
                            <div
                              key={indexGrandChild}
                              className="col-3"
                              onClick={e => handleClickEpisode(e, itemGrandChild)}
                            >
                              <figure className="figure">
                                <div className="figure-overflow">
                                  <ImageHolder data={itemGrandChild.images} />

                                  {item.episode_current_custom !== indexGrandChild &&
                                    <div className="episode_current_custom">
                                      <img src={icPlay} width={36} height={36} className="ic-play-episode" alt="" />
                                    </div>
                                  }
                                </div>
                                <figcaption className="figure-caption" role="button">{itemGrandChild.title}</figcaption>
                              </figure>
                            </div>
                          )
                        })}
                      </Slider>
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </div>
          </div>
        )
      })} */}

      {/* <div className="row">
        <div className="col-12 col-md-3">
          <select className="form-select" aria-label="Season" value={seasonIndex} onChange={handleChangeSeason}>
            {seasonList && seasonList.map((item, index) => {
              return (
                <option key={index} value={index}>{item.title}</option>
              )
            })}
          </select>
        </div>
      </div> */}

      <div className="row mb-4">
        <div className="col-12">
          {/* <div className="season-title">
            <div className="season-title-text">{seasonList[seasonIndex]?.title}</div>

            <select className="form-select" aria-label="Season" value={seasonIndex} onChange={handleChangeSeason}>
              {seasonList && seasonList.map((item, index) => {
                return (
                  <option key={index} value={index}>{item.title}</option>
                )
              })}
            </select>
          </div> */}

          {(Array.isArray(seasonList) && seasonList.length > 1)
            ? <select className="form-select" aria-label="Season" value={seasonIndex} onChange={handleChangeSeason}>
              {seasonList && seasonList.map((item, index) => {
                return (
                  <option key={index} value={index}>Mùa {index + 1}</option>
                )
              })}
            </select>
            : <>
                {/* <div className="season-title">{seasonList[seasonIndex]?.title}</div> */}
                {/* <div className="season-title">Danh sách tập</div> */}
              </>
          }

          {(Array.isArray(episodeChunkList) && episodeChunkList.length > 0) &&
            <div className="season-title">Danh sách tập</div>
          }

          {Array.isArray(episodeChunkList) && episodeChunkList.map((item, index) => {
            if (index === episodeChunkList.length - 1) {
              const childArrLength = Array.isArray(item) ? item.length : 0

              return (
                <span
                  key={index}
                  className={`episode-part ${seasonPartIndex === index ? 'active' : ''}`}
                  onClick={e => handleClickEpisodePart(e, index)}
                >
                  {(index * 12) + 1} - {(index * 12) + childArrLength}
                </span>
              )
            } else {
              return (
                <span
                  key={index}
                  className={`episode-part ${seasonPartIndex === index ? 'active' : ''}`}
                  onClick={e => handleClickEpisodePart(e, index)}
                >
                  {(index * 12) + 1} - {(index * 12) + 12}
                </span>
              )
            }
          })}

          <div className="pt-2 episode-list">
            {(isLoadingSeason)
              ? <Overlay />
              : <>
                <Slider ref={slider => (slickRef.current = slider)} {...{
                  dots: false,
                  infinite: false,
                  swipeToSlide: true,
                  speed: 500,
                  initialSlide: 0,
                  slidesToShow: 4,
                  slidesToScroll: 4,
                  responsive: [
                    { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                    { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                    { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 3 } },
                    { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                    { breakpoint: 1350, settings: { slidesToShow: 4, slidesToScroll: 4 } }
                  ],
                  beforeChange: (current, next) => {
                    if (Array.isArray(episodeList) && Array.isArray(episodeChunkList)) {
                      for (let i = 0; i < episodeChunkList.length; i++) {
                        const itemArrTemp = episodeChunkList[i]

                        if (Array.isArray(itemArrTemp)) {
                          for (let j = 0; j < itemArrTemp.length; j++) {
                            if (itemArrTemp[j] && episodeList[next]) {
                              if (itemArrTemp[j].id === episodeList[next].id) {
                                let partIndexCheck = i

                                // Kiểm tra episode
                                if (next >= episodeList.length - 4) {
                                  if (episodeChunkList[i + 1]) {
                                    partIndexCheck = i + 1
                                  }
                                }
                                setSeasonPartIndex(partIndexCheck)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }}>
                  {episodeList && episodeList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        // className="col-6 col-md-3"
                        onClick={e => handleClickEpisode(e, item)}
                      >
                        <figure className="figure">
                          <div className={`figure-overflow ${item.status === 7 ? 'imgTrailer' : ''}`}>
                            <ImageHolder data={item.images} />

                            {/* {vodType === 'trailer'
                              ? <div className="episode_current_custom">
                                  <img src={icPlay} width={36} height={36} className="ic-play-episode" alt="" />
                                </div>
                              : <>
                                  {item.id !== episodeId &&
                                    <div className="episode_current_custom">
                                      <img src={icPlay} width={36} height={36} className="ic-play-episode" alt="" />
                                    </div>
                                  }
                                </>
                            } */}
                            {vodType === 'trailer'
                              ? <></>
                              : <>
                                  {(item.status === 7 && item.has_trailer)
                                    ? <div className="badgeTrailerContainer">
                                        <div className="badge badgeTrailer">Giới thiệu</div>
                                      </div>
                                    : <>
                                        {(item.status !== 7 && item.new_release)
                                          ? <div className="badgeNewReleaseContainer">
                                              <div className="badge badgeNewRelease">Tập mới</div>
                                            </div>
                                          : <></>
                                        }
                                      </>
                                  }

                                  {item.id === episodeId &&
                                    <div className="badge badgePlaying">Đang phát</div>
                                  }
                                </>
                            }
                          </div>
                          <figcaption className={`figure-caption ${item.status === 7 ? 'titleTrailer' : ''}`} role="button">
                            {item.status === 7
                              ? item.display_name + ' | Ra mắt ' + parseDate(item.release_date)
                              : (item.display_name ? item.display_name : item.title)
                            }
                          </figcaption>
                        </figure>
                      </div>
                    )
                  })}
                </Slider>
              </>}
          </div>
        </div>
      </div>

      {(Array.isArray(trailerList) && trailerList.length > 0) &&
        <div className="row">
          <div className="col-12">
            <div className="season-title">Trailer & Hot Clips</div>

            <Slider {...{
              dots: false,
              infinite: false,
              swipeToSlide: false,
              speed: 500,
              initialSlide: 0,
              slidesToShow: 4,
              slidesToScroll: 4,
              responsive: [
                { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 3 } },
                { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                { breakpoint: 1350, settings: { slidesToShow: 4, slidesToScroll: 4 } }
              ]
            }}>
              {Array.isArray(trailerList) && trailerList.map((itemGrandChild, indexGrandChild) => {
                return (
                  <div key={indexGrandChild} className="col-3" onClick={e => handleClickEpisode(e, itemGrandChild)}>
                    <figure className="figure">
                      <div className="figure-overflow">
                        <ImageHolder data={itemGrandChild.images} />

                        {vodType === 'trailer'
                          ? <>
                              {(slugtrailer === itemGrandChild.id || slugtrailer === itemGrandChild.slug) &&
                                <div className="badge badgePlaying">Đang phát</div>
                              }
                            </>
                          : <></>
                        }
                      </div>
                      {/* <figcaption className="figure-caption" role="button">{itemGrandChild.title}</figcaption> */}
                    </figure>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      }
    </div>
  )
}

DetailSeason.propTypes = {
  vodType: PropTypes.string,
  data: PropTypes.object,
  onSeasonList: PropTypes.func,
  onEpisodeList: PropTypes.func,
  onChangeTitle: PropTypes.func,
  onChangeSrc: PropTypes.func
}

DetailSeason.defaultProps = {
  vodType: '',
  data: null
}

export default DetailSeason
