import React from 'react'
import Layout from 'features/layout/components/Layout'
import Login from '../components/Login'

const LoginPage = (props) => {
  return (
    <Layout>
      <Login />
    </Layout>
  )
}

export default LoginPage
