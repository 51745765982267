import React, { useEffect, useState, useRef } from 'react'
import { useInfiniteQuery } from 'react-query'
import { getData } from 'common/utils'
import { useMediaQuery } from 'common/hook-custom'
import Layout from 'features/layout/components/Layout'
import Search from '../components/Search'
import SearchNew from '../components/SearchNew'
import '../styles/search-new-page.scss'
import SearchMobile from '../components/SearchMobile'

const SearchNewPage = (props) => {
  const loadMoreRef = useRef()
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  const [searchText, setSearchText] = useState('')
  const [data, setData] = useState([])
  const [typeFilter, setTypeFilter] = useState('all')

  const {
    data: dataSearch,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery(['getSearch', searchText, typeFilter], async ({ pageParam = 0 }) => {
    if (searchText !== '') {
      return await getData('GET', `search_group/?keyword=${searchText}&type=${typeFilter}&page=${pageParam}&limit=20`)
    } else {
      return []
    }
  }, {
    getNextPageParam: lastPage => {
      if (Array.isArray(lastPage)) {
        const arrFind = lastPage.filter(obj => {
          return obj.type === typeFilter
        })
        if (Array.isArray(arrFind) && arrFind.length > 0) {
          const objFind = arrFind[0]
          if (objFind) {
            if (objFind.metadata) {
              return ((objFind.metadata?.page / 20) + 1) ?? false
              // return ((objFind.metadata?.page / 20) + 1) ?? false
            } else {
              return false
            }
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    }
  })

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Tìm kiếm',
        page_location: 'https://thvli.vn/search',
        page_path: '/search',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    document.title = 'Tìm kiếm'
  }, [])

  useEffect(() => {
    if (dataSearch) {
      if (Array.isArray(dataSearch.pages)) {
        // const pageArr = dataSearch.pages
        // if (Array.isArray(pageArr)) {
        //   const firstPageArr = pageArr[0]
        //   if (Array.isArray(firstPageArr)) {
        //     if (typeFilter === 'all') {
        //       setData([])
        //     } else {
        //       for (let i = 0; i < firstPageArr.length; i++) {
        //         if (firstPageArr[i]?.type === typeFilter) {
        //           const itemArr = firstPageArr[i]?.items
        //           if (Array.isArray(itemArr)) {
        //             const dataTemp = formatDataChunkCustom(itemArr, 4)
        //             setData(dataTemp)
        //           }
        //         }
        //       }
        //     }
        //   }
        // }

        setData(dataSearch.pages)
      }
    }
  }, [dataSearch])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleChange = (e) => {
    setSearchText(e.target && e.target.value)
  }

  const handleChangeType = (e, typeParam) => {
    setTypeFilter(typeParam)
  }

  return (
    <Layout>
      <div className="search-new-page pt-2">
        <div className='row'>
          <div className='col-12 mt-2 mt-lg-0 mx-2 mx-lg-5 text-search'>TÌM KIẾM NỘI DUNG</div>
        </div>
        <div className="row px-2 px-md-5">
          <div className="col-12 col-lg-4 input-search-container">
            <input className="form-control input-search" onChange={handleChange} placeholder="Tìm kiếm phim, show, diễn viên..." />
          </div>

          <div className='col-12 col-lg-8'>
            <div className='search-new-category'>
              <div className='search-new-category-item-vertical'></div>
              <div className={`search-new-category-item ${typeFilter === 'all' ? 'active' : ''}`} onClick={e => handleChangeType(e, 'all')}>TẤT CẢ</div>
              <div className={`search-new-category-item ${typeFilter === 'film' ? 'active' : ''}`} onClick={e => handleChangeType(e, 'film')}>PHIM</div>
              <div className={`search-new-category-item ${typeFilter === 'show' ? 'active' : ''}`} onClick={e => handleChangeType(e, 'show')}>SHOW</div>
              {/* <div className={`search-new-category-item ${typeFilter === 'season' ? 'active' : ''}`} onClick={e => handleChangeType(e, 'season')}>MÙA</div> */}
              <div className={`search-new-category-item ${typeFilter === 'episode' ? 'active' : ''}`} onClick={e => handleChangeType(e, 'episode')}>TẬP</div>
              <div className={`search-new-category-item ${typeFilter === 'artist' ? 'active' : ''}`} onClick={e => handleChangeType(e, 'artist')}>NGHỆ SĨ</div>
            </div>
          </div>
        </div>
        {/* <div className='row px-5 pt-4'>
          <div className='col-12'>
            <div className='search-new-badge'>
              <div className='badge badge-item'>doi lua</div>
              <div className='badge badge-item'>hua minh dat</div>
              <div className='badge badge-item'>tinh yeu bat tu</div>
            </div>
          </div>
        </div> */}

        <div className="py-4 py-lg-5 px-lg-5 result-container">
          {isDesktop
            ? <>
                {typeFilter === 'all'
                  ? <SearchNew data={data} />
                  : <Search
                      data={data}
                      typeFilter={typeFilter}
                      loadMoreRef={loadMoreRef}
                      fetchNextPage={fetchNextPage}
                      hasNextPage={hasNextPage}
                      isLoading={isLoading}
                      isFetchingNextPage={isFetchingNextPage}
                    />
                }
              </>
            : <>
                {typeFilter === 'all'
                  ? <SearchNew data={data} />
                  : <SearchMobile
                      data={data}
                      typeFilter={typeFilter}
                      loadMoreRef={loadMoreRef}
                      fetchNextPage={fetchNextPage}
                      hasNextPage={hasNextPage}
                      isLoading={isLoading}
                      isFetchingNextPage={isFetchingNextPage}
                    />
                }
              </>
          }
        </div>
      </div>
    </Layout>
  )
}

export default SearchNewPage
