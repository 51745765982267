import React, { useEffect } from 'react'
import Layout from 'features/layout/components/Layout'
import Tv from '../components/Tv'

const TvPage = (props) => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Đăng nhập trên TV',
        page_location: 'https://thvli.vn/tv',
        page_path: '/tv',
        send_to: 'G-RW9WHCKH4J'
      })
    }
  }, [])

  return (
    <Layout>
      <Tv />
    </Layout>
  )
}

export default TvPage
