import { createSlice } from '@reduxjs/toolkit'

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    menu: null
  },
  reducers: {
    setMenu (state, action) {
      state.menu = action.payload
    }
  }
})

export const {
  setMenu
} = menuSlice.actions
export default menuSlice.reducer
