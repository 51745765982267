import React from 'react'
import ReactDOM from 'react-dom'
import {
  QueryClient,
  QueryClientProvider
} from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
// import ReactGA from 'react-ga'
import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
import ScrollToTop from 'components/ScrollToTop'
import routes from './routes'
import store from 'common/store'
// import Spinner from 'components/Spinner'
import './index.scss'
// import reportWebVitals from './reportWebVitals'

Sentry.init({
  // dsn: 'https://dcc458960c6745429fb637dabd1a55bd@sentry.admon.com.vn/3',
  dsn: 'https://fb7a75bafff148279840b6941797970f@sentry.admon.com.vn/15',
  // integrations: [new Integrations.BrowserTracing()],
  defaultIntegrations: false
  // tracesSampleRate: 0.1
})

// const TRACKING_ID = 'UA-97094610-3'
// const TRACKING_ID = 'G-RW9WHCKH4J'

// ReactGA.initialize(TRACKING_ID)

// const usePageViews = () => {
//   // eslint-disable-next-line prefer-const
//   let location = useLocation()
//   React.useEffect(() => {
//     ReactGA.set({ page: location.pathname })
//     ReactGA.pageview(location.pathname)
//   }, [location])
// }

const AppContent = () => {
  // usePageViews()

  return (
    <Switch>
      {routes.map((route, index) => {
        return (
          <Route key={index} path={route.path} component={route.component} exact={route.exact} />
        )
      })}
    </Switch>
  )
}

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  })

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ScrollToTop />
          <AppContent />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
