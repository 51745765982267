import { createSlice } from '@reduxjs/toolkit'

const rootSlice = createSlice({
  name: 'root',
  initialState: {},
  reducers: {}
})

// export const { toggleModal } = rootSlice.actions
export default rootSlice.reducer
