import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldAlt, faInfoCircle, faHistory, faUserSlash } from '@fortawesome/free-solid-svg-icons'
import '../styles/profile-mobile.scss'

const ProfileMobile = (props) => {
  const { email, fullname, isActive, loginMethod, handleClickResendActiveEmail } = props

  return (
    <div className="profile-mobile">
      <div className="row">
        <div className="col-10 offset-1">
          <div className="title">Tài khoản</div>
        </div>
      </div>

      <div className="row">
        <div className="col-10 offset-1">
          <hr className="divider" />
        </div>
      </div>

      <div className="row">
        <div className="col-10 offset-1">
          <div className="profile-label">Họ và Tên:</div>
          <div className="profile-value">{fullname}</div>
          {loginMethod === 'email' &&
            <Link className="profile-change" to="/change-info">Thay đổi Họ và Tên</Link>
          }
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-10 offset-1">
          <div className="profile-label">Mật khẩu:</div>
          <div className="profile-value">*********</div>
          {loginMethod === 'email' &&
            <Link className="profile-change" to="/change-password">Thay đổi mật khẩu</Link>
          }
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-10 offset-1">
          <div className="profile-label">Email:</div>
          <div className="profile-value">{email}</div>

          {(isActive === 0 && loginMethod === 'email') &&
            <div className="profile-change-active-email" onClick={handleClickResendActiveEmail}>Chưa xác thực, gửi lại mail xác thực</div>
          }
        </div>
      </div>

      <div className="row pt-5">
        <div className="col-10 offset-1">
          <div className="profile-icon">
            <FontAwesomeIcon className="font-icon" icon={faHistory} />
          </div>
          <Link className="profile-link" to="/watching">Đang xem</Link>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-10 offset-1">
          <div className="profile-icon">
            <FontAwesomeIcon className="font-icon" icon={faShieldAlt} />
          </div>
          <Link className="profile-link" to="/privacy-policy">Điều khoản sử dụng</Link>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-10 offset-1">
          <div className="profile-icon">
            <FontAwesomeIcon className="font-icon" icon={faInfoCircle} />
          </div>
          <Link className="profile-link" to="/faq">Về ứng dụng</Link>
        </div>
      </div>
      <div className="row pt-3 pb-5">
        <div className="col-10 offset-1">
          <div className="profile-icon">
            <FontAwesomeIcon className="font-icon" icon={faUserSlash} />
          </div>
          <div className="profile-link">Xóa tài khoản</div>
        </div>
      </div>
    </div>
  )
}

ProfileMobile.propTypes = {
  email: PropTypes.string,
  fullname: PropTypes.string,
  isActive: PropTypes.number,
  loginMethod: PropTypes.string,
  handleClickResendActiveEmail: PropTypes.func
}

ProfileMobile.defaultProps = {
  email: '',
  fullname: '',
  isActive: 0,
  loginMethod: ''
}

export default ProfileMobile
