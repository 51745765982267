import React from 'react'
import Layout from 'features/layout/components/Layout'
import ActiveEmail from '../components/ActiveEmail'

const ActiveEmailPage = (props) => {
  return (
    <Layout>
      <ActiveEmail />
    </Layout>
  )
}

export default ActiveEmailPage
