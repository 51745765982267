import React from 'react'
import PropTypes from 'prop-types'
import AdsItem from 'components/AdsItem'
import '../styles/detail-ads.scss'

const DetailAds = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { data, position } = props

  // const [adsCategory, setAdsCategory] = useState('')
  // const [adsHashtag, setAdsHashtag] = useState('')
  // const [adsGenre, setAdsGenre] = useState('')
  // const [adsCountry, setAdsCountry] = useState('')
  // const [adsPartner, setAdsPartner] = useState('')
  // const [adsYear, setAdsYear] = useState('')

  // useEffect(() => {
  //   if (data && data.metadata) {
  //     const category = data.metadata.category
  //     if (category) {
  //       setAdsCategory(category.length > 0 ? category[0].value : '')
  //     }

  //     const hashtag = data.metadata.hashtag
  //     if (hashtag) {
  //       setAdsHashtag(hashtag.length > 0 ? hashtag[0].value : '')
  //     }

  //     const genre = data.metadata.genre
  //     if (genre) {
  //       setAdsGenre(genre.length > 0 ? genre[0].value : '')
  //     }

  //     const country = data.metadata.country
  //     if (country) {
  //       setAdsCountry(country.length > 0 ? country[0].value : '')
  //     }

  //     const partner = data.metadata.partner
  //     if (partner) {
  //       setAdsPartner(partner.length > 0 ? partner[0].value : '')
  //     }

  //     const year = data.metadata.year
  //     if (year) {
  //       setAdsYear(year.length > 0 ? year[0].value : '')
  //     }
  //   }
  // }, [data])

  return (
    <div className="detail-ads">
      {position === 'left'
        ? <AdsItem
              type="player"
              adsPos="POS004"
              adsClassName=""
            />
        : <AdsItem
              type="player"
              adsPos="POS005"
              adsClassName=""
            />
        }
    </div>
  )
}

DetailAds.propTypes = {
  data: PropTypes.object,
  position: PropTypes.string
}

DetailAds.defaultProps = {
  data: null,
  position: 'left'
}

export default DetailAds
