/* eslint-disable node/no-callback-literal */
import React, { useEffect } from 'react'
import request from 'superagent'
import Layout from 'features/layout/components/Layout'
import '../styles/support'
import '../styles/support.scss'

const SupportPage = (props) => {
  const domain = 'https://api.thvli.vn/backend'

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Phản Hồi - THVLi',
        page_location: 'https://thvli.vn/support',
        page_path: '/support',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    document.title = 'Phản Hồi - THVLi'
  }, [])

  const sendSupportData = (data, callback) => {
    request.post(domain + '/cas/public/support')
      .set('Content-Type', 'application/json')
      .send(data)
      // .withCredentials()
      .accept('application/json')
      .end(function (err, res) {
        if (err) {
          if (res) {
            return callback(true, res.body)
          } else {
            return callback(true, res)
          }
        }

        if (res) {
          callback(false, res.body)
        } else {
          callback(false, res)
        }
      })
  }

  const handleClickSend = () => {
    const _name = document.getElementById('support_name').value || ''
    const _contact = document.getElementById('support_contact').value || ''
    const _content = document.getElementById('support_content').value || ''
    if (!_name || !_contact || !_content) {
      return alert('Xin nhập đủ thông tin.')
    }
    sendSupportData({
      name: _name,
      contact: _contact,
      content: _content
    }, (err, data) => {
      if (err) {
        if (data) {
          return alert(data.detail || data.message)
        } else {
          return alert('Gửi thông tin thất bại.')
        }
      }
      alert('Chúng tôi sẽ sớm phản hồi lại quý khán giả. Xin cảm ơn.')
    })
  }

  return (
    <Layout>
      <div className="support">
        <div className="row pt-4 pt-md-4">
          <div className="col-12 col-lg-4 offset-lg-4">
            <div className="text-support text-center">Xin vui lòng nhập thông tin để được hỗ trợ.</div>
          </div>
        </div>
        <div className="row pt-3 pt-md-3">
          <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
            <input type="text" className="form-control" id="support_name" placeholder="Tên của quý khán giả" required />
            <div className="invalid-feedback">
              Looks good!
            </div>
          </div>
        </div>
        <div className="row pt-2 pt-md-2">
          <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
            <input type="text" className="form-control" id="support_contact" placeholder="Email hoặc số điện thoại" required />
            <div className="valid-feedback">
              Looks good!
            </div>
          </div>
        </div>
        <div className="row pt-2 pt-md-2">
          <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
            <textarea className="form-control" id="support_content" rows={9} placeholder="Nhập nội dung cần hỗ trợ" required></textarea>
            <div className="valid-feedback">
              Looks good!
            </div>
          </div>
        </div>
        <div className="row pt-3 pt-md-3 pb-5 pb-md-5">
          <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
            <button className="btn btn-primary w-100" onClick={handleClickSend}>Gửi</button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SupportPage
