/* eslint-disable react/prop-types */
import React from 'react'
import { getAgeContentRating } from 'common/utils'
import './styles/age-rating-item.scss'

const AgeRatingItem = ({ item, type }) => {
  const renderAgeRatingIcon = () => {
    if (item?.content_ratings?.length > 0) {
      const ageRating = getAgeContentRating(
        item?.content_ratings
      )

      if (ageRating && ageRating.image_url) {
        return (
          <div className={`${type === 'top10' ? 'ribbon-item-top10-age-rating-wrapper' : 'ribbon-item-age-rating-wrapper'}`}>
            <img
              className='ribbon-item-age-rating-icon'
              src={ageRating?.image_url}
              // src='https://s3-hcm.cloud.cmctelecom.vn/sctv-main-2/tenants/none_tenant/photos/t13_f5201dc0.png'
            />
          </div>
        )
      }
    }

    return null
  }

  return (
    <>
      {renderAgeRatingIcon()}
    </>
  )
}

export default AgeRatingItem
