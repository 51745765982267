import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import { useMediaQuery } from 'common/hook-custom'
import { Link } from 'react-router-dom'
import { initializeApp } from 'firebase/app'
import { getRemoteConfig, getValue, fetchAndActivate } from 'firebase/remote-config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/js/dist/modal'
import './styles/inapp-popup.scss'

const InAppPopup = () => {
  // Initialize Firebase
  const firebaseConfig = {
    apiKey: 'AIzaSyCkxCx869eG8aPFoZOc7K2U1bIfBnRr2os',
    authDomain: 'thvli-48223.firebaseapp.com',
    databaseURL: 'https://thvli-48223.firebaseio.com',
    projectId: 'thvli-48223',
    storageBucket: 'thvli-48223.appspot.com',
    messagingSenderId: '513556246464',
    appId: '1:513556246464:web:8f3b68253b4f3625a76225',
    measurementId: 'G-NW8BGJLPSG'
  }
  const app = initializeApp(firebaseConfig)

  // Initialize Remote Config and get a reference to the service
  const remoteConfig = getRemoteConfig(app)
  remoteConfig.settings.minimumFetchIntervalMillis = 5 * 60 * 1000

  // const isDesktop = useMediaQuery('(min-width: 1200px)')

  const [show, setShow] = useState(false)
  const [modalBanner, setModalBanner] = useState(null)
  const [modalSlug, setModalSlug] = useState(null)
  const [modalCountdown, setModalCountdown] = useState(null)

  useEffect(() => {
    const inAppTime = localStorage.getItem('adm_inapp_time')
    if (inAppTime) {
      if ((Date.now() - inAppTime) > (24 * 60 * 60 * 1000)) {
        fetchAndActivate(remoteConfig)
          .then(() => {
            const val = getValue(remoteConfig, 'in_app_message')
            if (val) {
              const valString = val._value
              if (typeof valString === 'string') {
                const objVal = JSON.parse(val._value)
                if (objVal) {
                  if (objVal.isShow === true) {
                    localStorage.setItem('adm_inapp_time', Date.now())

                    setModalBanner(objVal.banner)
                    setModalSlug(objVal.slug)
                    setModalCountdown(10)

                    setShow(true)
                  }
                }
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    } else {
      localStorage.setItem('adm_inapp_time', Date.now())
    }
  }, [])

  useEffect(() => {
    if (modalCountdown !== null) {
      const timer = modalCountdown > 0 && setInterval(() => setModalCountdown(modalCountdown - 1), 1000)

      if (modalCountdown === 0) {
        setShow(false)
        const btnInAppClose = document.getElementById('btnInAppClose')
        if (btnInAppClose) {
          btnInAppClose.click()
        }
      }

      return () => clearInterval(timer)
    }
  }, [modalCountdown])

  useEffect(() => {
    if (show === true) {
      const modalInAppBackdrop = document.getElementById('modalInAppBackdrop')
      if (modalInAppBackdrop) {
        if (modalInAppBackdrop.classList.contains('show')) {
          //
        } else {
          const modalOpen = document.getElementById('modalInAppOpen')
          if (modalOpen) {
            modalOpen.click()
          }
        }
      }
    }
  }, [show])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleCloseModal = () => {
    setShow(false)
  }

  return (
    <div className='inapp-popup'>
      <div className="modal fade" id="modalInAppBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {modalBanner &&
              <img
                className="img-fluid rounded-block w-100"
                src={modalBanner}
              />
            }

            <div className="btn-inapp-playnow-container">
              <Link to={`/detail/${modalSlug}`}>
                <button className="btn btn-primary btn-inapp-playnow" data-bs-dismiss="modal">
                  Xem ngay
                </button>
              </Link>

              <div className="btn-inapp-countdown">{modalCountdown}</div>
            </div>

            <button className="btn btn-dark btn-inapp-close-custom" id="btnInAppClose" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>

      <button
        id="modalInAppOpen"
        style={{ display: 'none' }}
        data-bs-toggle="modal"
        data-bs-target="#modalInAppBackdrop"
      >
        Open
      </button>
    </div>
  )
}

InAppPopup.propTypes = {
  modalContentId: PropTypes.string,
  modalContentSlug: PropTypes.any,
  modalContentEpisode: PropTypes.any,
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onClickPlay: PropTypes.func
}

InAppPopup.defaultProps = {
  modalContentId: '',
  isShow: false
}

export default InAppPopup
