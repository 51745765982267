/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import { useQuery } from 'react-query'
import { formatDate, formatTime, scrollVertical, scrollVerticalCenter, getData, getDataDetail, getDataServerTime } from 'common/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { imgholder } from 'constant/images'
import { Calendar as CalendarIcon, VideoSlash } from 'iconsax-react'
import Overlay from 'components/Overlay'
// import { trackingPlayer } from 'common/tracking'
// import XMLWriter from 'xml-writer'
import 'bootstrap/js/dist/modal'
import Calendar from 'react-calendar'
import moment from 'moment'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-calendar/dist/Calendar.css'
import '../styles/live.scss'

const LiveGeoBlock = (props) => {
  const history = useHistory()
  const { slug } = useParams()

  const dateConvert = new Date()
  const utcDate = Date.UTC(dateConvert.getUTCFullYear(), dateConvert.getUTCMonth(), dateConvert.getUTCDate(),
    dateConvert.getUTCHours(), dateConvert.getUTCMinutes(), dateConvert.getUTCSeconds())
  const dateGmt = new Date(utcDate)
  const currentTime = dateGmt.getTime()

  const [channelId, setChannelId] = useState(null)
  const [scheduleList, setScheduleList] = useState([])
  const [schedule, setSchedule] = useState(null)
  const [epgList, setEpgList] = useState([])
  const [isShowCalendar, setIsShowCalendar] = useState(false)
  const [currentSchedule, setCurrentSchedule] = useState(0)
  const [currentEpg, setCurrentEpg] = useState(0)
  const [scheduleDate, setScheduleDate] = useState(new Date())
  // eslint-disable-next-line no-unused-vars
  const [channelSlider, setChannelSlider] = useState(null)

  const { data, isError, error, refetch } = useQuery(['getDetailLive', slug], async () => {
    // return await getData('GET', `detail/${slug}`)
    return await getDataDetail('GET', `get_detail/${slug}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
  }, {
    retry: false,
    cacheTime: 0,
    staleTime: 0
  })

  const { data: dataAllChannel } = useQuery('getAllChannel', async () => {
    return await getData('GET', 'ribbon/a6ccbdff-5688-4b25-9989-5ce872603b0a/?platform=web')
  })

  const { data: dataEpg, isLoading: isLoadingEpg, isFetching: isFetchingEpg } = useQuery(['getAllChannel', channelId, schedule], async () => {
    if (channelId && schedule) {
      return await getData('GET', `epg/?channel_id=${channelId}&schedule_date=${schedule.value}&platform=web`)
    }
  })

  const heightScreen = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

  useEffect(() => {
    if (isError && error) {
      if (error?.request?.status === 403) {
        const fetchDataServerTime = async () => {
          const resultServerTime = await getDataServerTime()
          if (resultServerTime) {
            const currentTimestamp = Date.now()
            const timeDiff = currentTimestamp - resultServerTime
            localStorage.setItem('TIME_NOW', currentTimestamp)
            localStorage.setItem('TIME_SERVER', resultServerTime)
            localStorage.setItem('TIME_DIFF', timeDiff)
          }

          setTimeout(() => {
            refetch()
          }, 200)
        }

        fetchDataServerTime()
      }
    }
  }, [isError, error])

  useEffect(() => {
    const currentDate = new Date()
    const dateListTemp = []

    for (let i = 6; i > -2; i--) {
      const day = currentDate - (86400000 * i)
      dateListTemp.push({
        value: formatDate(day, 'yyyy-mm-dd'),
        label: formatDate(day, 'dd/mm/yyyy')
      })
    }

    setScheduleList(dateListTemp)
  }, [])

  useEffect(async () => {
    if (data) {
      // eslint-disable-next-line no-undef
      if (gtag) {
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
          page_title: data.title,
          page_location: `https://thvli.vn/live/${slug}`,
          page_path: `/live/${slug}`,
          send_to: 'G-RW9WHCKH4J'
        })
      }

      document.title = data.title
      setChannelId(data.id)
    }
  }, [data])

  useEffect(() => {
    if (scheduleList) {
      setCurrentSchedule(6)
      setSchedule(scheduleList[6])
    }
  }, [scheduleList])

  useEffect(() => {
    if (dataEpg) {
      setEpgList(dataEpg.items)

      if (dataEpg.items) {
        if (currentSchedule === 6) {
          for (let i = 0; i < dataEpg.items.length; i++) {
            if (currentTime > (dataEpg.items[i].start_at * 1000) && currentTime < (dataEpg.items[i].end_at * 1000)) {
              setCurrentEpg(i)
              setTimeout(() => {
                scrollVertical('container-epg', 'container-epg', 'UP')
                scrollVerticalCenter('container-epg', `epg-${i}`, 'DOWN')
              }, 800)
            }
          }
        } else {
          setTimeout(() => {
            setCurrentEpg(-1)
            scrollVertical('container-epg', 'container-epg', 'UP')
          }, 800)
        }
      }
    }
  }, [dataEpg])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickShowCalendar = () => {
    setIsShowCalendar(true)
  }

  const handleClickHideCalendar = () => {
    setIsShowCalendar(false)
  }

  const handleClickDate = (date) => {
    if (date) {
      const selectedDate = moment(date).format('YYYY-MM-DD')
      const indexFind = scheduleList.findIndex((item) => {
        return item.value === selectedDate
      })
      setCurrentSchedule(indexFind)
      setIsShowCalendar(false)
      setSchedule(scheduleList[indexFind])
      setScheduleDate(date)
    }
  }

  const handleClickEpg = (e, index) => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      if (Array.isArray(epgList)) {
        const epgItemTemp = epgList[index]

        if (epgItemTemp) {
          // eslint-disable-next-line no-undef
          gtag('event', 'page_view', {
            page_title: 'Catchup - ' + epgItemTemp?.title,
            page_location: `https://thvli.vn/live/${slug}`,
            page_path: `/live/${slug}`,
            send_to: 'G-RW9WHCKH4J',
            catchup_name: epgItemTemp?.title,
            start_at: epgItemTemp?.start_at,
            end_at: epgItemTemp?.end_at,
            currentTime: new Date().getTime()
          })
        }
      }
    }

    const epgCurrentData = epgList[index]

    if (epgCurrentData) {
      if (currentSchedule === 6) {
        if (currentTime < (epgCurrentData.start_at * 1000)) {
          // do nothing
        } else {
          setCurrentEpg(index)
        }
      } else if (currentSchedule > 6) {
        // do nothing
      } else {
        setCurrentEpg(index)
      }
    }
  }

  const handleClickChannel = (e, item) => {
    // setIsLoading(true)
    setCurrentSchedule(6)
    setScheduleDate(new Date())
    setSchedule(scheduleList[6])
    window.scrollTo(0, 0)

    if (`/live/${item.slug}` === location.pathname) {
      if (item.slug === 'phat-thanh') {
        window.open('https://audio.thvl.vn/radio/phat-thanh', '_blank')
      } else {
        if (dataEpg && dataEpg.items) {
          for (let i = 0; i < dataEpg.items.length; i++) {
            if (currentTime > (dataEpg.items[i].start_at * 1000) && currentTime < (dataEpg.items[i].end_at * 1000)) {
              setCurrentEpg(i)
              setTimeout(() => {
                scrollVertical('container-epg', 'container-epg', 'UP')
                scrollVertical('container-epg', `epg-${i}`, 'DOWN')
              }, 800)
            }
          }
        }
      }
    } else {
      if (item.slug === 'phat-thanh') {
        window.open('https://audio.thvl.vn/radio/phat-thanh', '_blank')
      } else {
        history.push(`/live/${item.slug}`)
        history.push('/empty')
        history.goBack()
      }
    }
  }

  return (
    <div className="live">
      <div className="row player-container">
        <div className="col-12 col-md-12 col-lg-8" style={{ position: 'relative', minHeight: '200px' }}>
          <div className='live-geo-block'>
            <div>
              <VideoSlash color="white" size={32} className='mb-2' />
            </div>
            <div>Sorry, this video is not available in your country</div>
          </div>
        </div>

        {/* Channel List */}
        <div className="col-12 pt-4 col-md-12 col-lg-8 offset-lg-2 channel-container-list-mobile">
          <Slider ref={slider => setChannelSlider(slider)} {...{
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            initialSlide: 0,
            // prevArrow: <PrevButton />,
            // nextArrow: <NextButton />,
            responsive: [
              { breakpoint: 480, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 687, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 768, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 1024, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 1350, settings: { slidesToShow: 5, slidesToScroll: 1 } }
            ]
          }}>
            {dataAllChannel && dataAllChannel.items && dataAllChannel.items.map((item, index) => {
              const indexFind = dataAllChannel.items.findIndex((itemFind) => {
                return itemFind.slug === slug
              })

              return (
                <figure key={index} className="figure px-2 px-md-2" onClick={e => handleClickChannel(e, item)}>
                  {indexFind === index && <div className="activeDot"></div>}
                  <img
                    className="figure-img img-fluid rounded-block w-100 channel-live"
                    style={{ cursor: 'pointer' }}
                    src={item.images
                      ? ((item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : imgholder)
                      : imgholder
                    }
                    onError={(e) => {
                      e.target.onerror = null
                      e.target.src = imgholder
                    }}
                  />
                </figure>
              )
            })}
          </Slider>
        </div>

        <div className="col-12 col-md-12 col-lg-4">
          <div className="row catchup-title pt-1 pt-md-0 pb-3">
            <div className="col-6 col-md-6">
              <div className="live-epg-calendar-live">Lịch phát sóng</div>
              {/* Lịch phát sóng {isPaused ? ' Pause' : ' Play'} */}
            </div>
            <div className="col-6 col-md-6">
              <div className="float-end calendar-epg" onClick={handleClickShowCalendar}>
                <CalendarIcon color="white" variant='Outline' size={22} className='me-2 mb-1' role='button' />
                {/* <FontAwesomeIcon className="me-2" icon={faCalendar} role="button" /> */}
                <span role="button">
                  {currentSchedule === 5
                    ? 'Hôm qua'
                    : currentSchedule === 6
                      ? 'Hôm nay'
                      : currentSchedule === 7
                        ? 'Ngày mai'
                        : scheduleList[currentSchedule] && scheduleList[currentSchedule].label
                  }
                </span>
              </div>
              {isShowCalendar &&
                <div className='loadingOverlayContainer'>
                  <div className='calendarCenter'>
                    <div className='closeCalendarButton' onClick={handleClickHideCalendar}><FontAwesomeIcon icon={faTimes} style={{ color: 'black' }} /></div>
                    <Calendar
                      // onChange={(date) => this._onCalendarChange(date)}
                      // onClickDecade={() => this._onClickDecade()}
                      onClickDay={(date) => handleClickDate(date)}
                      value={scheduleDate}
                      maxDate={new Date(moment().add(1, 'd'))}
                      minDate={new Date(moment().add(-6, 'd'))}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
          <ul id="container-epg" className="list-group epg" style={{ height: `${heightScreen - 200}px` }}>
            {(isLoadingEpg || isFetchingEpg)
              ? <Overlay />
              : epgList && epgList.map((item, index) => {
                return (
                  <li
                    id={`epg-${index}`}
                    key={index}
                    className={`list-group-item 
                      ${(currentSchedule > 6 || (currentSchedule === 6 && currentTime < (item.start_at * 1000))) ? 'epg-disabled' : ''} 
                      ${(currentSchedule === 6 && currentTime > (item.start_at * 1000) && currentTime < (item.end_at * 1000)) ? 'epg-is-live' : (index === currentEpg ? 'epg-is-live' : '')}
                    `}
                    onClick={e => handleClickEpg(e, index)}
                  >
                    <div className="row">
                      {/* <div className="col-3 col-md-3 col-xxl-2"> */}
                      <div className="col col-time-epg">
                        <div className="epg-title title-content-epg">{formatTime(item.start_at)}</div>
                        <div>
                          {/* {(currentSchedule === 6 && currentTime > (item.start_at * 1000) && currentTime < (item.end_at * 1000))
                            ? <div className="epg-current-live"><span className="epg-dot"></span>Live</div>
                            : (index === currentEpg) ? <div className="epg-current-playing">Đang phát</div> : null
                          } */}

                          {(currentSchedule === 6 && currentTime > (item.start_at * 1000) && currentTime < (item.end_at * 1000))
                            ? <div className="epg-current-live"><span className="epg-dot"></span>Live</div>
                            : (index === currentEpg) ? <div className="epg-current-playing-catchup">Đang phát</div> : null
                          }
                        </div>
                        {/* <figure className="figure">
                          <div className="figure-overflow">
                            <img
                              className="figure-img img-fluid rounded-block w-100 img-thumbnail"
                              src={item.images
                                ? ((item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : imgholder)
                                : imgholder
                              }
                              onError={(e) => {
                                e.target.onerror = null
                                e.target.src = imgholder
                              }}
                            />
                          </div>
                        </figure> */}
                      </div>
                      {/* <div className="col-8 col-md-8 col-xxl-9 pb-4 pb-md-0 epg-title"> */}
                      <div className="col pb-4 pb-md-0 epg-title">
                        <div className="title-content-epg">{item.title}</div>
                        {/* <div>
                          <span><FontAwesomeIcon icon={faClock} /></span>
                          <span className="ps-2">{formatTime(item.start_at)} - {formatTime(item.end_at)}</span>
                        </div> */}
                        {/* <div>
                          {(currentSchedule === 6 && currentTime > (item.start_at * 1000) && currentTime < (item.end_at * 1000))
                            ? <div className="epg-current-live">Live</div>
                            : (index === currentEpg) ? <div className="epg-current-playing">Đang phát</div> : null
                          }
                        </div> */}
                      </div>
                      {/* <div className="col-1 col-md-1 pb-4 pb-md-0 ps-1 epg-title"> */}
                      <div className="col-1 col-md-1 pb-4 pb-md-0 ps-1 epg-title">
                        {(currentSchedule > 6 || (currentSchedule === 6 && currentTime < (item.start_at * 1000)))
                          ? (currentSchedule === 6 && currentTime > (item.start_at * 1000) && currentTime < (item.end_at * 1000))
                              ? <></>
                            // : <Notification color="white" variant='Bold' size={24} className='me-1' />
                              : <></>
                          : (currentSchedule === 6 && currentTime > (item.start_at * 1000) && currentTime < (item.end_at * 1000))
                              ? <></>
                              : <>
                            </>
                        }
                      </div>
                    </div>

                    <hr className="epg-divider" />
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>

      {/* Channel List */}
      <div className="row pt-4 pt-md-5 pb-0 pb-md-4 channel-container-list">
        <div className="col-12 col-md-12 col-lg-8 offset-lg-2">
          <Slider ref={slider => setChannelSlider(slider)} {...{
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            initialSlide: 0,
            // prevArrow: <PrevButton />,
            // nextArrow: <NextButton />,
            responsive: [
              { breakpoint: 480, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 687, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 768, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 1024, settings: { slidesToShow: 5, slidesToScroll: 1 } },
              { breakpoint: 1350, settings: { slidesToShow: 5, slidesToScroll: 1 } }
            ]
          }}>
            {dataAllChannel && dataAllChannel.items && dataAllChannel.items.map((item, index) => {
              const indexFind = dataAllChannel.items.findIndex((itemFind) => {
                return itemFind.slug === slug
              })

              return (
                <figure key={index} className="figure px-2 px-md-2" onClick={e => handleClickChannel(e, item)}>
                  {indexFind === index && <div className="activeDot"></div>}
                  <img
                    className="figure-img img-fluid rounded-block w-100 channel-live"
                    style={{ cursor: 'pointer' }}
                    src={item.images
                      ? ((item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : imgholder)
                      : imgholder
                    }
                    onError={(e) => {
                      e.target.onerror = null
                      e.target.src = imgholder
                    }}
                  />
                </figure>
              )
            })}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default LiveGeoBlock
