import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Slider from 'react-slick'
import { setProgress } from 'features/watching/watchingSlice'
import { Play, InfoCircle } from 'iconsax-react'
import ModalFigure from 'features/core/ModalFigure'
import ImageHolder from 'features/core/ImageHolder'
import ImagePosterHolder from 'features/core/ImagePosterHolder'
import RibbonItemNewRelease from 'features/ribbon/components/RibbonItemNewRelease'
import RibbonItemTop from 'features/ribbon/components/RibbonItemTop'
import AgeRatingItem from 'components/AgeRatingItem'
import NewReleaseItem from 'components/NewReleaseItem'
import RibbonItemLiveEvent from 'features/ribbon/components/RibbonItemLiveEvent'
import { imgholder } from 'constant/images'
import '../styles/search.scss'
import ImageHolderCustom from 'features/core/ImageHolderCustom'

const SearchNew = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { data } = props

  const dispatch = useDispatch()

  const [modalContentId, setModalContentId] = useState(null)
  const [modalContentSlug, setModalContentSlug] = useState(null)
  const [modalContentEpisode, setModalContentEpisode] = useState(null)
  const [modalContentImage, setModalContentImage] = useState(null)
  const [modalContentDescription, setModalContentDescription] = useState(null)
  const [modalContentPublishDate, setModalContentPublishDate] = useState(null)
  const [modalContentNewRelease, setModalContentNewRelease] = useState(null)
  const [modalProgress, setModalProgress] = useState(null)
  const [isShowModal, setIsShowModal] = useState(false)

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const convertToPlain = (html) => {
    // Create a new div element
    const tempDivElement = document.createElement('div')

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html

    const htmlValue =
      tempDivElement.textContent || tempDivElement.innerText || ''

    tempDivElement.remove()

    // Retrieve the text property of the element
    return htmlValue
  }

  const handleClickWatching = (e, progress) => {
    dispatch(setProgress(progress))
  }

  const handleClickWatchingFromBegin = (e, progress) => {
    dispatch(setProgress(0))
  }

  const handleClickShowModal = (e, item) => {
    if (item.images && item.images.cover && item.images.cover !== '') {
      setModalContentImage(item.images
        ? ((item.images.cover && item.images.cover) !== '' ? item.images.cover : imgholder)
        : imgholder)
    } else {
      setModalContentImage(item.images
        ? ((item.images.thumbnail && item.images.thumbnail) !== '' ? item.images.thumbnail : imgholder)
        : imgholder)
    }

    const strippedHtml = convertToPlain(
      item.long_description
    )
    setModalContentDescription(strippedHtml)

    setModalContentPublishDate(item.publish_date)

    setModalContentId(item.id)
    setModalContentSlug((item.default_episode && item.default_episode.id) ? item.default_episode.id : item.slug)
    setModalContentEpisode(item?.default_episode?.episode)
    setModalProgress(item.progress)
    setModalContentNewRelease(item.new_release)
    setIsShowModal(true)
  }

  const handleCloseModal = (value) => {
    setIsShowModal(value)
    setModalContentId(null)
  }

  return (
    <div className="search-new">
      {Array.isArray(data) && data.map((itemParent, indexParent) => {
        return (
          <div key={indexParent}>
            {Array.isArray(itemParent) && itemParent.map((item, index) => {
              const itemArrTemp = item.items
              const itemArr = Array.isArray(itemArrTemp) ? (item?.type === 5 ? itemArrTemp.slice(0, 9) : itemArrTemp) : []

              if (item?.type === 'artist') {
                return (
                  <div key={index}>
                    {itemArr?.length > 0 &&
                      <>
                        <div className="row pt-4">
                          <div className="col-md-12">
                            <h3 className="ribbon-title ps-1 ps-md-2 pb-0 pb-md-1">
                              <span>{item?.name}</span>
                              {/* <Link to={`${(location.pathname && location.pathname !== '' && location.pathname !== '/') ? location.pathname : '/page/trang-chu'}/${item.slug}/tat-ca`}>
                                <span className="text-all">Xem thêm &#8250;</span>
                              </Link> */}
                            </h3>
                          </div>
                        </div>

                        <div className="row container-wrapper">
                          <div className="col-md-12">
                            <Slider {...{
                              className: 'vod-ribbon',
                              dots: false,
                              infinite: false,
                              swipeToSlide: false,
                              speed: 500,
                              initialSlide: 0,
                              slidesToShow: 5,
                              slidesToScroll: 5,
                              responsive: [
                                { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                                { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                                { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 3 } },
                                { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                                { breakpoint: 1350, settings: { slidesToShow: 5, slidesToScroll: 5 } }
                                // { breakpoint: 1200, settings: { slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5), slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5) } },
                                // { breakpoint: 1500, settings: { slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5), slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5) } }
                              ]
                            }}>
                              {Array.isArray(itemArr) && itemArr.map((itemChild, indexChild) => {
                                return (
                                  <div key={indexChild} className="mb-1 mb-md-0">
                                    <figure style={{ borderRadius: '4px', backgroundColor: '#262626', width: '100%', padding: '30px' }}>
                                      <div>
                                        <Link to={`/artist/${itemChild.id}`} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                                          <div style={{ width: '70px', height: '70px', borderRadius: '50%' }}>
                                            <ImageHolderCustom data={itemChild.avatar_url} />
                                          </div>
                                          <div style={{ color: 'white', marginLeft: '15px' }}>{itemChild.name}</div>
                                        </Link>
                                      </div>
                                    </figure>
                                  </div>
                                )
                              })}
                            </Slider>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                )
              } else {
                return (
                  <div key={index}>
                    {itemArr?.length > 0 &&
                      <>
                        <div className="row pt-4">
                          <div className="col-md-12">
                            <h3 className="ribbon-title ps-1 ps-md-2 pb-0 pb-md-1">
                              <span>{item?.name}</span>
                              {/* <Link to={`${(location.pathname && location.pathname !== '' && location.pathname !== '/') ? location.pathname : '/page/trang-chu'}/${item.slug}/tat-ca`}>
                                <span className="text-all">Xem thêm &#8250;</span>
                              </Link> */}
                            </h3>
                          </div>
                        </div>

                        <div className="row container-wrapper">
                          <div className="col-md-12">
                            <Slider {...{
                              className: item.id === '4818436b-d7bf-4867-85ca-64203b11c694' ? 'live-ribbon' : `vod-ribbon ${(item?.type === 6) ? 'newrelease-ribbon' : ''}`,
                              dots: false,
                              infinite: false,
                              swipeToSlide: false,
                              speed: 500,
                              initialSlide: 0,
                              slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 4.5 : item?.type === 5 ? 4.5 : 5),
                              slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 4.5 : item?.type === 5 ? 4.5 : 5),
                              // slidesToShow: item.type === 1 ? 7 : 5,
                              // slidesToScroll: item.type === 1 ? 7 : 5,
                              responsive: [
                                { breakpoint: 480, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                                { breakpoint: 687, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                                { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 3 } },
                                { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                                { breakpoint: 1350, settings: { slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 4.5 : 5), slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 4.5 : 5) } }
                                // { breakpoint: 1200, settings: { slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5), slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5) } },
                                // { breakpoint: 1500, settings: { slidesToShow: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5), slidesToScroll: item.type === 1 ? 7 : (item?.type === 6 ? 3.5 : item?.type === 5 ? 2.5 : 5) } }
                              ]
                            }}>
                              {Array.isArray(itemArr) && itemArr.map((itemChild, indexChild) => {
                                // const peopleArr = itemChild.people
                                // let actorStr = ''
                                // let directorStr = ''
                                // const publishDateStr = parseDate(itemChild.publish_date)

                                // if (Array.isArray(peopleArr)) {
                                //   for (let i = 0; i < peopleArr.length; i++) {
                                //     if (peopleArr[i] && peopleArr[i].role === 'actor') {
                                //       actorStr += peopleArr[i].name + ', '
                                //     }
                                //     if (peopleArr[i] && peopleArr[i].role === 'director') {
                                //       directorStr += peopleArr[i].name + ', '
                                //     }
                                //   }
                                // }

                                // actorStr = actorStr.substring(0, actorStr.length - 2)
                                // directorStr = directorStr.substring(0, directorStr.length - 2)

                                if (item?.type === 6) {
                                  return (
                                    <RibbonItemNewRelease
                                      key={indexChild}
                                      indexChild={indexChild}
                                      handleClickShowModal={handleClickShowModal}
                                      item={item}
                                      itemChild={itemChild}
                                      slug={null}
                                    />
                                  )
                                } else if (item?.type === 5) { // Top 10
                                  return (
                                    <RibbonItemTop
                                      key={indexChild}
                                      indexChild={indexChild}
                                      handleClickShowModal={handleClickShowModal}
                                      item={item}
                                      itemChild={itemChild}
                                      slug={null}
                                    />
                                  )
                                } else {
                                  return (
                                    // <div key={indexChild} className="pb-1 pb-md-4 item-figure">
                                    <div key={indexChild} className="mb-1 mb-md-0 item-figure">
                                      {/* <Link to={`${itemChild.type === 5 ? '/live/' : '/detail/'}${itemChild.slug}`}> */}
                                        {/* <figure className="figure px-1 px-md-2"> */}
                                        <figure className="figure">
                                          <AgeRatingItem item={itemChild} />

                                          <NewReleaseItem
                                            item={itemChild}
                                            // isPoster={itemChild?.type === 1}
                                            isPoster={item?.type === 1}
                                          />

                                          <RibbonItemLiveEvent data={itemChild} />

                                          {itemChild.slug === 'phat-thanh'
                                            ? <a href="https://audio.thvl.vn/radio/phat-thanh" target="_blank" rel="noopener noreferrer">
                                                <div className="figure-overflow">
                                                  <ImageHolder data={itemChild.images} />
                                                </div>
                                              </a>
                                            : <Link to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${'trang-chu'}/${item.slug}/`)}${itemChild.slug}`}>
                                                {/* <div className={`figure-overflow ${item.id === 'fb6237e5-471f-474b-8fad-7cd6f1777be0' ? 'overflow_master_banner_mobile' : ''}`}> */}
                                                  <div className="figure-overflow">

                                                    {/* <ImageHolder data={itemChild.images} /> */}

                                                    {/* {item.id === 'fb6237e5-471f-474b-8fad-7cd6f1777be0'
                                                      ? <ImagePosterHolder data={itemChild.images} />
                                                      : <ImageHolder data={itemChild.images} />
                                                    } */}

                                                    {item.type === 1
                                                      ? <ImagePosterHolder data={itemChild.images} />
                                                      : <ImageHolder data={itemChild.images} />
                                                    }
                                                    {/* <ImageHolder data={itemChild.images} /> */}
                                                </div>
                                              </Link>
                                          }

                                          {itemChild.type !== 5 &&
                                            <div className={`info-detail ${item.type === 1 ? 'info_detail_master_banner_mobile' : ''}`} onClick={e => handleClickShowModal(e, itemChild)}>
                                              <Link
                                                // to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${'trang-chu'}/${item.slug}/`)}${itemChild.slug}`}
                                                to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${itemChild.slug}/`)}`}
                                                className="link-btn-play"
                                              >
                                                <button className={`btn btn-primary btn-play ${item.type === 1 ? 'btn_play_master_banner_mobile' : ''}`}>
                                                {/* <button className="btn btn-primary btn-play"> */}
                                                  {/* <FontAwesomeIcon icon={faPlay} className="icon-play me-2" /> */}
                                                  <Play color="black" variant='Bold' size={12} className='me-1' />
                                                  {item.type === 1 ? 'Xem ngay' : 'Xem'}
                                                </button>
                                              </Link>
                                              <button className={`btn btn-primary btn-info-detail ${item.type === 1 ? 'btn_play_master_banner_mobile' : ''}`} onClick={e => handleClickShowModal(e, itemChild)}>
                                              {/* <button className="btn btn-primary btn-info-detail" onClick={e => handleClickShowModal(e, itemChild)}> */}
                                                {/* <FontAwesomeIcon icon={faInfoCircle} className="icon-info-detail me-2" /> */}
                                                <InfoCircle color="white" variant='Bold' size={12} className='me-1' />
                                                Thông tin
                                              </button>

                                              {/* {directorStr.length > 0 &&
                                                <div className="info-text">Đạo diễn: {directorStr}</div>
                                              }
                                              {actorStr.length > 0 &&
                                                <div className="info-text">Diễn viên: {actorStr}</div>
                                              }
                                              <div className="info-text pb-2">Xuất bản: {publishDateStr}</div> */}
                                            </div>
                                          }
                                        </figure>
                                      {/* </Link> */}
                                    </div>
                                  )
                                }
                              })}
                            </Slider>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                )
              }
            })}
          </div>
        )
      })}

      <ModalFigure
        isShow={isShowModal}
        modalContentId={modalContentId}
        modalContentSlug={modalContentSlug}
        modalContentEpisode={modalContentEpisode}
        modalContentImage={modalContentImage}
        modalContentDescription={modalContentDescription}
        modalContentPublishDate={modalContentPublishDate}
        modalContentNewRelease={modalContentNewRelease}
        onClose={handleCloseModal}
        onClickPlay={e => handleClickWatching(e, modalProgress)}
        onClickPlayFromBegin={e => handleClickWatchingFromBegin(e, modalProgress)}
      />
    </div>
  )
}

SearchNew.propTypes = {
  data: PropTypes.array
}

SearchNew.defaultProps = {
  data: []
}

export default SearchNew
