import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import MenuCustom from 'features/menu/components/MenuCustom'
import Footer from 'features/footer/components/Footer'
import '../styles/layout.scss'

const Layout = (props) => {
  const { children } = props

  const location = useLocation()

  return (
    <div className="main-layout">
      <MenuCustom />

      <div className={`main-container ${location.pathname === '/' ? '' : 'main-container-detail'}`}>
        {children}
      </div>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any
}

Layout.defaultProps = {
}

export default Layout
