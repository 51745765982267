import SupportPage from 'features/support/pages/SupportPage'
import HomePage from 'features/home/pages/HomePage'
import LivePage from 'features/live/pages/LivePage'
import DetailPage from 'features/detail/pages/DetailPage'
import DetailTrailerPage from 'features/detail/pages/DetailTrailerPage'
import DetailLiveEventPage from 'features/detail/pages/DetailLiveEventPage'
import RibbonDetailPage from 'features/ribbon/pages/RibbonDetailPage'
// import SearchPage from 'features/search/pages/SearchPage'
import SearchNewPage from 'features/search/pages/SearchNewPage'
import FaqPage from 'features/faq/pages/FaqPage'
import PrivacyPolicyPage from 'features/privacy-policy/pages/PrivacyPolicyPage'
import LoginPage from 'features/auth/pages/LoginPage'
import RegisterPage from 'features/auth/pages/RegisterPage'
import ForgetPasswordPage from 'features/auth/pages/ForgetPasswordPage'
import UpdatePasswordPage from 'features/auth/pages/UpdatePasswordPage'
import ChangePasswordPage from 'features/auth/pages/ChangePasswordPage'
import ChangeInfoPage from 'features/auth/pages/ChangeInfoPage'
import ActiveEmailPage from 'features/auth/pages/ActiveEmailPage'
import TvPage from 'features/auth/pages/TvPage'
import ProfilePage from 'features/profile/pages/ProfilePage'
import WatchingPage from 'features/watching/pages/WatchingPage'
import AdsPage from 'features/ads/AdsPage'
import DownloadAppPage from 'features/home/pages/DownloadAppPage'
import HashtagPage from 'features/hashtag/pages/HashtagPage'
import ArtistPage from 'features/artist/pages/ArtistPage'
import ArtistCustomPage from 'features/artist/pages/ArtistCustomPage'

const routes = [
  { path: '/', component: HomePage, exact: true },
  { path: '/live/:slug', component: LivePage, exact: false },
  // { path: '/:page/:ribbon/:slug', component: DetailPage, exact: true },
  { path: '/detail/:page/:ribbon/:slug', component: DetailPage, exact: false },
  { path: '/detail/:slug', component: DetailPage, exact: false },
  { path: '/truc-tiep/:slug', component: DetailLiveEventPage, exact: false },
  { path: '/truc-tiep/:page/:ribbon/:slug', component: DetailLiveEventPage, exact: false },
  { path: '/trailer/:slug/:slugtrailer', component: DetailTrailerPage, exact: false },
  // { path: '/ribbon/:id', component: RibbonDetailPage, exact: false },
  { path: '/page/:slug', component: HomePage, exact: true },
  { path: '/page/:slug/:ribbonSlug/tat-ca', component: RibbonDetailPage, exact: true },
  // { path: '/search', component: SearchPage, exact: false },
  { path: '/search', component: SearchNewPage, exact: false },
  { path: '/faq', component: FaqPage, exact: false },
  { path: '/privacy-policy', component: PrivacyPolicyPage, exact: false },
  { path: '/privacy-policy.html', component: PrivacyPolicyPage, exact: false },
  { path: '/support', component: SupportPage, exact: false },
  { path: '/login', component: LoginPage, exact: false },
  { path: '/register', component: RegisterPage, exact: false },
  { path: '/forget-password', component: ForgetPasswordPage, exact: false },
  { path: '/update-forget-password', component: UpdatePasswordPage, exact: false },
  { path: '/change-password', component: ChangePasswordPage, exact: false },
  { path: '/change-info', component: ChangeInfoPage, exact: false },
  { path: '/validate_register', component: ActiveEmailPage, exact: false },
  { path: '/tv', component: TvPage, exact: false },
  { path: '/profile', component: ProfilePage, exact: false },
  { path: '/watching', component: WatchingPage, exact: false },
  { path: '/ads-mgid', component: AdsPage, exact: false },
  { path: '/download-app', component: DownloadAppPage, exact: false },

  { path: '/hashtag', component: HashtagPage, exact: false },
  { path: '/artist/:slug/:slugcontent', component: ArtistPage, exact: false },
  { path: '/artist/:slug', component: ArtistCustomPage, exact: false }
]

export default routes
