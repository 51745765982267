import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import axios from 'axios'
import { getData } from 'common/utils'
import 'bootstrap/js/dist/modal'
import './styles/modal-report-error.scss'

const ModalReportError = (props) => {
  const { detailId, isShow, onClose } = props

  const [show, setShow] = useState(false)
  const [reasonList, setReasonList] = useState([])
  const [selectedReasonList, setSelectedReasonList] = useState([])

  const { data: dataReason } = useQuery('getReason', async () => {
    return await getData('GET', 'reasons/')
  })

  useEffect(() => {
    if (dataReason) {
      setReasonList(dataReason)
    }
  }, [dataReason])

  useEffect(() => {
    if (isShow) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [isShow])

  useEffect(() => {
    if (show) {
      const modalOpen = document.getElementById('modalOpen')
      if (modalOpen) {
        modalOpen.click()
      }
    }
  }, [show])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */
  const handleClickReason = (e, idParam) => {
    const selectedReasonListClone = [...selectedReasonList]
    let indexReason = -1

    for (let i = 0; i < selectedReasonList.length; i++) {
      if (selectedReasonList[i] === idParam) {
        indexReason = i
      }
    }

    if (indexReason !== -1) {
      selectedReasonListClone.splice(indexReason, 1)
    } else {
      selectedReasonListClone.push(idParam)
    }

    setSelectedReasonList(selectedReasonListClone)
  }

  const handleSendReport = () => {
    if (selectedReasonList && selectedReasonList.length > 0) {
      axios.post(`${process.env.REACT_APP_API_URL}report/`, {
        reasons: selectedReasonList,
        content: detailId,
        platform: 'web',
        version: 'v3.4.0',
        description: null
      })
        .then(function (response) {
          console.log(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    }

    setShow(false)
    onClose(false)
  }

  const handleCloseModal = () => {
    setShow(false)
    onClose(false)
  }

  return (
    <div className="modal-report-error">
      <div className="modal modal-custom fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Báo Cáo Lỗi</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>
            </div>
            <div className="modal-body">
              <div className="report-info">Chúng tôi chân thành xin lỗi và rất tiếc về sự cố quý khách gặp phải</div>
              <div className="report-info pt-2">Để cải thiện chất lượng sản phẩm tốt hơn quý khách vui lòng chọn báo cáo lỗi được gợi ý bên dưới để giúp chúng tôi trong việc khắc phục nhanh hơn.</div>
              <div className="pt-4">
                {reasonList && reasonList.map((item, index) => {
                  let isReason = false
                  for (let i = 0; i < selectedReasonList.length; i++) {
                    if (selectedReasonList[i] === item.id) {
                      isReason = true
                    }
                  }

                  return (
                    <div
                      key={index}
                      className={`alert alert-dark alert-reason ${isReason ? 'active' : ''}`}
                      role="alert"
                      onClick={(e) => handleClickReason(e, item.id)}
                    >
                      {item.title}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleCloseModal}>Đóng</button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleSendReport}>Gửi báo cáo</button>
            </div>
          </div>
        </div>
      </div>

      <button id="modalOpen" style={{ display: 'none' }} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
        Open
      </button>
    </div>
  )
}

ModalReportError.propTypes = {
  detailId: PropTypes.string,
  isShow: PropTypes.bool,
  onClose: PropTypes.func
}

ModalReportError.defaultProps = {
  detailId: '',
  isShow: false
}

export default ModalReportError
