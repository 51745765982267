/* eslint-disable react/prop-types */
import React from 'react'
import './styles/new-release-item.scss'

const NewReleaseItem = ({ item, isPoster }) => {
  const renderNewReleaseBadge = () => {
    if (item?.type !== 5) {
      if (isPoster) {
        if (item?.type === 2) {
          return (
            <div className='ribbon-item-new-release-show-poster'>
              <div className='ribbon-item-new-release-show-thumbnail-left'>
                <div className='ribbon-item-new-release-movie-thumbnail-text'>Tập mới</div>
              </div>
              <div className='ribbon-item-new-release-show-thumbnail-right'>
                <div className='ribbon-item-new-release-movie-thumbnail-text'>Xem ngay</div>
              </div>
            </div>
          )
        }

        return (
          <div className='ribbon-item-new-release-movie-poster'>
            <div className='ribbon-item-new-release-movie-thumbnail-text'>Mới thêm</div>
          </div>
        )
      } else {
        if (item?.type === 2) {
          return (
            <div className='ribbon-item-new-release-show-thumbnail'>
              <div className='ribbon-item-new-release-show-thumbnail-left'>
                <div className='ribbon-item-new-release-movie-thumbnail-text'>Tập mới</div>
              </div>
              <div className='ribbon-item-new-release-show-thumbnail-right'>
                <div className='ribbon-item-new-release-movie-thumbnail-text'>Xem ngay</div>
              </div>
            </div>
          )
        }

        return (
          <div className='ribbon-item-new-release-movie-thumbnail'>
            <div className='ribbon-item-new-release-movie-thumbnail-text'>Mới thêm</div>
          </div>
        )
      }
    }

    return null
  }

  return (
    <>
      {item?.new_release
        ? <div className={`ribbon-item-new-release-wrapper ${isPoster ? 'ribbon-item-new-release-wrapper-poster' : 'ribbon-item-new-release-wrapper-thumbnail'}`}>
            {renderNewReleaseBadge()}
          </div>
        : <></>
      }
    </>
  )
}

export default NewReleaseItem
