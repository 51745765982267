/* eslint-disable multiline-ternary */
/* eslint-disable react/prop-types */

import React from 'react'
import { Link } from 'react-router-dom'
import AgeRatingItem from 'components/AgeRatingItem'
import RibbonItemLiveEvent from './RibbonItemLiveEvent'
import NewReleaseItem from 'components/NewReleaseItem'
import ImageHolder from 'features/core/ImageHolder'
import ImagePosterHolder from 'features/core/ImagePosterHolder'
import bgTop10 from '../styles/images/bg_top10.png'
import '../styles/ribbon-item-top.scss'

const RibbonItemTopMobile = ({ index, indexChild, item, itemChild, slug }) => {
  return (
    <div key={indexChild} className="px-1 px-md-2 pb-1 pb-md-4 item-figure ribbon-item-top-mobile">
      <Link to={`${itemChild.type === 5 ? '/live/' : (itemChild.type === 10 ? '/truc-tiep/' : `/detail/${slug || 'trang-chu'}/dang-xem/`)}${itemChild.slug}`}>
        <figure className="figure">
          <div className={`figure-overflow ${item.type === 1 ? 'overflow_master_banner_mobile' : ''}`}>
            <AgeRatingItem item={itemChild} />

          {/* <div className="figure-overflow"> */}
            <NewReleaseItem
              item={itemChild}
              // isPoster={item?.type === 1}
              isPoster
            />

            <RibbonItemLiveEvent data={itemChild} />

            <div className="badge-top10">
              <img src={bgTop10} alt="" />
            </div>

            <div className="order-top10">
              {indexChild + 1}
            </div>

            {item.type === 1
              ? <ImagePosterHolder data={itemChild.images} />
              : <ImageHolder data={itemChild.images} />
            }
            {/* <ImageHolder data={itemChild.images} /> */}
          </div>
          <figcaption className="figure-caption">
            {itemChild.title}
          </figcaption>
        </figure>
      </Link>
    </div>
  )
}

export default RibbonItemTopMobile
