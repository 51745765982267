/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { imgGoogleIcon, imgFacebookIcon, imgAppleIcon } from 'constant/images'
import InputForm from 'components/InputForm'
import Overlay from 'components/Overlay'
import '../styles/login.scss'

const Login = (props) => {
  const { handleSubmit, register, formState: { errors } } = useForm()

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Đăng nhập',
        page_location: 'https://thvli.vn/login',
        page_path: '/login',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    document.title = 'Đăng nhập'

    if (localStorage.getItem('adm_token')) {
      window.location.href = '/'
    }

    // Google Login
    if (gapi) {
      gapi.load('auth2', () => {
        // eslint-disable-next-line no-var
        var auth2 = gapi.auth2.init({
          // client_id: '1049167759129-tqefrdond9fo7fi2s9k1kq6lqve0avpj.apps.googleusercontent.com',
          client_id: '513556246464-88ovl0o6l91igapfb29ts41nilsqji5a.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin'
        })

        auth2.attachClickHandler(document.getElementById('googleLogin'), {},
          function (googleUser) {
            const GoogleAuth = gapi.auth2.getAuthInstance()
            const user = GoogleAuth.currentUser.get()
            const token = user.getAuthResponse(true)
            const googleToken = token.access_token

            // Login
            const formData = new FormData()
            formData.append('token', googleToken)
            formData.append('platform', 'web')
            formData.append('app_version ', '3.7.0')
            axios.post(`${process.env.REACT_APP_API_SETTING_URL}login/google`, formData)
              .then(res => {
                if (res.status === 200) {
                  setError('')

                  const resultData = res.data
                  if (resultData) {
                    localStorage.setItem('adm_email', resultData.profile && resultData.profile.email)
                    localStorage.setItem('adm_avatar', resultData.profile && resultData.profile.avatar)
                    localStorage.setItem('adm_token', resultData.access_token)
                    localStorage.setItem('adm_refresh_token', resultData.refresh_token)
                    if (localStorage.getItem('adm_redirect_url')) {
                      window.location.href = localStorage.getItem('adm_redirect_url')
                    } else {
                      window.location.href = '/'
                    }
                  }
                }
                setIsLoading(false)
              })
              .catch(err => {
                const errData = err.response && err.response.data
                if (errData) {
                  if (errData.error === 400) {
                    const messageError = errData.message ? errData.message : errData.detail
                    setError(messageError)
                  } else if (errData.error === 412) {
                    const messageError = errData.data && errData.data.error_msg
                    setError(messageError)
                  } else {
                    setError('Something went wrong!')
                  }
                } else {
                  setError('Something went wrong!')
                }

                setIsLoading(false)
                console.log(err)
              })
          }, function (error) {
            // alert(JSON.stringify(error, undefined, 2))
            console.log(error)
          })
      })
    }

    // Apple Login
    if (AppleID) {
      if (AppleID.auth) {
        AppleID.auth.init({
          clientId: 'vn.thvl.app.login',
          scope: 'name email',
          redirectURI: window.location.hostname === 'staging.thvli.vn' ? process.env.REACT_APP_STAGING_APPLE_REDIRECT_URI : process.env.REACT_APP_APPLE_REDIRECT_URI,
          state: 'state',
          nonce: '78377b525757b494427f89014f97d79928f3938d14eb51e20fb5dec9834eb304',
          usePopup: true
        })
      }

      // Listen for authorization success.
      document.addEventListener('AppleIDSignInOnSuccess', (event) => {
        // Handle successful response.
        console.log(event)

        const formData = new FormData()
        formData.append('token', event && event.detail && event.detail.authorization && event.detail.authorization.id_token)
        formData.append('first_name', event && event.detail && event.detail.user && event.detail.user.name && event.detail.user.name.firstName)
        formData.append('last_name', event && event.detail && event.detail.user && event.detail.user.name && event.detail.user.name.lastName)
        formData.append('platform', 'web')
        formData.append('app_version ', '3.7.0')
        axios.post(`${process.env.REACT_APP_API_SETTING_URL}login/apple/base/`, formData)
          .then(res => {
            if (res.status === 200) {
              setError('')

              const resultData = res.data
              if (resultData) {
                localStorage.setItem('adm_email', resultData.profile && resultData.profile.email)
                // localStorage.setItem('adm_avatar', resultData.profile && resultData.profile.avatar)
                localStorage.setItem('adm_token', resultData.access_token)
                localStorage.setItem('adm_refresh_token', resultData.refresh_token)
                if (localStorage.getItem('adm_redirect_url')) {
                  window.location.href = localStorage.getItem('adm_redirect_url')
                } else {
                  window.location.href = '/'
                }
              }
            }
            setIsLoading(false)
          })
          .catch(err => {
            const errData = err.response && err.response.data
            if (errData) {
              if (errData.error === 400) {
                const messageError = errData.message ? errData.message : errData.detail
                setError(messageError)
              } else if (errData.error === 412) {
                const messageError = errData.data && errData.data.error_msg
                setError(messageError)
              } else {
                setError('Something went wrong!')
              }
            } else {
              setError('Something went wrong!')
            }

            setIsLoading(false)
            console.log(err)
          })
      })

      // Listen for authorization failures.
      document.addEventListener('AppleIDSignInOnFailure', (event) => {
        // Handle error.
        console.log(event)
      })
    }
  }, [])

  const onSubmit = (data) => {
    if (data) {
      setIsLoading(true)

      const formData = new FormData()
      formData.append('email', data.email)
      formData.append('password', data.password)
      formData.append('platform', 'web')
      formData.append('app_version ', '3.7.0')

      axios.post(`${process.env.REACT_APP_API_SETTING_URL}login/email/`, formData)
        .then(res => {
          if (res.status === 200) {
            setError('')

            const resultData = res.data
            if (resultData) {
              localStorage.setItem('adm_email', resultData.profile && resultData.profile.email)
              localStorage.setItem('adm_token', resultData.access_token)
              localStorage.setItem('adm_refresh_token', resultData.refresh_token)

              if (localStorage.getItem('adm_redirect_url')) {
                window.location.href = localStorage.getItem('adm_redirect_url')
              } else {
                window.location.href = '/'
              }
            }
          }
          setIsLoading(false)
        })
        .catch(err => {
          const errData = err.response && err.response.data
          if (errData) {
            if (errData.error === 400) {
              const messageError = errData.message ? errData.message : errData.detail
              setError(messageError)
            } else if (errData.error === 412) {
              const messageError = errData.data && errData.data.error_msg
              setError(messageError)
            } else {
              setError('Something went wrong!')
            }
          } else {
            setError('Something went wrong!')
          }

          setIsLoading(false)
          console.log(err)
        })
    }
  }

  const handleLoginFacebook = () => {
    // eslint-disable-next-line no-undef
    if (FB) {
      setIsLoading(true)

      // eslint-disable-next-line no-undef
      FB.login(response => {
        if (response.authResponse) {
          const facebookToken = response.authResponse && response.authResponse.accessToken
          if (facebookToken) {
            localStorage.setItem('adm_fb_token', facebookToken)

            // Login
            const formData = new FormData()
            formData.append('token', facebookToken)
            formData.append('platform', 'web')
            formData.append('app_version ', '3.7.0')
            axios.post(`${process.env.REACT_APP_API_SETTING_URL}login/facebook`, formData)
              .then(res => {
                if (res.status === 200) {
                  setError('')

                  const resultData = res.data
                  if (resultData) {
                    localStorage.setItem('adm_email', resultData.profile && resultData.profile.email)
                    localStorage.setItem('adm_avatar', resultData.profile && resultData.profile.avatar)
                    localStorage.setItem('adm_token', resultData.access_token)
                    localStorage.setItem('adm_refresh_token', resultData.refresh_token)
                    if (localStorage.getItem('adm_redirect_url')) {
                      window.location.href = localStorage.getItem('adm_redirect_url')
                    } else {
                      window.location.href = '/'
                    }
                  }
                }
                setIsLoading(false)
              })
              .catch(err => {
                const errData = err.response && err.response.data
                if (errData) {
                  if (errData.error === 400) {
                    const messageError = errData.message ? errData.message : errData.detail
                    setError(messageError)
                  } else if (errData.error === 412) {
                    const messageError = errData.data && errData.data.error_msg
                    setError(messageError)
                  } else {
                    setError('Something went wrong!')
                  }
                } else {
                  setError('Something went wrong!')
                }

                setIsLoading(false)
                console.log(err)
              })
          }
        } else {
          setIsLoading(false)
        }

        console.log(response)
      })
    }
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)()
    }
  }

  const handleClickLoginApple = () => {
    const appleLogin = document.getElementById('appleid-signin')
    if (appleLogin) {
      appleLogin.click()
    }
  }

  return (
    <div className="login">
      {/* <div className="auth-background">
        <img src={imgAuthBackground} />
      </div> */}

      <div className="auth-title">ĐĂNG NHẬP</div>

      {error !== '' &&
        <div className="row pt-4">
          <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        </div>
      }

      <div className="row pt-4">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <InputForm register={register} error={errors.email} required name="email" label="Email" className="register-input" placeholder="Email" onKeyDown={handleEnter} />
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <InputForm type="password" register={register} error={errors.password} required name="password" label="Mật khẩu" className="register-input" placeholder="Mật khẩu" onKeyDown={handleEnter} />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4 text-center">
          <Link className="forget-password" to="/forget-password">Quên mật khẩu</Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <button className="btn btn-primary w-100 btn-login" onClick={handleSubmit(onSubmit)}>ĐĂNG NHẬP</button>
        </div>
      </div>

      <div className="row pt-4">
        <div className="col-10 col-lg-4 offset-1 offset-lg-4">
          <div className="text-register">hoặc</div>
        </div>
      </div>

      <div className="row pt-4">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <div className="login-social row" id="googleLogin">
            <div className="col-12">
              <div className='social-btn-container'>
                <img className="social-icon" src={imgGoogleIcon} width={20} height={20} />
                <div className="social-text">Đăng nhập với Google</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-3">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <div className="login-social row" onClick={handleLoginFacebook}>
            <div className="col-12">
              <div className='social-btn-container'>
                <img className="social-icon" src={imgFacebookIcon} width={20} height={20} />
                <div className="social-text">Đăng nhập với Facebook</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-3 pb-5">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <div className="login-social row">
            <div className="col-12">
              <div className='social-btn-container'>
                <img className="social-icon" src={imgAppleIcon} width={20} height={20} />
                <div className="social-text" onClick={handleClickLoginApple}>Đăng nhập với Apple</div>
              </div>
            </div>
          </div>

          <div id="appleid-signin" data-color="black" data-border="true" data-type="sign-in" style={{ display: 'none' }}></div>
        </div>
      </div>

      <div className="row pt-4 pb-5">
        <div className="col-10 col-lg-4 offset-1 offset-lg-4">
          <div className="text-register">Bạn chưa có tài khoản? <Link to="/register"><div className="text-primary">Đăng Ký</div></Link></div>
        </div>
      </div>

      {isLoading && <Overlay />}
    </div>
  )
}

export default Login
