import React, { useEffect } from 'react'
import Layout from 'features/layout/components/Layout'

const DownloadAppPage = (props) => {
  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      let appstoreURL = ''

      if (navigator.userAgent.match(/Android/i)) {
        appstoreURL = 'https://play.google.com/store/apps/details?id=vn.thvl.app'
      }

      if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        appstoreURL = 'itms-apps://itunes.apple.com/app/thvli/id1185316310'
      }

      let pathname = window.location.pathname
      if (pathname && pathname.charAt(0) === '/') {
        pathname = pathname.substring(1)
      }

      const pathArray = pathname.trim().split('/')
      let slug = ''
      let deeplinkPath = 'thvli://'

      if (pathArray.length > 1) {
        slug = pathArray[1]
        if (pathArray[0] === 'detail') {
          deeplinkPath = 'thvli://entity/' + slug
        } else if (pathArray[0] === 'live') {
          deeplinkPath = 'thvli://live/' + slug
        }
      }

      const now = new Date().valueOf()
      setTimeout(function () {
        if (new Date().valueOf() - now > 100) {
          window.location = appstoreURL
        }
      }, 2000)

      window.location = deeplinkPath
    }
  }, [])

  return (
    <Layout>
      <div></div>
    </Layout>
  )
}

export default DownloadAppPage
