import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import imgInstall from '../styles/images/img-install.png'
import '../styles/detail-info.scss'
import { getData } from 'common/utils'

const DetailInfo = (props) => {
  // const { data, title } = props
  const { data, title, hashtagList } = props

  // const [actor, setActor] = useState('')
  // const [director, setDirector] = useState('')
  const [directorList, setDirectorList] = useState([])
  const [actorList, setActorList] = useState([])
  const [publishDate, setPublishDate] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    if (data) {
      if (data.long_description === null || data.long_description === '') {
        let parentIdTemp = null

        const parentEpisode = data.parent_episode
        if (parentEpisode && parentEpisode.season_id) {
          parentIdTemp = parentEpisode.season_id
        } else {
          parentIdTemp = data.group
        }

        if (parentIdTemp) {
          const fetchSeasonInfo = async () => {
            const resultSeasonInfo = await getData('GET', `get_detail/${parentIdTemp}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
            if (resultSeasonInfo) {
              const strippedHtml = convertToPlain(
                resultSeasonInfo.long_description
              )
              setDescription(strippedHtml)
            }
          }

          fetchSeasonInfo()
        }
      } else {
        const strippedHtml = convertToPlain(
          data.long_description
        )
        setDescription(strippedHtml)
      }

      // setDescription(data.long_description)
      setPublishDate(data.publish_date)

      // let actorStr = ''
      // let directorStr = ''

      const actorArr = []
      const directorArr = []

      if (data.people) {
        for (let i = 0; i < data.people.length; i++) {
          if (data.people[i].role === 'actor') {
            // actorStr += data.people[i].name + ', '
            actorArr.push(data.people[i])
          }
          if (data.people[i].role === 'director') {
            // directorStr += data.people[i].name + ', '
            directorArr.push(data.people[i])
          }
        }
      }
      // setActor(actorStr.substr(0, actorStr.length - 2))
      // setDirector(directorStr.substr(0, directorStr.length - 2))

      setActorList(actorArr)
      setDirectorList(directorArr)
    }
  }, [data])

  const convertToPlain = (html) => {
    // Create a new div element
    const tempDivElement = document.createElement('div')

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html

    const htmlValue =
      tempDivElement.textContent || tempDivElement.innerText || ''

    tempDivElement.remove()

    // Retrieve the text property of the element
    return htmlValue
  }

  const handleClickInstallApp = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      let appstoreURL = ''

      if (navigator.userAgent.match(/Android/i)) {
        appstoreURL = 'https://play.google.com/store/apps/details?id=vn.thvl.app'
      }

      if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        appstoreURL = 'itms-apps://itunes.apple.com/app/thvli/id1185316310'
      }

      window.location = appstoreURL

      // let pathname = window.location.pathname
      // if (pathname && pathname.charAt(0) === '/') {
      //   pathname = pathname.substring(1)
      // }

      // const pathArray = pathname.trim().split('/')
      // let slug = ''
      // let deeplinkPath = 'thvli://'

      // if (pathArray.length > 1) {
      //   slug = pathArray[1]
      //   if (pathArray[0] === 'detail') {
      //     deeplinkPath = 'thvli://entity/' + slug
      //   } else if (pathArray[0] === 'live') {
      //     deeplinkPath = 'thvli://live/' + slug
      //   }
      // }

      // const now = new Date().valueOf()
      // setTimeout(function () {
      //   if (new Date().valueOf() - now > 100) {
      //     window.location = appstoreURL
      //   }
      // }, 2000)

      // window.location = deeplinkPath
    }
  }

  return (
    <div className="detail-info">
      <div className='row row-install-app mt-1 mb-2'>
        <div className='col-12'>
          <img src={imgInstall} className='img-fluid' alt="" onClick={handleClickInstallApp} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="info-title">{title}</div>
          <div className="publish-duration-info">{publishDate}</div>
        </div>
      </div>

      <hr />

      <div className="row">
        {/* <div className="col-7"> */}
        <div className="col-12 col-lg-7">
          {description && description !== '' &&
            <div className="pt-3">
            {/* <div className="pt-1"> */}
              {/* <span className="info-content-title">Nội dung: &nbsp;</span> */}
              {/* <span className="info-content">{description}</span> */}
              <div className="info-content" dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
          }
        </div>
        {/* <div className="col-5"> */}
        <div className="col-12 col-lg-5">
          {/* {actor !== '' &&
            <div className="pt-3">
              <span className="info-content-title">Diễn viên: &nbsp;</span>
              <span className="info-content">{actor}</span>
            </div>
          } */}
          {(Array.isArray(actorList) && actorList.length > 0) &&
            <div className="pt-3">
              <span className="info-content-title">Diễn viên: &nbsp;</span>
              <span className="info-content">{actorList.map((item, index) => {
                return (
                  <a key={index} href={`/artist/${item.id}/${data?.slug}`} className="link-people">
                    {item.name + (index < actorList.length - 1 ? ', ' : '')}
                  </a>
                )
              })}</span>
            </div>
          }

          {(Array.isArray(directorList) && directorList.length > 0) &&
            <div className="pt-3">
              <span className="info-content-title">Đạo diễn: &nbsp;</span>
              <span className="info-content">{directorList.map((item, index) => {
                return (
                  <a key={index} href={`/artist/${item.id}/${data?.slug}`} className="link-people">
                    {item.name + (index < directorList.length - 1 ? ', ' : '')}
                  </a>
                )
              })}</span>
            </div>
          }

          {/* {director !== '' &&
            <div>
              <span className="info-content-title">Đạo diễn: &nbsp;</span>
              <span className="info-content">{director}</span>
            </div>
          } */}

          {/* <div>
            <span className="info-content-title">Xuất bản: &nbsp;</span>
            <span className="info-content">{publishDate}</span>
          </div> */}

          <div className="pt-3">
            {Array.isArray(hashtagList) && hashtagList.map((item, index) => {
              const itemTrim = typeof item === 'string' ? item.trim() : ''

              return (
                <h5 key={index} className="d-inline-block">
                  <Link className="badge badge-hashtag bg-secondary me-3" to={`/hashtag?q=${itemTrim}`}>#{itemTrim}</Link>
                </h5>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

DetailInfo.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  hashtagList: PropTypes.array
}

DetailInfo.defaultProps = {
  data: null,
  title: '',
  hashtagList: []
}

export default DetailInfo
