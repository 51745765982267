import React, { useEffect, useState } from 'react'
import { getData } from 'common/utils'
import { useQuery } from 'react-query'
import Layout from 'features/layout/components/Layout'
import Detail from '../components/Detail'
import DetailGeoBlock from '../components/DetailGeoBlock'

const HomePage = (props) => {
  const [isAllowGeoBlock, setIsAllowGeoBlock] = useState(true)

  const { data: dataGeoBlock } = useQuery('getGeoBlock', async () => {
    return await getData('GET', 'check-geo/')
  })

  useEffect(() => {
    if (dataGeoBlock) {
      const resultData = dataGeoBlock.data
      if (resultData) {
        setIsAllowGeoBlock(resultData.is_allow)
      }
    }
  }, [dataGeoBlock])

  return (
    <Layout>
      {!isAllowGeoBlock
        ? <DetailGeoBlock />
        : <Detail />
      }
    </Layout>
  )
}

export default HomePage
