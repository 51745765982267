import React, { useEffect, useState, useRef } from 'react'
import { useMediaQuery } from 'common/hook-custom'
import { useInfiniteQuery } from 'react-query'
import { getDataAuth } from 'common/utils'
import Layout from 'features/layout/components/Layout'
import Watching from '../components/Watching'
import WatchingMobile from '../components/WatchingMobile'
import '../styles/watching-page.scss'

const WatchingPage = (props) => {
  const loadMoreRef = useRef()
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  const [title, setTitle] = useState('')
  const [data, setData] = useState([])

  const {
    data: dataRibbon,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery('getWatching', async ({ pageParam = 0 }) => {
    return await getDataAuth('GET', `progress/?page=${pageParam}&limit=20`)
  }, {
    getNextPageParam: lastPage => {
      if (lastPage.metadata) {
        return (lastPage.metadata.page + 1) ?? false
      } else {
        return false
      }
    }
  })

  useEffect(() => {
    if (!localStorage.getItem('adm_token')) {
      window.location.href = '/'
    }

    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Đang xem',
        page_location: 'https://thvli.vn/watching',
        page_path: '/watching',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    document.title = 'Đang xem'
    setTitle('Đang xem')
  }, [])

  useEffect(() => {
    if (dataRibbon) {
      setData(dataRibbon.pages)
    }
  }, [dataRibbon])

  /* --------------------------------------------- */
  /* -------------- Handle Event ----------------- */
  /* --------------------------------------------- */

  return (
    <Layout>
      <div className="watching-page">
        <div className="pb-3 mx-0 mx-md-5">
          <div className="row">
            <div className="col-12">
              <div className="title ms-md-3 ms-lg-5">{title}</div>
            </div>
          </div>

          <div className="mx-md-3 mx-lg-5 pt-2">
            {isDesktop
              ? <Watching
                  data={data}
                  loadMoreRef={loadMoreRef}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  isLoading={isLoading}
                  isFetchingNextPage={isFetchingNextPage}
                />
              : <WatchingMobile
                  data={data}
                  loadMoreRef={loadMoreRef}
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  isLoading={isLoading}
                  isFetchingNextPage={isFetchingNextPage}
                />
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WatchingPage
