import React from 'react'
import { useMediaQuery } from 'common/hook-custom'
import Layout from 'features/layout/components/Layout'
import Artist from '../components/Artist'
import ArtistMobile from '../components/ArtistMobile'

const ArtistPage = () => {
  const isDesktop = useMediaQuery('(min-width: 1200px)')

  return (
    <Layout>
      {isDesktop
        ? <Artist />
        : <ArtistMobile />
      }
    </Layout>
  )
}

export default ArtistPage
