import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { callApiFaq } from 'common/utils'
import Layout from 'features/layout/components/Layout'
import '../styles/faq.scss'

const FaqPage = (props) => {
  const [data, setData] = useState([])

  const { data: dataFaq } = useQuery('getFaq', async () => {
    const result = await callApiFaq()
    const { data: dataApi } = result
    return dataApi
  })

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'FAQ - THVLi',
        page_location: 'https://thvli.vn/faq',
        page_path: '/faq',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    document.title = 'FAQ - THVLi'
  }, [])

  useEffect(() => {
    if (dataFaq) {
      setData(dataFaq.content)
    }
  }, [dataFaq])

  return (
    <Layout>
      <div className="faq">
        <div className="row pt-4 pt-md-5">
          <div className="col-12 col-md-12">
            <div dangerouslySetInnerHTML={{ __html: data }}></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FaqPage
