import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import axios from 'axios'
import InputForm from 'components/InputForm'
import Overlay from 'components/Overlay'
// import { imgAuthBackground } from 'constant/images'
import '../styles/register.scss'

const Register = (props) => {
  // const history = useHistory()
  const { handleSubmit, register, formState: { errors } } = useForm()

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', 'page_view', {
        page_title: 'Đăng ký',
        page_location: 'https://thvli.vn/register',
        page_path: '/register',
        send_to: 'G-RW9WHCKH4J'
      })
    }

    document.title = 'Đăng ký'

    if (localStorage.getItem('adm_token')) {
      window.location.href = '/'
    }
  }, [])

  const onSubmit = (data) => {
    if (data) {
      setIsLoading(true)

      const dataEmailTemp = data.email
      if (typeof dataEmailTemp === 'string') {
        // eslint-disable-next-line no-control-regex
        if (dataEmailTemp.match(/(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi)) {
          const formData = new FormData()
          formData.append('email', data.email)
          formData.append('password', data.password)
          formData.append('first_name', data.first_name)
          formData.append('last_name', data.last_name)

          axios.post(`${process.env.REACT_APP_API_SETTING_URL}register/email/`, formData)
            .then(res => {
              setError('')
              setIsLoading(false)
              const successRegisterModalButton = document.getElementById('successRegisterModalButton')
              if (successRegisterModalButton) {
                successRegisterModalButton.click()
              }
            })
            .catch(err => {
              const errData = err.response && err.response.data
              if (errData) {
                if (errData.error === 400) {
                  const messageError = errData.message ? errData.message : errData.detail
                  setError(messageError)
                } else if (errData.error === 412) {
                  const messageError = errData.data && errData.data.error_msg
                  setError(messageError)
                } else {
                  setError('Something went wrong!')
                }
              } else {
                setError('Something went wrong!')
              }

              setIsLoading(false)
              console.log(err)
            })
        } else {
          setIsLoading(false)
          setError('Email không chính xác!')
        }
      }
    }
  }

  const handleClickConfirm = () => {
    window.location.href = '/login'
  }

  return (
    <div className="register">
      {/* <div className="auth-background">
        <img src={imgAuthBackground} />
      </div> */}

      <div className="auth-title">ĐĂNG KÝ</div>

      {error !== '' &&
        <div className="row pt-4">
          <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          </div>
        </div>
      }

      <div className="row pt-4">
        <div className="col-5 col-md-3 col-lg-2 offset-1 offset-md-3 offset-lg-4">
          <InputForm register={register} error={errors.first_name} required name="first_name" label="Họ" className="register-input" placeholder="Họ" />
        </div>
        <div className="col-5 col-md-3 col-lg-2">
          <InputForm register={register} error={errors.last_name} required name="last_name" label="Tên" className="register-input" placeholder="Tên" />
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <InputForm register={register} error={errors.email} required name="email" label="Email" className="register-input" placeholder="Email" />
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <InputForm register={register} error={errors.password} required isShowPassword name="password" label="Mật khẩu" type="password" className="register-input" placeholder="Mật khẩu" />
          <span className='password-instruction'>Mật khẩu yêu cầu tối thiểu 6 ký tự</span>
        </div>
      </div>

      <div className='row'>
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <div className="confirm-text">Bằng việc đăng ký tài khoản, bạn đã đồng ý với <Link to="/privacy-policy">Chính sách bảo mật</Link> và <Link to="/faq">Điều khoản sử dụng</Link></div>
        </div>
      </div>

      <div className="row pt-3">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <button className="btn btn-primary w-100 btn-register" onClick={handleSubmit(onSubmit)}>TẠO TÀI KHOẢN MỚI</button>
        </div>
      </div>

      <div className="row pt-4 pb-5">
        <div className="col-10 col-md-6 col-lg-4 offset-1 offset-md-3 offset-lg-4">
          <div className="text-login">Bạn đã có tài khoản? <Link to="/login"><span className="text-primary">Đăng Nhập</span></Link></div>
        </div>
      </div>

      <div className="modal fade" id="successRegisterModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Thông báo</h5>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body">
              Đăng ký tài khoản thành công. Vui lòng kiểm tra email để xác thực tài khoản của bạn.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" onClick={handleClickConfirm}>Đồng ý</button>
            </div>
          </div>
        </div>
      </div>

      <button style={{ display: 'none' }} type="button" id="successRegisterModalButton" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#successRegisterModal">
        Launch static backdrop modal
      </button>

      {isLoading && <Overlay />}
    </div>
  )
}

export default Register
