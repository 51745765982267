import { createSlice } from '@reduxjs/toolkit'

const watchingSlice = createSlice({
  name: 'watching',
  initialState: {
    progress: null
  },
  reducers: {
    setProgress (state, action) {
      state.progress = action.payload
    }
  }
})

export const {
  setProgress
} = watchingSlice.actions
export default watchingSlice.reducer
