import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import './styles/input-form.scss'

const InputForm = (props) => {
  const { className, name, label, type, placeholder, required, isShowPassword, register, error, onKeyDown } = props

  const [typeData, setTypeData] = useState('password')

  const handleShowPassword = () => {
    if (typeData === 'text') {
      setTypeData('password')
    } else if (typeData === 'password') {
      setTypeData('text')
    }
  }

  return (
    <div className="input-group adm-input-group">
      <input
        type={isShowPassword ? typeData : type}
        className={`form-control ${error ? 'is-invalid' : ''}${className === '' ? '' : (' ' + className)}`}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        {...register(name, { required: required })}
      />

      {isShowPassword && <span className="input-group-text" onClick={handleShowPassword}><FontAwesomeIcon icon={faEye} /></span>}

      {(error && error.type === 'required') &&
        <div className="invalid-feedback">
          Vui lòng nhập {label}
        </div>
      }
      {(error && error.type === 'manual') &&
        <div className="invalid-feedback">
          {error.message}
        </div>
      }
    </div>
  )
}

InputForm.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isShowPassword: PropTypes.bool,
  register: PropTypes.any,
  error: PropTypes.any,
  onKeyDown: PropTypes.func
}

InputForm.defaultProps = {
  className: '',
  name: '',
  label: '',
  type: 'text',
  required: false,
  isShowPassword: false,
  placeholder: ''
}

export default InputForm
