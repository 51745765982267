import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getData, formatDataChunk, formatDataChunkCustom } from 'common/utils'
import ImageHolder from 'features/core/ImageHolder'
import AgeRatingItem from 'components/AgeRatingItem'
import NewReleaseItem from 'components/NewReleaseItem'
import imgArtistPlaceholder from '../styles/images/artist_placeholder.png'
import '../styles/artist-mobile.scss'

const ArtistMobile = () => {
  const { slug, slugcontent } = useParams()

  const [name, setName] = useState('')
  const [country, setCountry] = useState('')
  const [dataList, setDataList] = useState([])
  const [avatar, setAvatar] = useState(null)
  const [role, setRole] = useState('')
  const [peopleList, setPeopleList] = useState([])

  const { data } = useQuery(['getArtist', slug], async () => {
    if (slug) {
      return await getData('GET', `artist/${slug}/contents/`)
    }
  })

  const { data: dataContent } = useQuery(['getDetailArtist', slugcontent], async () => {
    if (slugcontent) {
      return await getData('GET', `get_detail/${slugcontent}/?platform=web&drm_ver=2&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Saigon'}`)
    }
  })

  useEffect(() => {
    if (data && dataContent) {
      const dataTemp = formatDataChunk(data, 4)
      if (Array.isArray(dataTemp)) {
        setDataList(dataTemp)
      }

      const peopleArr = dataContent.people
      if (Array.isArray(peopleArr)) {
        const arrTemp = peopleArr.filter(el => {
          return el.id !== slug
        })
        setPeopleList(formatDataChunkCustom(arrTemp, 2))

        const arrArtist = peopleArr.filter(el => {
          return el.id === slug
        })
        if (Array.isArray(arrArtist)) {
          const firstItem = arrArtist[0]
          if (firstItem) {
            setName(firstItem.name)
            setCountry(firstItem.country_name)
            setAvatar(firstItem.avatar_url)
            setRole(firstItem.role === 'actor' ? 'Diễn viên' : firstItem.role === 'director' ? 'Đạo diễn' : '')
          }
        }
      }
    }
  }, [data, dataContent])

  return (
    <div className="artist-mobile">
      <div className="row">
        <div className="col-md-12">
          <div className="text-artist">NGHỆ SĨ</div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="artist-container">
            <div className="info-container">
              {avatar
                ? <img
                    className="artist-img"
                    src={avatar}
                    width={85}
                    height={85}
                    alt=""
                  />
                : <img
                    className="artist-img"
                    src={imgArtistPlaceholder}
                    width={85}
                    height={85}
                    alt=""
                  />
                }
              <div className="artist-name">{name}</div>
              <div className="text-nation">Nghề nghiệp: <span className="text-detail">{role}</span></div>
              <div className="text-nation">Quốc gia: <span className="text-detail">{country}</span></div>
            </div>

            <div className='artist-relate-text'>Nghệ sĩ tương tự</div>
            {Array.isArray(peopleList) && peopleList.map((item, index) => {
              return (
                <div key={index} className='row'>
                  {Array.isArray(item) && item.map((itemChild, indexChild) => {
                    return (
                      <React.Fragment key={indexChild}>
                        <div className='col-6'>
                          <Link key={indexChild} to={`/artist/${itemChild.id}/${slugcontent}`} className="col-artist-info">
                            {itemChild.avatar_url
                              ? <img
                                  className="artist-img"
                                  src={itemChild.avatar_url}
                                  width={85}
                                  height={85}
                                  alt=""
                                />
                              : <img
                                  className="artist-img"
                                  src={imgArtistPlaceholder}
                                  width={85}
                                  height={85}
                                  alt=""
                                />
                            }
                            <div className='img-title'>{itemChild.name}</div>
                          </Link>
                        </div>

                        {((indexChild === item.length - 1) && item.length === 1) &&
                          <>
                            <div className='col-6 col-dump'>
                              <div className='artist-img-dump'></div>
                            </div>
                          </>
                        }
                      </React.Fragment>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="text-joined">Đã tham gia</div>
        </div>
      </div>
      {Array.isArray(dataList) && dataList.map((item, index) => {
        return (
          <div key={index} className='row'>
            {Array.isArray(item) && item.map((itemChild, indexChild) => {
              return (
                <React.Fragment key={indexChild}>
                  <div className='col-6 item-figure'>
                    <Link to={`detail/${slug}/${itemChild.slug}`}>
                      <figure className="figure">
                        <div className="figure-overflow">
                          <AgeRatingItem item={itemChild} />
                          <NewReleaseItem item={itemChild} isPoster />

                          <ImageHolder data={itemChild.images} />
                        </div>
                        <figcaption className="figure-caption">
                          {itemChild.title}
                        </figcaption>
                      </figure>
                    </Link>
                  </div>

                  {((indexChild === item.length - 1) && item.length === 1) &&
                    <>
                      <div className='col item-figure col-artist-joined'></div>
                    </>
                  }
                </React.Fragment>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default ArtistMobile
