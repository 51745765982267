import React from 'react'
import Layout from 'features/layout/components/Layout'
import DetailTrailer from '../components/DetailTrailer'

const DetailTrailerPage = (props) => {
  return (
    <Layout>
      <DetailTrailer />
    </Layout>
  )
}

export default DetailTrailerPage
